import { Device } from '@thingslog/repositories';
import { DeviceModel } from '@thingslog/repositories/src/deviceModel/DeviceModel';

const modbusMasterCompatibleModels = new Set<DeviceModel>([
  DeviceModel.TLCTL_21A4S,
  DeviceModel.TLCTL_1104,
  DeviceModel.TLCTL_1105,
  DeviceModel.TLCTL_1106,
]);

const slaveCompatibleModels = new Set<DeviceModel>([
  DeviceModel.FINECO_EM735,
  DeviceModel.FINECO_EM4374,
  DeviceModel.MODBUS_SLAVE,
]);

export const filterModbusMasterDevices = (devices: Device[]): string[] => {
  return devices
    .filter((device: Device) => modbusMasterCompatibleModels.has(device.model))
    .map((device: Device) => device.number);
};

export const filterSlaveDevices = (devices: Device[]): string[] => {
  return devices
    .filter((device: Device) => slaveCompatibleModels.has(device.model))
    .map((device: Device) => device.number);
};
