import { defaultAnalogInputPort } from './AnalogInputPort';
import { defaultCurrent420InputPort } from './Current420InputPort';
import { defaultDigitalInputPort } from './DigitalInputPort';
import { defaultModbusOnOffInputPort } from './GenericModbusOnOffInputPort';
import { defaultGenericModbusOnOffOutputPort } from './GenericModbusOnOffOutputPort';
import { defaultOnOffInputPort } from './OnOffInputPort';
import { defaultOnOffOutputPort } from './OnOffOutputPort';
import { Port } from './Port';
import { PortType } from './PortType';
import { defaultPulseInputPort } from './PulseInputPort';
import { defaultS0InputPort } from './PulseS0InputPort';
import { defaultVoltage05InputPort } from './Voltage05InputPort';

export const PortRegistry: Record<PortType, Port> = {
  analog_input_port: defaultAnalogInputPort,
  digital_input_port: defaultDigitalInputPort,
  on_off_input_port: defaultOnOffInputPort,
  on_off_output_port: defaultOnOffOutputPort,
  pulse_input_port: defaultPulseInputPort,
  pulse_s0_input_port: defaultS0InputPort,
  '0_5_V_input_port': defaultVoltage05InputPort,
  '4_20_mA_input_port': defaultCurrent420InputPort,
  generic_modbus_on_off_input_port: defaultModbusOnOffInputPort,
  generic_modbus_on_off_output_port: defaultGenericModbusOnOffOutputPort,
};
