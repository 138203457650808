import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface GenericVirtualSensor extends GenericAnalogSensor {
  isVirtual: boolean;
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultGenericVirtualSensor: GenericVirtualSensor = {
  '@type': 'generic_virtual_sensor',
  ...defaultSensorWithoutType,
  isVirtual: true,
};
