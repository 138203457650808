import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface ParticulateMatterSensor extends GenericAnalogSensor {
  particleSize: ParticulateMatterSize | null;
  '@type': 'particulate_matter_sensor';
}

export enum ParticulateMatterSize {
  PM1 = '1',
  PM2_5 = '2.5',
  PM4 = '4',
  PM10 = '10',
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultParticulateMatterSensor: ParticulateMatterSensor = {
  '@type': 'particulate_matter_sensor',
  particleSize: null,
  ...defaultSensorWithoutType,
};
