import { MeasurementUnit } from '../units/MeasurementUnit';
import { SensorType } from './SensorType';

export default interface SensorBase {
  '@type': SensorType;
  name: string;
  description: string | null;
  precision: number;
  scale: number;
  units: MeasurementUnit;
  flowUnit: FlowUnit | null;
  flowConversionCoefficient: number | null;
  model: string | null;
  serialNumber: string | null;
  manufacturer: string | null;
  alarmConfigList: null; // not supported
  iconName: string | null;
  iconId: number | null;
  orderNumber: number | null;
  visible: boolean | null;
  virtual: boolean;
  valueRanges: ValueRanges | null;
  useInSensorsAggregation: boolean | null;
  allowedUnits: MeasurementUnit[];
}

interface ValueRanges {
  boundaries: number[];
  keys: string[];
}

export interface FlowUnit {
  units: MeasurementUnit;
  timeUnits: FlowTimeUnits;
}

export type FlowTimeUnits =
  | 'NANOS'
  | 'MICROS'
  | 'MILLIS'
  | 'SECONDS'
  | 'MINUTES'
  | 'HOURS'
  | 'HALFDAYS'
  | 'DAYS'
  | 'WEEKS'
  | 'MONTHS'
  | 'YEARS'
  | 'DECADES'
  | 'CENTURIES'
  | 'MILLENNIA'
  | 'ERAS'
  | 'FOREVER';

export const defaultSensorBase: SensorBase = {
  '@type': 'generic_analog_sensor', // Needs to be overwritten
  alarmConfigList: null,
  allowedUnits: [],
  description: null,
  flowConversionCoefficient: null,
  flowUnit: null,
  iconName: null,
  iconId: null,
  manufacturer: null,
  model: null,
  orderNumber: null,
  name: '',
  precision: 9,
  scale: 0,
  serialNumber: null,
  units: MeasurementUnit.NO_UNITS,
  useInSensorsAggregation: null,
  valueRanges: null,
  virtual: false,
  visible: true,
};
