import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface LevelSensor extends GenericAnalogSensor {
  '@type': 'level_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultLevelSensor: LevelSensor = {
  '@type': 'level_sensor',
  ...defaultSensorWithoutType,
};
