import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface LoadCells extends GenericAnalogSensor {
  '@type': 'load_cells';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultLoadCells: LoadCells = {
  '@type': 'load_cells',
  ...defaultSensorWithoutType,
};
