import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface SoilTemperatureSensor extends GenericAnalogSensor {
  '@type': 'soil_temperature_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultSoilTemperatureSensor: SoilTemperatureSensor = {
  '@type': 'soil_temperature_sensor',
  ...defaultSensorWithoutType,
};
