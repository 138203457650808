import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface LeafWetnessSensor extends GenericAnalogSensor {
  '@type': 'leaf_wetness_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultLeafWetnessSensor: LeafWetnessSensor = {
  '@type': 'leaf_wetness_sensor',
  ...defaultSensorWithoutType,
};
