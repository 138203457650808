export enum DisplayUnit {
  PULSES = 'pulses',
  AMPER = 'A',
  MILLIAMPER = 'mA',
  MILLIVOLT = 'mV',
  VOLT = 'V',
  BAR = 'bar',
  MILLIBAR = 'mBar',
  METERS_OF_WATER_COLUMN = 'mca',
  GALLON = 'gal',
  LITER = 'liter',
  KW = 'kW',
  KWH = 'kWh',
  KVARH = 'kVarh',
  DEG_C = '°C',
  CUBIC_METER = 'm³',
  KILOLITRE = 'kl',
  RELATIVE_HUMIDITY = 'RH(%)',
  VOLUMETRIC_WATER_CONTENT = 'VWC (%)',
  PERCENT = '(%)',
  METER = 'm',
  CENTIMETER = 'cm',
  MILLIMETER = 'mm',
  INCH = 'in',
  FOOT = 'ft',
  NO_UNITS = '',
  DEG_F = '°F',
  GRAM = 'g',
  KILOGRAM = 'kg',
  TON = 'ton',
  NEWTON = 'N',
  KILONEWTON = 'kN',
  MILLILITER = 'ml',
  LITERS_PER_SECOND_SQUARE_METER = 'L/s/m²',
  LITERS_PER_SQUARE_METER = 'L/m²',
  MM_PER_HOUR_PER_SQUARE_METER = 'mmh/m²',
  MM_PER_SQUARE_METER = 'mm/m²',
  METERS_PER_SECOND = 'm/s',
  KNOT = 'kts',
  KILOMETER_PER_HOUR = 'km/h',
  PASCAL = 'Pa',
  KILOPASCAL = 'kPa',
  HECTOPASCAL = 'hPa',
  MILESPERHOUR = 'mp/h',
  PICOFARAD = 'pF',
  COUNTS_PER_MINUTE = 'cpm',
  MILLI_ROENTGEN_PER_HOUR = 'mR/h',
  AQI = 'aqi',
  MICRO_GRAMS_PER_CUBIC_METER = 'µg/m³',
  SIEVERT = 'Sv',
  DEGREE = 'deg',
  LITERS_PER_SECOND = 'l/s',
  CUBIC_METERS_PER_HOUR = 'm^3/h',
  LITERS_PER_SQUARE_METER_PER_HOUR = 'l/(m²*h)',
  PARTS_PER_MILLION = 'ppm',
  PARTS_PER_BILLION = 'ppb',
  NEPHELOMETRIC_TURBIDITY_UNIT = 'NTU',
  MILLIGRAMS_PER_LITER = 'mg/l',
  MILLIGRAMS_PER_CUBIC_METER = 'mg/m^3',
  MICROSIEMENS_PER_CENTIMETER = 'µS/cm',
  POTENTIAL_OF_HYDROGEN = 'pH',
  KILOVOLT_AMPER = 'kVA',
  KILOVOLT_AMPER_REACTIVE = 'kVAr',
}
