export default interface ModbusSensorBase {
  registerAddress: number;
  dataType: ModbusReturnedDataType;
}

export type ModbusReturnedDataType =
  | 'RAW'
  | 'BIT'
  | 'HEX'
  | 'FLOAT'
  | 'UINT8'
  | 'UINT16'
  | 'UINT32'
  | 'INT8'
  | 'INT16'
  | 'INT32';

export const defaultModbusSensorBase: ModbusSensorBase = {
  registerAddress: 0,
  dataType: 'RAW',
};
