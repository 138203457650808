import GenericDigitalSensor, { defaultGenericDigitalSensor } from './GenericDigitalSensor';
import ModbusSensorBase, { defaultModbusSensorBase } from './ModbusSensorBase';

export default interface ModbusGenericDigitalSensor extends GenericDigitalSensor, ModbusSensorBase {
  count: number;
}
const { '@type': _, ...defaultSensorWithoutType } = defaultGenericDigitalSensor;

export const defaultModbusGenericDigitalSensor: ModbusGenericDigitalSensor = {
  '@type': 'modbus_generic_digital_sensor',
  ...defaultSensorWithoutType,
  ...defaultModbusSensorBase,
  count: 2,
};
