import React, { FC, ReactElement, ReactNode, useMemo } from 'react';

import {
  TextField,
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Chip,
  AutocompleteRenderInputParams,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';

import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import { ModbusSlaveConfigModel } from '@thingslog/repositories';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../reducers';
import JwtValidator from '../../../../common/JwtValidator';
import { filterModbusMasterDevices } from '../../../../common/DeviceModelUtil';

const ModbusSlaveNetworkConfiguration: FC<ModbusSlaveNetworkConfigurationProps> = () => {
  const { t } = useTranslation();
  const devices = useSelector((state: ReduxState) => state.dev.devicesArray);
  const { hasRole } = useMemo(() => new JwtValidator(), []);

  const masterDeviceOptions: string[] = useMemo(
    () => filterModbusMasterDevices(devices),
    [devices]
  );

  return (
    <ConnectForm<ModbusSlaveConfigModel>>
      {({ control }: UseFormReturn<ModbusSlaveConfigModel>): ReactElement => (
        <div className="grid grid-cols-4 gap-5">
          <Controller
            control={control}
            name="modbusMasterDeviceNumber"
            render={({
              field,
            }: UseControllerReturn<
              ModbusSlaveConfigModel,
              'modbusMasterDeviceNumber'
            >): ReactElement => (
              <Autocomplete
                multiple
                options={masterDeviceOptions}
                freeSolo={hasRole('ROLE_SUPER_ADMIN')} // Allows Superadmin to add more options in the dropdown. Usable when SA wants to select master devices outside of selected company
                value={field.value ? [field.value] : []}
                onChange={(event: React.SyntheticEvent, value: string[]): void => {
                  const selectedValue = value.length > 0 ? value[value.length - 1] : null;
                  field.onChange(selectedValue);
                }}
                size="small"
                renderInput={(params: AutocompleteRenderInputParams): JSX.Element => (
                  <TextField {...params} label={t('device_initial_config_modbus_master_device')} />
                )}
                renderTags={(
                  deviceNumbers: string[],
                  getTagProps: AutocompleteRenderGetTagProps
                ): ReactNode => {
                  return deviceNumbers.map((deviceNumber: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        key={key}
                        label={
                          <Link key={key} to={`/app/ParametersConfiguration/${deviceNumber}`}>
                            <span className="text-blue-500 underline cursor-pointer">
                              {`${deviceNumber}`}
                            </span>
                          </Link>
                        }
                        size="small"
                        {...tagProps}
                      />
                    );
                  });
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="modbusAddress"
            rules={{
              min: { value: 0, message: t('device_network_config_modbus_address_min') },
              max: { value: 255, message: t('device_network_config_modbus_address_max') },
            }}
            render={({
              field,
            }: UseControllerReturn<ModbusSlaveConfigModel, 'modbusAddress'>): ReactElement => (
              <TextField
                {...field}
                type="number"
                value={field.value < 0 ? field.value + 256 : field.value}
                label={t('device_initial_config_modbus_address')}
                size="small"
              />
            )}
          />
        </div>
      )}
    </ConnectForm>
  );
};

interface ModbusSlaveNetworkConfigurationProps {}

export default ModbusSlaveNetworkConfiguration;
