import React, { ChangeEvent, FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { getSensorTypeOptionsBasedOnPortTypeAndDeviceModel } from '../../common/SensorTypeOptions';
import InitialConfigFormFields from '../../models/InitialConfigFormFields';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import { hash, HelperTextInputField } from '@thingslog/ui-components';
import SensorTypeSelector from '../../components/SensorTypeSelector';

const OnOffConfig: FC<OnOffConfigProps> = ({ index }: OnOffConfigProps) => {
  const { t } = useTranslation();

  return (
    <ConnectForm<InitialConfigFormFields>>
      {({ control, getValues }: UseFormReturn<InitialConfigFormFields>): ReactElement => (
        <>
          <Controller
            control={control}
            name={`ports.${index}.sensor.valueLabels`}
            render={({
              field,
            }: UseControllerReturn<
              InitialConfigFormFields,
              `ports.${number}.sensor.valueLabels`
            >): ReactElement => (
              <>
                <HelperTextInputField
                  className="flex-1"
                  InputProps={{
                    startAdornment: (
                      <div className="mr-2 pl-1 pr-4 font-bold border-0 border-r border-solid border-slate-300">
                        0
                      </div>
                    ),
                  }}
                  value={field.value?.[0] ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                    const valueLabels = {
                      0: event.target.value || undefined,
                      1: field.value?.[1],
                    };

                    if (valueLabels[0] === undefined && valueLabels[1] === undefined) {
                      field.onChange(null);
                    } else {
                      field.onChange(valueLabels);
                    }
                  }}
                  size="small"
                  label={t('sensor_config_label_for_value_zero')}
                />

                <HelperTextInputField
                  className="flex-1"
                  InputProps={{
                    startAdornment: (
                      <div className="mr-2 pl-1 pr-4 font-bold border-0 border-r border-solid border-slate-300">
                        1
                      </div>
                    ),
                  }}
                  value={field.value?.[1] ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                    const valueLabels = {
                      0: field.value?.[0],
                      1: event.target.value || undefined,
                    };

                    if (valueLabels[0] === undefined && valueLabels[1] === undefined) {
                      field.onChange(null);
                    } else {
                      field.onChange(valueLabels);
                    }
                  }}
                  size="small"
                  label={t('sensor_config_label_for_value_one')}
                />
              </>
            )}
          />

          <Controller
            control={control}
            name={`ports.${index}.sensor.@type`}
            render={({
              field,
            }: UseControllerReturn<
              InitialConfigFormFields,
              `ports.${number}.sensor.@type`
            >): ReactElement => (
              <SensorTypeSelector
                portType={getValues(`ports.${index}.@type`)}
                deviceModel={getValues('@type')}
                field={field}
              />
            )}
          />

          <Controller
            control={control}
            name={`ports.${index}.sensor.invertedReadStateValue`}
            render={({
              field,
            }: UseControllerReturn<
              InitialConfigFormFields,
              `ports.${number}.sensor.invertedReadStateValue`
            >): ReactElement => (
              <FormControl size="small">
                <InputLabel>{t('sensor_config_inverted_read_state_value')}</InputLabel>
                <Select
                  {...field}
                  onChange={(e: SelectChangeEvent): void =>
                    field.onChange(e.target.value === 'true')
                  }
                  value={field.value === true ? 'true' : 'false'}
                  label={t('sensor_config_inverted_read_state_value')}
                >
                  <MenuItem value="true">
                    {t('sensor_config_inverted_read_state_value_true')}
                  </MenuItem>
                  <MenuItem value="false">
                    {t('sensor_config_inverted_read_state_value_false')}
                  </MenuItem>
                </Select>
                <FormHelperText> </FormHelperText>
              </FormControl>
            )}
          />
        </>
      )}
    </ConnectForm>
  );
};

interface OnOffConfigProps {
  index: number;
}

export default OnOffConfig;
