import OnOffInputSensorBase, { defaultOnOffInputSensorBase } from './OnOffInputSensorBase';

export default interface TasmotaAlarmInputSensor extends OnOffInputSensorBase {
  '@type': 'tasmota_alarm_input_sensor';
  power: number;
}

const { '@type': _, ...defaultSensorWithoutType } = defaultOnOffInputSensorBase;

export const defaultTasmotaAlarmInputSensor: TasmotaAlarmInputSensor = {
  '@type': 'tasmota_alarm_input_sensor',
  power: 0,
  ...defaultSensorWithoutType,
};
