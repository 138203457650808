import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface TasmotaGenericAnalogSensor extends GenericAnalogSensor {
  '@type': 'tasmota_generic_analog_sensor';
  measurementGroup: string | null;
  measurementType: string | null;
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultTasmotaGenericAnalogSensor: TasmotaGenericAnalogSensor = {
  '@type': 'tasmota_generic_analog_sensor',
  measurementGroup: null,
  measurementType: null,
  ...defaultSensorWithoutType,
};
