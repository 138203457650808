import DigitalInputPort, { defaultDigitalInputPort } from './DigitalInputPort';
import { defaultPortBase } from './PortBase';

export default interface PulseS0InputPort extends Omit<DigitalInputPort, '@type'> {
  '@type': 'pulse_s0_input_port';
}

const { '@type': _, ...defaultPortWithoutType } = defaultDigitalInputPort;

export const defaultS0InputPort: PulseS0InputPort = {
  '@type': 'pulse_s0_input_port',
  ...defaultPortWithoutType,
};
