import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface WindDirectionSensor extends GenericAnalogSensor {
  '@type': 'wind_direction_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultWindDirectionSensor: WindDirectionSensor = {
  '@type': 'wind_direction_sensor',
  ...defaultSensorWithoutType,
};
