import ModbusSensorBase, { defaultModbusSensorBase } from './ModbusSensorBase';
import { defaultOnOffOutputSensor } from './OnOffOutputSensor';
import OnOffOutputSensorBase from './OnOffOutputSensorBase';

export default interface ModbusGenericOnOffOutputSensor
  extends OnOffOutputSensorBase,
    ModbusSensorBase {
  '@type': 'modbus_generic_on_off_output_sensor';
  numberBytes: number;
}

const { '@type': _, ...defaultSensorWithoutType } = defaultOnOffOutputSensor;

export const defaultModbusGenericOnOffOutputSensor: ModbusGenericOnOffOutputSensor = {
  '@type': 'modbus_generic_on_off_output_sensor',
  ...defaultSensorWithoutType,
  ...defaultModbusSensorBase,
  numberBytes: 2,
};
