import OnOffSensor, { defaultOnOffSensor } from './OnOffSensor';

export default interface OnOffOutputSensorBase extends OnOffSensor {
  '@type': 'on_off_output_sensor' | 'tasmota_relay_sensor' | 'modbus_generic_on_off_output_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultOnOffSensor;

export const defaultOnOffOutputSensorBase: OnOffOutputSensorBase = {
  '@type': 'on_off_output_sensor',
  ...defaultSensorWithoutType,
};
