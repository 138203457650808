import OnOffInputPort, { defaultOnOffInputPort } from './OnOffInputPort';

export default interface GenericModbusOnOffInputPort extends Omit<OnOffInputPort, '@type'> {
  '@type': 'generic_modbus_on_off_input_port';
}

const { '@type': _, ...defaultPortWithoutType } = defaultOnOffInputPort;

export const defaultModbusOnOffInputPort: GenericModbusOnOffInputPort = {
  '@type': 'generic_modbus_on_off_input_port',
  ...defaultPortWithoutType,
};
