import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeviceRepository from '../clients/Device/DeviceRepository';
import { Device } from '@thingslog/repositories';

const useFetchDevice = (number?: string): UseFetchDeviceProps => {
  const [device, setDevice] = useState<Device | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const deviceRepository = new DeviceRepository();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);
      setError(null);

      try {
        const device = await deviceRepository.getDevices(number!);
        setDevice(device);
      } catch {
        setDevice(null);
        setError(t('error_cannot_fetch_device'));
      }
      setLoading(false);
    };
    number && fetchData();
  }, [number]);

  return { device: device, deviceLoading: loading, deviceError: error };
};

interface UseFetchDeviceProps {
  device: Device | null;
  deviceError: string | null;
  deviceLoading: boolean;
}

export default useFetchDevice;
