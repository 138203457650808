import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface BarometricPressureSensor extends GenericAnalogSensor {
  '@type': 'barometric_pressure_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultBarometricPressureSensor: BarometricPressureSensor = {
  '@type': 'barometric_pressure_sensor',
  ...defaultSensorWithoutType,
};
