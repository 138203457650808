import { Sensor } from '../sensor/Sensor';
import { SensorRegistry } from '../sensor/SensorRegistry';
import { PortType } from './PortType';

export default interface PortBase {
  '@type': PortType;
  direction: 'IN' | 'OUT';
  enabled: boolean;
  sensor: Sensor;
}

export const defaultPortBase: PortBase = {
  '@type': 'analog_input_port', // Needs to be overwritten
  enabled: true,
  direction: 'IN',
  sensor: SensorRegistry.generic_analog_sensor!, // safe
};
