import DigitalOutputPort from './DigitalOutputPort';
import { defaultPortBase } from './PortBase';

export default interface OnOffOutputPort extends Omit<DigitalOutputPort, '@type'> {
  '@type': 'on_off_output_port';
}

const { '@type': _, ...defaultPortWithoutType } = defaultPortBase;

export const defaultOnOffOutputPort: OnOffOutputPort = {
  '@type': 'on_off_output_port',
  ...defaultPortWithoutType,
};
