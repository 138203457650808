import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface HumiditySensor extends GenericAnalogSensor {
  '@type': 'humidity_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultHumiditySensor: HumiditySensor = {
  '@type': 'humidity_sensor',
  ...defaultSensorWithoutType,
};
