import { AnalogPortType, DigitalPortType, OnOffPortType, PortType } from '@thingslog/repositories';
import {
  ModbusOnOffInputPortType,
  ModbusOnOffOutputPortType,
} from '@thingslog/repositories/src/port/PortType';

export default class PortUtil {
  public static isDigitalPort(portType: PortType): portType is DigitalPortType {
    return (
      portType === 'digital_input_port' ||
      portType === 'pulse_input_port' ||
      portType === 'pulse_s0_input_port'
    );
  }

  public static isAnalogPort(portType: PortType): portType is AnalogPortType {
    return (
      portType === 'analog_input_port' ||
      portType === '4_20_mA_input_port' ||
      portType === '0_5_V_input_port'
    );
  }

  public static isOnOffPort(portType: PortType): portType is OnOffPortType {
    return portType === 'on_off_input_port' || portType === 'on_off_output_port';
  }

  public static isModbusOnOffInputPort(portType: PortType): portType is ModbusOnOffInputPortType {
    return portType === 'generic_modbus_on_off_input_port';
  }

  public static isModbusOnOffOutputPort(portType: PortType): portType is ModbusOnOffOutputPortType {
    return portType === 'generic_modbus_on_off_output_port';
  }
}
