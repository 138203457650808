import GenericDigitalSensor, { defaultGenericDigitalSensor } from './GenericDigitalSensor';

export default interface GasFlowMeter extends GenericDigitalSensor {
  '@type': 'gas_flow_meter';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericDigitalSensor;

export const defaultGasFlowMeter: GasFlowMeter = {
  '@type': 'gas_flow_meter',
  ...defaultSensorWithoutType,
};
