import React from 'react';
import { DisplayUnit } from '@thingslog/repositories';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MeasurementUnit } from '@thingslog/repositories';

const UnitsDropdown: React.FC<UnitsDropdownProps> = ({
  allowedUnits,
  onChange,
  unit = MeasurementUnit.NO_UNITS,
}: UnitsDropdownProps) => {
  const { t } = useTranslation();

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel>{t('units_type')}</InputLabel>
      <Select value={unit} label={t('units_type')}>
        {allowedUnits
          .sort((a: MeasurementUnit, b: MeasurementUnit) => {
            const unitA = DisplayUnit[a];
            const unitB = DisplayUnit[b];
            if (unitA === undefined && unitB === undefined) return 0;
            if (unitA === undefined) return -1;
            if (unitB === undefined) return 1;
            return unitA.localeCompare(unitB, 'en');
          })
          .map((unit: MeasurementUnit, index: number) => {
            return (
              <MenuItem
                id={`unit-${index}`}
                key={`unit-${index}`}
                value={unit}
                onClick={(): void => {
                  onChange(unit);
                }}
              >
                {DisplayUnit[unit] === DisplayUnit.NO_UNITS ? t('no_unit') : DisplayUnit[unit]}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

interface UnitsDropdownProps {
  allowedUnits: MeasurementUnit[];
  unit: MeasurementUnit | null;
  onChange: (unit: MeasurementUnit) => void;
}

export default UnitsDropdown;
