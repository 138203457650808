import OnOffOutputSensorBase, { defaultOnOffOutputSensorBase } from './OnOffOutputSensorBase';

export default interface TasmotaRelaySensor extends OnOffOutputSensorBase {
  '@type': 'tasmota_relay_sensor';
  power: number;
}

const { '@type': _, ...defaultSensorWithoutType } = defaultOnOffOutputSensorBase;

export const defaultTasmotaRelaySensor: TasmotaRelaySensor = {
  '@type': 'tasmota_relay_sensor',
  power: 0,
  ...defaultSensorWithoutType,
};
