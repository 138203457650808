import DigitalInputPort from './DigitalInputPort';
import { defaultPortBase } from './PortBase';

export default interface PulseInputPort extends Omit<DigitalInputPort, '@type'> {
  '@type': 'pulse_input_port';
}

const { '@type': _, ...defaultPortWithoutType } = defaultPortBase;

export const defaultPulseInputPort: PulseInputPort = {
  '@type': 'pulse_input_port',
  ...defaultPortWithoutType,
};
