import AnalogInputPort, { defaultAnalogInputPort } from './AnalogInputPort';

export default interface Current420InputPort extends Omit<AnalogInputPort, '@type'> {
  '@type': '4_20_mA_input_port';
}

const { '@type': _, ...defaultPortWithoutType } = defaultAnalogInputPort;

export const defaultCurrent420InputPort: Current420InputPort = {
  '@type': '4_20_mA_input_port',
  ...defaultPortWithoutType,
};
