import React, { Component } from 'react';
import { WithRouterProps, withRouter } from '../../common/withRouter';
import { withTranslation, WithTranslation } from 'react-i18next';
import deviceConfigClient from '../../DeviceConfigClient';
import sensorsConfigClient from '../../SensorsConfigClient';
import Header from '../../components/header';
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Theme,
  IconButton,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import SVGProofOfConcept from './components/SvgPOC3';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Link } from 'react-router-dom';
import { getSensorReadingsAsTimeSeriesData } from '../../clients/DeviceReadings/DeviceReadingsClient';
import { getSwitchRelay, postSwitchRelay } from '../../clients/RelaySwitch/RelaySwitchClient';
import { getSensorFlowAsTimeSeriesData } from '../../clients/DeviceFlow/DeviceFlowClient';
import { Port } from '@thingslog/repositories';
import { TimeSeriesData } from '../../model/TimeSeriesData/TimeSeriesData';
import { PumpStationDeviceInfo } from './model/PumpStationDeviceInfo';
import PortUtil from '../graph/utils/PortUtil';

const TOWER_DEVICE_NUMBER = 'FFFF4444';
//const GROUND_DEVICE_NUMBER = '00112340';

export class Pump extends Component<PumpProps, PumpState> {
  public state: PumpState = {
    towerDevice: {
      deviceNumber: TOWER_DEVICE_NUMBER,
      analogSensorName: 'Tower Device Sensor',
      analogSensorIndex: null,
      onOffSensorName1: 'Tower Device On Off 1',
      onOffSensorName2: 'Tower Device On Off 2',
      unit: null,
      lastReadingValue: null,
      lastReadingDate: null,
      timezone: null,
    },
    relaySwitchStatusOnOff1: false,
    relaySwitchStatusOnOff2: false,
    debitLastReading: 0,
    energyLastReading: 0,
    lastRefreshTimestamp: '',
  };

  public componentDidMount = async (): Promise<void> => {
    await this.loadDevicesTimezone();
    await this.loadDevicesPortConfig();
    await this.loadDevicesData();
    await this.loadDebitAndEnergyFlow();

    setInterval(async (): Promise<void> => {
      await this.reloadData();
      await this.loadDebitAndEnergyFlow();
    }, 1000 * 30); // 30 secs
  };

  private reloadData = async (): Promise<void> => {
    await this.loadDevicesData();
    await this.loadRelaySwitchStatus(4);
    await this.loadRelaySwitchStatus(5);
  };

  private loadDebitAndEnergyFlow = async (): Promise<void> => {
    await this.loadFlowData('01019110', 0);
    await this.loadFlowData('01019110', 1);
  };

  private loadDevicesTimezone = async (): Promise<void> => {
    const towerDevice = await this.loadTimezone({ ...this.state.towerDevice });
    this.setState({ towerDevice });
  };

  private loadTimezone = async (device: PumpStationDeviceInfo): Promise<PumpStationDeviceInfo> => {
    const deviceConfigResponse = await deviceConfigClient.getDeviceConfigAsync(device.deviceNumber);
    device.timezone = deviceConfigResponse.timeZone;
    return device;
  };

  private loadDevicesPortConfig = async (): Promise<void> => {
    const towerDevice = await this.loadPortsConfig({ ...this.state.towerDevice });
    this.setState({ towerDevice });
  };

  private loadPortsConfig = async (
    device: PumpStationDeviceInfo
  ): Promise<PumpStationDeviceInfo> => {
    const portsConfigResponse = await sensorsConfigClient.getPortsConfigAsync(device.deviceNumber);

    Object.keys(portsConfigResponse).forEach(
      async (value: string, index: number): Promise<void> => {
        const portConfig: Port = portsConfigResponse[index];
        const portType = portConfig['@type'];
        if (portConfig.enabled && PortUtil.isAnalogPort(portType) && index === 2) {
          device.analogSensorIndex = index;
          device.analogSensorName = portConfig.sensor.name;
          device.unit = portConfig.sensor.units;
        }
        if (portConfig.enabled && PortUtil.isOnOffPort(portType) && index === 4) {
          device.onOffSensorName1 = portConfig.sensor.name;
          await this.loadRelaySwitchStatus(4);
        }
        if (portConfig.enabled && PortUtil.isOnOffPort(portType) && index === 5) {
          device.onOffSensorName2 = portConfig.sensor.name;
          await this.loadRelaySwitchStatus(5);
        }
      }
    );
    return device;
  };

  private loadDevicesData = async (): Promise<void> => {
    const towerDevice = await this.loadData({ ...this.state.towerDevice });
    const now = moment(new Date()).format('YYYY/MM/DD HH:mm:ss');

    this.setState({ towerDevice, lastRefreshTimestamp: now });
  };

  private loadData = async (device: PumpStationDeviceInfo): Promise<PumpStationDeviceInfo> => {
    const today = new Date();
    const oneDayAgo = new Date(new Date().setHours(new Date().getHours() - 24));

    if (device.analogSensorIndex !== null) {
      const timeSeriesData: TimeSeriesData[] = await getSensorReadingsAsTimeSeriesData(
        device.deviceNumber,
        device.analogSensorIndex,
        oneDayAgo.toISOString(),
        today.toISOString(),
        1
      );

      if (timeSeriesData.length > 0) {
        const deviceWithReading: PumpStationDeviceInfo = {
          ...device,
          lastReadingDate: timeSeriesData[timeSeriesData.length - 1].x,
          lastReadingValue: timeSeriesData[timeSeriesData.length - 1].y,
        };
        return deviceWithReading;
      }
    }

    return device;
  };

  private loadRelaySwitchStatus = async (index: number): Promise<void> => {
    const deviceNumber = 'FFFF4445';
    const response = await getSwitchRelay(deviceNumber, index);
    const status = response === 'OFF' ? false : true;

    if (index === 4) {
      this.setState({ relaySwitchStatusOnOff1: status });
    } else if (index === 5) {
      this.setState({ relaySwitchStatusOnOff2: status });
    }
  };

  private postRelaySwitchChange = async (index: number): Promise<void> => {
    const deviceNumber = 'FFFF4445';
    if (index === 4) {
      await postSwitchRelay(deviceNumber, index, this.state.relaySwitchStatusOnOff1 ? 'OFF' : 'ON');
    } else if (index === 5) {
      await postSwitchRelay(deviceNumber, index, this.state.relaySwitchStatusOnOff2 ? 'OFF' : 'ON');
    }
  };

  private toggleRelaySwitch = async (index: number): Promise<void> => {
    await this.postRelaySwitchChange(index);
    await this.loadRelaySwitchStatus(index);
  };

  private loadFlowData = async (deviceNumber: string, sensorIndex: number): Promise<void> => {
    const now = new Date();
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    now.setHours(23, 59, 59, 999);
    yesterday.setHours(0, 0, 0, 0);

    let multiply = 1;
    let dv = 1;
    let dt = 1;
    dt /= 3600;
    multiply = dv / dt;

    try {
      const timeSeriesData = await getSensorFlowAsTimeSeriesData(
        deviceNumber,
        sensorIndex,
        yesterday.toISOString(),
        now.toISOString(),
        multiply,
        1
      );
      let lastReading = 0;
      if (timeSeriesData.length > 0) {
        lastReading = timeSeriesData[timeSeriesData.length - 1].y;
      }

      if (sensorIndex === 1) {
        this.setState({ debitLastReading: lastReading });
      } else if (sensorIndex === 0) {
        this.setState({ energyLastReading: lastReading });
      }
    } catch (error) {
      console.error(error);
    }
  };

  public render = (): React.ReactNode => {
    const { classes } = this.props;

    return (
      <Header>
        <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
          <Grid item>
            <Grid container spacing={3}>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="h6">ДЕБИТ:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      <Box fontWeight={600}>{this.state.debitLastReading.toFixed(3)} m^3/h</Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="h6">МОЩНОСТ:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      <Box fontWeight={600}>{this.state.energyLastReading.toFixed(3)} kW</Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Link to="/app/Graph/01019110" style={{ textDecoration: 'none' }} target="_blank">
              <Button variant="contained" color="primary">
                Графика на консумацията
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={1}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
            >
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    label={this.state.towerDevice.onOffSensorName1}
                    labelPlacement="bottom"
                    control={
                      <Switch
                        color="primary"
                        onClick={async (): Promise<void> => {
                          await this.toggleRelaySwitch(4);
                        }}
                        checked={this.state.relaySwitchStatusOnOff1}
                      />
                    }
                  />
                  <FormControlLabel
                    label={
                      <>
                        <p>
                          {this.props.t<string>('pump_auto_every', {
                            secs: '30',
                          })}
                        </p>
                        <p>{this.state.lastRefreshTimestamp}</p>
                      </>
                    }
                    labelPlacement="bottom"
                    control={
                      <IconButton size="large">
                        <RefreshIcon onClick={this.reloadData} fontSize="large" />
                      </IconButton>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={11}>
            <SVGProofOfConcept
              towerDevice={this.state.towerDevice}
              towerContainerCapacity={85.0}
              relaySwitchStatus={this.state.relaySwitchStatusOnOff1}
            />
          </Grid>
        </Grid>
      </Header>
    );
  };
}

const styles: StyleRulesCallback<Theme, any> = (theme: Theme) => createStyles({});

interface PumpProps extends WithRouterProps, WithTranslation, WithStyles<typeof styles> {
  classes: { [key: string]: string };
}

interface PumpState {
  towerDevice: PumpStationDeviceInfo;
  relaySwitchStatusOnOff1: boolean;
  relaySwitchStatusOnOff2: boolean;
  debitLastReading: number;
  energyLastReading: number;
  lastRefreshTimestamp: string;
}

interface MatchParams {
  deviceNumber: string;
}

export default withRouter(withStyles(styles, { withTheme: true })(withTranslation()(Pump)));
