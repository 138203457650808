import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface DewPointSensor extends GenericAnalogSensor {
  '@type': 'dew_point_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultDewPointSensor: DewPointSensor = {
  '@type': 'dew_point_sensor',
  ...defaultSensorWithoutType,
};
