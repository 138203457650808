import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface SoilMoistureSensor extends GenericAnalogSensor {
  '@type': 'soil_moisture_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultSoilMoistureSensor: SoilMoistureSensor = {
  '@type': 'soil_moisture_sensor',
  ...defaultSensorWithoutType,
};
