import React, { FC } from 'react';

import GenericAnalogConfig from './sensorConfigFields/GenericAnalogConfig';
import ModbusGenericAnalogConfig from './sensorConfigFields/ModbusGenericAnalogConfig';
import GenericDigitalConfig from './sensorConfigFields/GenericDigitalConfig';
import WaterMeterConfig from './sensorConfigFields/WaterMeterConfig';
import ModbusGenericDigitalConfig from './sensorConfigFields/ModbusGenericDigitalConfig';
import BaseSensorConfig from './sensorConfigFields/BaseSensorConfig';
import BaseModbusConfig from './sensorConfigFields/BaseModbusConfig';
import OnOffConfig from './sensorConfigFields/OnOffConfig';
import OnOffAutoConfig from './sensorConfigFields/OnOffAutoConfig';
import TasmotaOnOffConfig from './sensorConfigFields/TasmotaOnOffConfig';
import { FormulaValidationProps, SensorType } from '@thingslog/repositories';
import ParticulateMatterSensorConfig from './sensorConfigFields/ParticulateMatterSensorConfig';
import TasmotaGenericConfig from './sensorConfigFields/TasmotaGenericConfig';

const SensorConfigurationMapper: FC<SensorConfigurationMapperProps> = ({
  sensorType,
  index,
  formulaToValidateChangeCallback,
}: SensorConfigurationMapperProps) => {
  switch (sensorType) {
    //analog sensors
    case 'generic_analog_sensor':
    case 'barometric_pressure_sensor':
    case 'co_sensor':
    case 'dew_point_sensor':
    case 'generic_virtual_sensor':
    case 'leaf_wetness_sensor':
    case 'level_sensor':
    case 'load_cells':
    case 'no2_sensor':
    case 'pressure_sensor':
    case 'so2_sensor':
    case 'soil_moisture_sensor':
    case 'soil_potential_sensor':
    case 'soil_temperature_sensor':
    case 'temperature_sensor':
    case 'weight_meter':
    case 'weight_sensor':
    case 'wind_direction_sensor':
      return (
        <>
          <GenericAnalogConfig
            index={index}
            formulaToValidateChangeCallback={formulaToValidateChangeCallback}
          />
          <BaseSensorConfig index={index} />
        </>
      );
    case 'particulate_matter_sensor':
      return (
        <>
          <ParticulateMatterSensorConfig index={index} />
          <GenericAnalogConfig
            index={index}
            formulaToValidateChangeCallback={formulaToValidateChangeCallback}
          />
          <BaseSensorConfig index={index} />
        </>
      );
    case 'modbus_generic_analog_sensor':
      return (
        <>
          <ModbusGenericAnalogConfig index={index} />
          <BaseModbusConfig index={index} />
          <GenericAnalogConfig
            index={index}
            formulaToValidateChangeCallback={formulaToValidateChangeCallback}
          />
          <BaseSensorConfig index={index} />
        </>
      );

    //digital sensors
    case 'generic_digital_sensor':
    case 'gas_flow_meter':
    case 'geiger_mueller_counter':
    case 'heat_meter':
    case 'power_meter':
    case 'rain_sensor':
    case 'wind_speed_sensor':
      return (
        <>
          <GenericDigitalConfig index={index} />
          <BaseSensorConfig index={index} />
        </>
      );
    case 'water_meter':
      return (
        <>
          <WaterMeterConfig index={index} />
          <GenericDigitalConfig index={index} />
          <BaseSensorConfig index={index} />
        </>
      );
    case 'modbus_generic_digital_sensor':
      return (
        <>
          <ModbusGenericDigitalConfig index={index} />
          <BaseModbusConfig index={index} />
          <GenericDigitalConfig index={index} />
          <BaseSensorConfig index={index} />
        </>
      );
    case 'on_off_sensor':
    case 'on_off_input_sensor':
    case 'on_off_output_sensor':
      return (
        <>
          <OnOffConfig index={index} />
          <BaseSensorConfig index={index} />
        </>
      );
    case 'on_off_auto_output_sensor':
      return (
        <>
          <OnOffAutoConfig index={index} />
          <OnOffConfig index={index} />
          <BaseSensorConfig index={index} />
        </>
      );
    case 'tasmota_relay_sensor':
    case 'tasmota_alarm_input_sensor':
      return (
        <>
          <TasmotaOnOffConfig index={index} />
          <OnOffConfig index={index} />
          <BaseSensorConfig index={index} />
        </>
      );
    case 'tasmota_generic_analog_sensor':
      return (
        <>
          <TasmotaGenericConfig index={index} />
          <GenericAnalogConfig
            index={index}
            formulaToValidateChangeCallback={formulaToValidateChangeCallback}
          />
          <BaseSensorConfig index={index} />
        </>
      );
    case 'tasmota_generic_digital_sensor':
      return (
        <>
          <TasmotaGenericConfig index={index} />
          <GenericDigitalConfig index={index} />
          <BaseSensorConfig index={index} />
        </>
      );

    case 'modbus_generic_on_off_input_sensor':
    case 'modbus_generic_on_off_output_sensor':
      return (
        <>
          <BaseModbusConfig index={index} />
          <OnOffConfig index={index} />
          <BaseSensorConfig index={index} />
        </>
      );

    default:
      return <></>;
  }
};

interface SensorConfigurationMapperProps {
  sensorType: SensorType;
  index: number;
  formulaToValidateChangeCallback?: (props: FormulaValidationProps) => void;
}

export default SensorConfigurationMapper;
