import { defaultBarometricPressureSensor } from './BarometricPressureSensor';
import { defaultCOSensor } from './COSensor';
import { defaultDewPointSensor } from './DewPointSensor';
import { defaultGasFlowMeter } from './GasFlowMeter';
import { defaultGeigerMuellerCounter } from './GeigerMuellerCounter';
import { defaultGenericAnalogSensor } from './GenericAnalogSensor';
import { defaultGenericDigitalSensor } from './GenericDigitalSensor';
import { defaultGenericVirtualSensor } from './GenericVirtualSensor';
import { defaultHeatMeter } from './HeatMeter';
import { defaultHumiditySensor } from './HumiditySensor';
import { defaultLeafWetnessSensor } from './LeafWetnessSensor';
import { defaultLevelSensor } from './LevelSensor';
import { defaultLoadCells } from './LoadCells';
import { defaultModbusGenericAnalogSensor } from './ModbusGenericAnalogSensor';
import { defaultModbusGenericDigitalSensor } from './ModbusGenericDigitalSensor';
import { defaultModbusGenericOnOffInputSensor } from './ModbusGenericOnOffInputSensor';
import { defaultModbusGenericOnOffOutputSensor } from './ModbusGenericOnOffOutputSensor';
import { defaultNO2Sensor } from './NO2Sensor';
import { defaultOnOffAutoOutputSensor } from './OnOffAutoOutputSensor';
import { defaultOnOffInputSensor } from './OnOffInputSensor';
import { defaultOnOffOutputSensor } from './OnOffOutputSensor';
import { defaultOnOffSensor } from './OnOffSensor';
import { defaultParticulateMatterSensor } from './ParticulateMatterSensor';
import { defaultPowerMeter } from './PowerMeter';
import { defaultPressureSensor } from './PressureSensor';
import { defaultRainSensor } from './RainSensor';
import { Sensor } from './Sensor';
import { SensorType } from './SensorType';
import { defaultSO2Sensor } from './SO2Sensor';
import { defaultSoilMoistureSensor } from './SoilMoistureSensor';
import { defaultSoilPotentialSensor } from './SoilPotentialSensor';
import { defaultSoilTemperatureSensor } from './SoilTemperatureSensor';
import { defaultTasmotaAlarmInputSensor } from './TasmotaAlarmInputSensor';
import { defaultTasmotaGenericAnalogSensor } from './TasmotaGenericAnalogSensor';
import { defaultTasmotaGenericDigitalSensor } from './TasmotaGenericDigitalSensor';
import { defaultTasmotaRelaySensor } from './TasmotaRelaySensor';
import { defaultTemperatureSensor } from './TemperatureSensor';
import { defaultWaterMeter } from './WaterMeter';
import { defaultWeightMeter } from './WeightMeter';
import { defaultWeightSensor } from './WeightSensor';
import { defaultWindDirectionSensor } from './WindDirectionSensor';
import { defaultWindSpeedSensor } from './WindSpeedSensor';

export const SensorRegistry: Record<SensorType, Sensor> = {
  barometric_pressure_sensor: defaultBarometricPressureSensor,
  co_sensor: defaultCOSensor,
  dew_point_sensor: defaultDewPointSensor,
  gas_flow_meter: defaultGasFlowMeter,
  geiger_mueller_counter: defaultGeigerMuellerCounter,
  generic_analog_sensor: defaultGenericAnalogSensor,
  generic_digital_sensor: defaultGenericDigitalSensor,
  generic_virtual_sensor: defaultGenericVirtualSensor,
  heat_meter: defaultHeatMeter,
  humidity_sensor: defaultHumiditySensor,
  leaf_wetness_sensor: defaultLeafWetnessSensor,
  level_sensor: defaultLevelSensor,
  load_cells: defaultLoadCells,
  modbus_generic_analog_sensor: defaultModbusGenericAnalogSensor,
  modbus_generic_digital_sensor: defaultModbusGenericDigitalSensor,
  no2_sensor: defaultNO2Sensor,
  on_off_auto_output_sensor: defaultOnOffAutoOutputSensor,
  on_off_input_sensor: defaultOnOffInputSensor,
  on_off_output_sensor: defaultOnOffOutputSensor,
  on_off_sensor: defaultOnOffSensor,
  particulate_matter_sensor: defaultParticulateMatterSensor,
  power_meter: defaultPowerMeter,
  pressure_sensor: defaultPressureSensor,
  rain_sensor: defaultRainSensor,
  soil_moisture_sensor: defaultSoilMoistureSensor,
  so2_sensor: defaultSO2Sensor,
  temperature_sensor: defaultTemperatureSensor,
  soil_potential_sensor: defaultSoilPotentialSensor,
  soil_temperature_sensor: defaultSoilTemperatureSensor,
  water_meter: defaultWaterMeter,
  weight_meter: defaultWeightMeter,
  weight_sensor: defaultWeightSensor,
  wind_direction_sensor: defaultWindDirectionSensor,
  wind_speed_sensor: defaultWindSpeedSensor,
  modbus_generic_on_off_input_sensor: defaultModbusGenericOnOffInputSensor,
  modbus_generic_on_off_output_sensor: defaultModbusGenericOnOffOutputSensor,
  tasmota_generic_digital_sensor: defaultTasmotaGenericDigitalSensor,
  tasmota_generic_analog_sensor: defaultTasmotaGenericAnalogSensor,
  tasmota_relay_sensor: defaultTasmotaRelaySensor,
  tasmota_alarm_input_sensor: defaultTasmotaAlarmInputSensor,
};
