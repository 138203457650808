import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface SO2Sensor extends GenericAnalogSensor {
  '@type': 'so2_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultSO2Sensor: SO2Sensor = {
  '@type': 'so2_sensor',
  ...defaultSensorWithoutType,
};
