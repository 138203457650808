import PortBase, { defaultPortBase } from './PortBase';
import { AnalogPortType } from './PortType';

export default interface AnalogInputPort extends PortBase {
  '@type': 'analog_input_port';
  adcResolution: number;
  settlingTime: number;
  formula: string;
}

const { '@type': _, ...defaultPortWithoutType } = defaultPortBase;

export const defaultAnalogInputPort: AnalogInputPort = {
  '@type': 'analog_input_port',
  adcResolution: 16,
  settlingTime: 50,
  formula: 'x',
  ...defaultPortWithoutType,
};
