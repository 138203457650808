import OnOffSensor, { defaultOnOffSensor } from './OnOffSensor';

export default interface OnOffInputSensorBase extends OnOffSensor {
  '@type':
    | 'on_off_input_sensor'
    | 'modbus_generic_on_off_input_sensor'
    | 'tasmota_alarm_input_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultOnOffSensor;

export const defaultOnOffInputSensorBase: OnOffInputSensorBase = {
  '@type': 'on_off_input_sensor',
  ...defaultSensorWithoutType,
};
