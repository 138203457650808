import AnalogInputPort, { defaultAnalogInputPort } from './AnalogInputPort';

export default interface Voltage05InputPort extends Omit<AnalogInputPort, '@type'> {
  '@type': '0_5_V_input_port';
}

const { '@type': _, ...defaultPortWithoutType } = defaultAnalogInputPort;

export const defaultVoltage05InputPort: Voltage05InputPort = {
  '@type': '0_5_V_input_port',
  ...defaultPortWithoutType,
};
