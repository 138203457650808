import SensorBase, { defaultSensorBase } from './SensorBase';

export default interface GenericDigitalSensor extends SensorBase {
  initialValue: number;
  pulseRate: number;
  price: number;
  currency: string | null;
  offset: number | null;
  cumulative: boolean;
}

const { '@type': _, ...defaultSensorWithoutType } = defaultSensorBase;

export const defaultGenericDigitalSensor: GenericDigitalSensor = {
  '@type': 'generic_digital_sensor',
  ...defaultSensorWithoutType,
  initialValue: 0,
  pulseRate: 1,
  price: 0,
  currency: null,
  offset: null,
  cumulative: true,
};
