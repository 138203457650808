import SensorBase, { defaultSensorBase } from './SensorBase';

export default interface GenericAnalogSensor extends SensorBase {
  formula: string;
}

const { '@type': _, ...defaultSensorWithoutType } = defaultSensorBase;

export const defaultGenericAnalogSensor: GenericAnalogSensor = {
  '@type': 'generic_analog_sensor',
  formula: 'x',
  ...defaultSensorWithoutType,
};
