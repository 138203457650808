import ModbusSensorBase, { defaultModbusSensorBase } from './ModbusSensorBase';
import OnOffInputSensor, { defaultOnOffInputSensor } from './OnOffInputSensor';
import OnOffInputSensorBase from './OnOffInputSensorBase';

export default interface ModbusGenericOnOffInputSensor
  extends OnOffInputSensorBase,
    ModbusSensorBase {
  '@type': 'modbus_generic_on_off_input_sensor';
  numberBytes: number;
}

const { '@type': _, ...defaultSensorWithoutType } = defaultOnOffInputSensor;

export const defaultModbusGenericOnOffInputSensor: ModbusGenericOnOffInputSensor = {
  '@type': 'modbus_generic_on_off_input_sensor',
  ...defaultSensorWithoutType,
  ...defaultModbusSensorBase,
  numberBytes: 2,
};
