import GenericDigitalSensor, { defaultGenericDigitalSensor } from './GenericDigitalSensor';

export default interface HeatMeter extends GenericDigitalSensor {
  '@type': 'heat_meter';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericDigitalSensor;

export const defaultHeatMeter: HeatMeter = {
  '@type': 'heat_meter',
  ...defaultSensorWithoutType,
};
