import {
  AnalogSensorType,
  DeviceType,
  DigitalSensorType,
  OnOffSensorType,
  PortType,
} from '@thingslog/repositories';
import {
  OnOffInputModbusSensorType,
  OnOffOutputModbusSensorType,
  SensorType,
} from '@thingslog/repositories/src/sensor/SensorType';
import PortUtil from '../../graph/utils/PortUtil';

export const analogSensorTypeOptions: Record<AnalogSensorType, string> = {
  generic_analog_sensor: 'generic_analog_sensor',
  modbus_generic_analog_sensor: 'modbus_generic_analog_sensor',
  generic_virtual_sensor: 'generic_virtual_sensor',
  pressure_sensor: 'pressure_sensor',
  temperature_sensor: 'temperature_sensor',
  humidity_sensor: 'humidity_sensor',
  level_sensor: 'level_sensor',
  weight_meter: 'weight_meter',
  weight_sensor: 'weight_sensor',
  load_cells: 'load_cells',
  wind_direction_sensor: 'wind_direction_sensor',
  barometric_pressure_sensor: 'barometric_pressure_sensor',
  soil_moisture_sensor: 'soil_moisture_sensor',
  dew_point_sensor: 'dew_point_sensor',
  soil_temperature_sensor: 'soil_temperature_sensor',
  soil_potential_sensor: 'soil_potential_sensor',
  particulate_matter_sensor: 'particulate_matter_sensor',
  leaf_wetness_sensor: 'leaf_wetness_sensor',
  co_sensor: 'co_sensor',
  no2_sensor: 'no2_sensor',
  so2_sensor: 'so2_sensor',
  tasmota_generic_analog_sensor: 'tasmota_generic_analog_sensor',
};
export const digitalSensorTypeOptions: Record<DigitalSensorType, string> = {
  generic_digital_sensor: 'generic_digital_sensor',
  modbus_generic_digital_sensor: 'modbus_generic_digital_sensor',
  water_meter: 'water_meter',
  power_meter: 'power_meter',
  gas_flow_meter: 'gas_flow_meter',
  rain_sensor: 'rain_sensor',
  wind_speed_sensor: 'wind_speed_sensor',
  heat_meter: 'heat_meter',
  geiger_mueller_counter: 'geiger_mueller_counter',
  tasmota_generic_digital_sensor: 'tasmota_generic_digital_sensor',
};

export const onOffSensorTypeOptions: Record<OnOffSensorType, string> = {
  on_off_sensor: 'on_off_sensor',
  on_off_input_sensor: 'on_off_input_sensor',
  on_off_output_sensor: 'on_off_output_sensor',
  on_off_auto_output_sensor: 'on_off_auto_output_sensor',
  tasmota_relay_sensor: 'tasmota_relay_sensor',
  tasmota_alarm_input_sensor: 'tasmota_alarm_input_sensor',
};

export const modbusOnOffInputTypeOptions: Record<OnOffInputModbusSensorType, string> = {
  modbus_generic_on_off_input_sensor: 'modbus_generic_on_off_input_sensor',
};

export const modbusOnOffOutputTypeOptions: Record<OnOffOutputModbusSensorType, string> = {
  modbus_generic_on_off_output_sensor: 'modbus_generic_on_off_output_sensor',
};

// Available sensor types for each device model
const deviceModelSensorMapping: Partial<Record<DeviceType, SensorType[]>> = {
  TASMOTA: [
    'tasmota_generic_analog_sensor',
    'tasmota_generic_digital_sensor',
    'tasmota_relay_sensor',
    'tasmota_alarm_input_sensor',
  ],
};

const getSensorOptionsByPortType = (portType: PortType | null): SensorTypeOptions => {
  if (!portType) return null;

  if (PortUtil.isAnalogPort(portType)) return analogSensorTypeOptions;
  if (PortUtil.isDigitalPort(portType)) return digitalSensorTypeOptions;
  if (PortUtil.isOnOffPort(portType)) return onOffSensorTypeOptions;
  if (PortUtil.isModbusOnOffInputPort(portType)) return modbusOnOffInputTypeOptions;
  if (PortUtil.isModbusOnOffOutputPort(portType)) return modbusOnOffOutputTypeOptions;

  return null;
};

const filterOptionsBasedOnDeviceModel = (
  sensorOptions: SensorTypeOptions | null,
  deviceModel: string | null
): SensorTypeOptions => {
  if (!sensorOptions || !deviceModel) return sensorOptions;

  const allowedSensorTypes = deviceModelSensorMapping[deviceModel] || [];
  if (allowedSensorTypes.length === 0) return sensorOptions;

  return Object.fromEntries(
    Object.entries(sensorOptions).filter(([key]) => allowedSensorTypes.includes(key as SensorType))
  ) as SensorTypeOptions;
};

// Main function to get filtered sensor options
export const getSensorTypeOptionsBasedOnPortTypeAndDeviceModel = (
  portType: PortType | null,
  deviceModel: string | null
): SensorTypeOptions | null => {
  const sensorOptions = getSensorOptionsByPortType(portType);
  return filterOptionsBasedOnDeviceModel(sensorOptions, deviceModel);
};

export type SensorTypeOptions =
  | Record<AnalogSensorType, string>
  | Record<DigitalSensorType, string>
  | Record<OnOffSensorType, string>
  | Record<OnOffInputModbusSensorType, string>
  | Record<OnOffOutputModbusSensorType, string>
  | null;
