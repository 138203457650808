import { DeviceModel, QueryOptions, QueryResult } from '@thingslog/repositories';
import { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryKeys } from '../enums/QueryKeys';

export interface DeviceModelsQueryClient {
  getDeviceModels: () => Promise<DeviceModel[]>;
  useDeviceModelsData: (
    options?: QueryOptions<DeviceModel[]>
  ) => UseQueryResult<DeviceModel[], AxiosError>;
}

export function createDeviceModelsQueryClient(axios: AxiosInstance): DeviceModelsQueryClient {
  return new DeviceModelsQueryClientImp(axios);
}

class DeviceModelsQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public getDeviceModels = async (): Promise<DeviceModel[]> => {
    return this.axios.get('/api/devices/models').then((response: AxiosResponse<DeviceModel[]>) => {
      return response.data;
    });
  };

  public useDeviceModelsData = (
    options?: QueryOptions<DeviceModel[]>
  ): UseQueryResult<DeviceModel[], AxiosError> => {
    return useQuery<DeviceModel[], AxiosError>(
      [QueryKeys.GetDeviceModels],
      () => this.getDeviceModels(),
      options
    );
  };
}
