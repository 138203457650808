import React, { FC, useState } from 'react';
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@thingslog/ui-components';

import { format } from 'date-fns';
import { DisplayUnit } from '@thingslog/repositories';
import { useTranslation } from 'react-i18next';

const ConsumptionTable: FC<ConsumptionTableProps> = ({
  pageSize,
  autoHeight,
  rowsPerPageOptions,
  isAggregated,
  onPageSizeChange,
  ...props
}: ConsumptionTableProps) => {
  const { t } = useTranslation();
  const [size, setSize] = useState(10);

  const renderReadingCell = (params: GridRenderCellParams): JSX.Element => {
    const unit = DisplayUnit[params.row.unit] || '';
    return <span>{params.value ? `${params.value} ${unit}` : ''}</span>;
  };

  const dateFormatter = (params: GridValueFormatterParams<string>): string =>
    params.value ? format(new Date(params.value), 'dd MMM yyyy HH:mm') : '';

  const columnDefinitions: Record<string, GridColDef> = {
    deviceName: {
      field: 'deviceName',
      headerName: t('counters_export_table_device_name'),
      flex: 1,
      minWidth: 100,
    },
    deviceNumber: {
      field: 'deviceNumber',
      headerName: t('counters_export_table_device_number'),
      flex: 1,
      minWidth: 100,
      hide: true,
    },
    sensorIndex: {
      field: 'sensorIndex',
      headerName: t('consumption_export_table_sensor_index'),
      type: 'number',
      headerAlign: 'left',
      minWidth: 90,
    },
    sensorName: {
      field: 'sensorName',
      headerName: t('consumption_export_table_sensor_name'),
      minWidth: 150,
    },
    address: {
      field: 'address',
      headerName: t('consumption_export_table_address'),
      hide: true,
      minWidth: 150,
    },
    firstReadingDate: {
      field: 'firstReadingDate',
      type: 'date',
      headerName: t('consumption_export_table_first_reading_date'),
      flex: 1,
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
    firstReadingValue: {
      field: 'firstReadingValue',
      headerName: t('consumption_export_table_first_reading_value'),
      type: 'number',
      headerAlign: 'left',
      minWidth: 110,
      renderCell: renderReadingCell,
      flex: 1,
    },
    lastReadingDate: {
      field: 'lastReadingDate',
      type: 'date',
      headerName: t('consumption_export_table_last_reading_date'),
      flex: 1,
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
    lastReadingValue: {
      field: 'lastReadingValue',
      headerName: t('consumption_export_table_last_reading_value'),
      type: 'number',
      headerAlign: 'left',
      minWidth: 110,
      renderCell: renderReadingCell,
      flex: 1,
    },
    delta: {
      field: 'delta',
      headerName: t('consumption_export_table_delta'),
      type: 'number',
      headerAlign: 'left',
      minWidth: 110,
      renderCell: renderReadingCell,
    },
    average: {
      field: 'average',
      headerName: t('consumption_export_table_average'),
      type: 'number',
      headerAlign: 'left',
      minWidth: 110,
      renderCell: renderReadingCell,
    },
    consumptionForPeriod: {
      field: 'consumptionForPeriod',
      headerName: t('consumption_export_table_consumption_for_period'),
      type: 'number',
      headerAlign: 'left',
      minWidth: 140,
      renderCell: renderReadingCell,
    },
  };

  const consumptionExportColumns: GridColDef[] = [
    columnDefinitions.deviceName,
    columnDefinitions.deviceNumber,
    columnDefinitions.sensorIndex,
    columnDefinitions.sensorName,
    columnDefinitions.address,
    columnDefinitions.firstReadingDate,
    columnDefinitions.firstReadingValue,
    columnDefinitions.lastReadingDate,
    columnDefinitions.lastReadingValue,
    columnDefinitions.delta,
  ];

  const aggregatedConsumptionExportColumns: GridColDef[] = [
    columnDefinitions.deviceName,
    columnDefinitions.deviceNumber,
    columnDefinitions.sensorIndex,
    columnDefinitions.sensorName,
    columnDefinitions.firstReadingDate,
    columnDefinitions.lastReadingDate,
    columnDefinitions.delta,
    columnDefinitions.average,
    columnDefinitions.consumptionForPeriod,
  ];

  const onPageSizeChangeHandler = (pageSize: number): void => setSize(pageSize);

  return (
    <DataGrid
      pageSize={pageSize ? pageSize : size}
      rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [10, 25, 50]}
      autoHeight={autoHeight ? autoHeight : true}
      onPageSizeChange={onPageSizeChange ? onPageSizeChange : onPageSizeChangeHandler}
      columns={isAggregated ? aggregatedConsumptionExportColumns : consumptionExportColumns}
      {...props}
    />
  );
};

interface ConsumptionTableProps extends Omit<DataGridProps, 'columns'> {
  isAggregated: boolean;
}

export default ConsumptionTable;
