const i18n_es = {
  close_btn: 'Cerrar',
  search_placeholder: 'Buscar',

  label_inactive: 'Inactivo',
  label_invalid: 'Inválido',

  active_devices: ' {{devices_len}} Dispositivos activos',
  change_language: 'Cambiar idioma',
  language_changed_message: 'Idioma cambiado',

  error_select_device_message: 'Seleccione el dispositivo primero',
  select_device: 'Seleccione el dispositivo',
  select_company_menu_message: 'Seleccione empresa para ver la lista de dispositivos',

  error_forbidden_operation_not_allowed: 'No permitido',

  second: 'segundo',
  seconds: 'segundos',
  second_one: 'segundo',
  second_other: 'segundos',
  minute: 'minuto',
  minutes: 'minutos',
  minute_one: 'minuto',
  minute_other: 'minutos',
  hour: 'hora',
  hours: 'horas',
  hour_one: 'hora',
  hour_other: 'horas',
  day: 'día',
  days: 'días',
  day_one: 'día',
  day_other: 'días',
  start_date: 'Fecha Inicial',
  end_date: 'Fecha Final',
  from_date: 'Desde',
  to_date: 'Hasta',
  avg_from_date: 'Promedio desde la fecha',
  avg_to_date: 'Promedio hasta la fecha',
  period: 'Período',
  description: 'Descripción',
  date: 'Fecha',
  number: 'Serie',
  sensor: 'ID Sensor',
  apply: 'Aplicar',
  update: 'Actualizar',
  create: 'Crear',
  delete: 'Eliminar',
  export: 'Exportar',
  rssi: 'RSSI',
  ber: 'BER',
  reading: 'Lectura',
  model: 'Modelo',
  email_address: 'Correo electrónico',
  password: 'Contraseña',
  confirm_password: 'Confirmar Contraseña',
  sign_in: 'Registrarse',
  graph: 'Gráfico',
  no_data: 'Sin datos',
  every: 'Cada',
  for_company: 'Para la compañia',
  flow: 'Caudal',
  no_data_found: 'Datos no encontrados.',
  percent: 'Porcentaje',
  device_number_devicenum: 'Serie del Dispositivo: {{device_number}}',
  device_number: 'Serie del Dispositivo',
  successfully_saved: 'Exitosamente guardado',
  error_occured: 'An error occureOcurrió un errord',
  none: 'Ninguno',
  off: 'Apagado',
  no_unit: 'Sin unidad de medida',
  record_period: 'Período de Registro',

  createdOn: 'Creado el',
  createdBy: 'Creado por',

  hardware_version: 'Versión del Hardware',
  software_version: 'Versión del Software',
  transmission_period: 'Periodo de Transmisión',

  show_average: 'Mostrar promedio',
  show_readings: 'Mostrar lecturas',

  yes: 'Si',
  no: 'No',
  capital_yes: 'SI',
  capital_no: 'NO',

  sensor: 'Sensor',

  all_sensors: 'Todos los sensores',

  select_all: 'Seleccionar todo',
  deselect_all: 'Deseleccionar',

  function_last: 'Last',
  function_first: 'First',
  function_avg: 'Average',
  function_min: 'Min',
  function_max: 'Max',
  function_median: 'Median',
  function_count: 'Count',
  function_last_flow: 'Last Flow',
  function_first_flow: 'First Flow',
  function_avg_flow: 'Average Flow',
  function_max_flow: 'Max Flow',
  function_min_flow: 'Min Flow',
  function_median_flow: 'Median Flow',

  page_title_header_number_with_name_absent: '{{title}} - {{device_number}}',
  page_title_header_number_with_name_present: '{{title}} - {{device_name}} ({{device_number}})',

  not_set: 'No establecido',

  // Sensor Types
  generic_analog_sensor: 'Sensor analógico genérico',
  generic_virtual_sensor: 'Sensor virtual genérico',
  generic_digital_sensor: 'Sensor Digital Genérico',
  modbus_generic_analog_sensor: 'Sensor Analógico Genérico Modbus',
  modbus_generic_digital_sensor: 'Sensor Digital Genérico Modbus',
  pressure_sensor: 'Sensor de presión',
  temperature_sensor: 'Sensor de temperatura',
  humidity_sensor: 'Sensor de humedad',
  level_sensor: 'Sensor de nivel',
  weight_meter: 'Medidor de peso',
  weight_sensor: 'Sensor de peso',
  load_cells: 'Celdas de carga',
  wind_direction_sensor: 'Sensor de dirección del viento',
  barometric_pressure_sensor: 'Sensor de presión barométrica',
  soil_moisture_sensor: 'Sensor de humedad del suelo',
  dew_point_sensor: 'Sensor de punto de rocío',
  soil_temperature_sensor: 'Sensor de temperatura del suelo',
  soil_potential_sensor: 'Sensor de potencial del suelo',
  particulate_matter_sensor: 'Sensor de material particulado',
  leaf_wetness_sensor: 'Sensor de humedad de hojas',
  water_meter: 'Medidor de agua',
  power_meter: 'Medidor de energía',
  gas_flow_meter: 'Medidor de flujo de gas',
  rain_sensor: 'Sensor de lluvia',
  wind_speed_sensor: 'Sensor de velocidad del viento',
  heat_meter: 'Medidor de calor',
  geiger_mueller_counter: 'Contador Geiger-Mueller',
  on_off_sensor: 'Sensor Encendido/Apagado',
  on_off_input_sensor: 'Sensor de Entrada Encendido/Apagado',
  on_off_output_sensor: 'Sensor de Salida Encendido/Apagado',
  on_off_auto_output_sensor: 'Sensor de Salida Encendido/Apagado/Automático',
  tasmota_relay_sensor: 'Sensor de Relé Tasmota',
  modbus_generic_on_off_input_sensor: 'Sensor de Entrada Encendido/Apagado Genérico Modbus',
  modbus_generic_on_off_output_sensor: 'Sensor de Salida Encendido/Apagado Genérico Modbus',
  co_sensor: 'Sensor de CO',
  no2_sensor: 'Sensor de NO2',
  so2_sensor: 'Sensor de SO2',

  // Port Types
  digital_input_port: 'Puerto de Entrada Digital',
  pulse_input_port: 'Puerto de Entrada de Pulso',
  pulse_s0_input_port: 'Puerto de Entrada de Pulso S0',
  analog_input_port: 'Puerto de Entrada Analógica',
  '4_20_mA_input_port': 'Puerto de Entrada 4-20mA',
  '0_5_V_input_port': 'Puerto de Entrada 0-5V',
  on_off_input_port: 'Puerto de Entrada On/Off',
  on_off_output_port: 'Puerto de Salida On/Off',
  generic_modbus_on_off_input_port: 'Puerto de Entrada On/Off Genérico Modbus',
  generic_modbus_on_off_output_port: 'Puerto de Salida On/Off Genérico Modbus',

  /*
   * |===============|
   * | Period Picker |
   * |===============|
   */
  period_date_range: 'Rango de fechas',
  period_custom: 'Personalizado',
  period_today: 'Hoy',
  period_week: 'Semana',
  period_month: 'Mes',
  period_yesterday: 'Ayer',
  period_last_week: 'La semana pasada',
  period_last_month: 'El mes pasado',
  period_last_1_hours: 'Ultima hora',
  period_last_2_hours: 'últimas 2 horas',
  period_last_4_hours: 'últimas 4 horas',
  period_last_24_hours: 'últimas 24 horas',
  period_last_48_hours: 'últimas 48 horas',

  /*
   * |=====================|
   * | Hook Error Messages |
   * |=====================|
   */
  error_cannot_fetch_device: 'No se pudo obtener el dispositivo.',
  error_cannot_fetch_device_config: 'No se pudo obtener la configuración del dispositivo.',
  error_cannot_fetch_ports_config: 'No se pudo recuperar la configuración de los puertos.',
  error_cannot_fetch_flow_stats: 'No se pudieron obtener las estadísticas de caudal.',
  error_cannot_fetch_time_stats: 'No se pudieron obtener las estadísticas de tiempo.',
  error_cannot_fetch_general_stats: 'No se pudieron obtener las estadísticas generales.',
  error_cannot_fetch_qflow_stats: 'No se pudieron obtener las estadísticas de qflow.',

  signup_company_name: 'Nombre de Empresa',

  sign_up_form_first_name: 'Nombre',
  sign_up_form_last_name: 'Apellido',
  sign_up_form_email: 'Correo electrónico',
  sign_up_form_password: 'Contraseña',
  sign_up_form_confirm_password: 'Confirmar Contraseña',
  sign_up_form_company_name: 'Nombre de Empresa',

  button_save: 'Salvar',
  button_apply: 'Aplicar',
  button_update: 'Actualizar',
  button_create: 'Crear',
  button_delete: 'Eliminar',
  button_continue: 'Continuar',
  button_back: 'Atrás',

  capital_add_btn: 'AGREGAR',
  capital_create_btn: 'CREAR',
  capital_update_btn: 'ACTUALIZAR',
  capital_remove_btn: 'REMOVER',
  capital_cancel_btn: 'CANCELAR',

  role_roles: 'Roles',
  role_user: 'Usuario',
  role_public: 'Público',
  role_admin: 'Administrador',
  role_super_admin: 'Super-Administrador',
  role_restricted: 'Restringido',

  device_counters_cumulative_graph_graph: 'Gráfico, serie del dispositivo: {{device_number}}',
  device_counters_cumulative_graph_must_have_analog_sensor:
    'Debe tener un sensor analógico habilitado.',

  sensor_statistics_min: 'Mín: {{min}}',
  sensor_statistics_max: 'Máx: {{max}}',
  sensor_statistics_avg: 'Promedio: {{avrg}}',
  sensor_statistics_total: 'Total: {{total}}',

  chart_statistics_min: 'Mín:',
  chart_statistics_max: 'Máx:',
  chart_statistics_avg: 'Promedio:',
  chart_statistics_total: 'Total:',

  menu_main: 'Principal',
  menu_device: 'Dispositivo',

  submenu_devices: 'Dispositivos',
  submenu_account: 'Cuenta',
  submenu_device_reports: 'Informes de dispositivos',
  submenu_settings: 'Ajustes',

  link_selected_device: 'Dispositivo Seleccionado',
  link_devices: 'Lista de dispositivos',
  link_accounts: 'Cuentas',
  link_utility_rate: 'Tarifa',
  link_link_device: 'Vincular Dispositivo',
  link_create_user: 'Crear Usuario',
  link_icons: 'Íconos',
  link_licenses: 'Licencias',
  link_accounts_manager: 'Cuentas de Administración',
  link_transmissions: 'Transmisiones',
  link_map: 'Mapa',
  link_inventory: 'Inventario',
  link_devices_manager: 'Administrador de Dispositivos',
  link_battery: 'Batería',
  device_groups: 'Grupos de Dispositivos',
  link_signal: 'Señal',
  link_alarms: 'Alarmas',
  link_value_ranges: 'Eventos de rango de valor',
  link_device_account_lookup: 'Búsqueda de dispositivos',
  link_show_users: 'Usuarios',
  link_dashboard: 'Panel de Control',
  link_reports: 'Reportes',
  link_billing_report: 'Informe de facturación',

  link_all_devices: 'Todos los Dispositivos',
  link_readings: 'Lecturas',
  link_flow: 'Caudal',
  link_graph: 'Gráfico',
  link_new_graph: 'Gráfico Instantáneo',
  link_pump_control: 'Control de la Bomba',
  link_relay_control: 'Control del Relay',
  link_alarm_config: 'Configurar Alarmas',
  link_statistics: 'Estadísticas',
  link_customer: 'Datos del Cliente',
  link_meter: 'Datos del Medidor',
  link_config: 'Sincronización de configuración',
  link_initial_config: 'Configurar Comunicación (antigua)',
  link_sensor_config: 'Configurar Sensor (antigua)',
  link_sensors_and_network_config: 'Configuración de parámetros',
  link_export: 'Exportar Lecturas',
  link_customer_billing_report: 'Informe de facturación de clientes',
  link_flow_generator: 'Generador de Caudal',
  link_commands: 'Enviar Comandos',
  link_copy_config: 'Copiar configuración',
  switch_to_new_menu_label: 'Usar nuevo menú',
  switch_to_old_menu_label: 'Usar menú antiguo',

  link_profile: 'Perfil',
  link_language: 'Idioma',
  link_sign_out: 'Desconectar',

  chip_early_access: 'Acceso Rápido',

  pulse_sensor_1: 'Sensor de Pulso 1',
  pulse_sensor_2: 'Sensor de Pulso 2',
  analog_sensor_1: 'Sensor Análogo 1',
  analog_sensor_2: 'Sensor Análogo 2',
  analog_sensor_3: 'Sensor Análogo 3',
  analog_sensor_4: 'Sensor Análogo 4',
  analog_sensor_5: 'Sensor 1',
  analog_sensor_6: 'Sensor 2',
  analog_sensor_7: 'Sensor 3',
  analog_sensor_8: 'Sensor 4',
  on_off_sensor_1: 'Sensor de encendido/apagado 1',
  on_off_sensor_2: 'Sensor de encendido/apagado 2',
  on_off_sensor_3: 'Relé de encendido/apagado 1',
  on_off_sensor_4: 'Relé de encendido/apagado 2',

  device_icon: 'Icono de dispositivo',
  sensor_icon: 'Icono de sensor',
  icon_no_icon: 'Sin icono',
  icon_barometer: 'Barómetro',
  icon_door_closed: 'Puerta cerrada',
  icon_door_open: 'Puerta abierta',
  icon_electricity: 'Electricidad',
  icon_energy_meter: 'Medidor de energía',
  icon_gas_flow_meter: 'Medidor de flujo de gas',
  icon_gas: 'Gas',
  icon_generic_particle_meter: 'Medidor de partículas genérico',
  icon_generic: 'Genérico',
  icon_heat_meter: 'Medidor de calor',
  icon_humidity: 'Humedad',
  icon_leaf_moisture: 'Humedad de la hoja',
  icon_level_metering: 'Medición de nivel',
  icon_pivot: 'Pivote',
  icon_pressure: 'Presión',
  icon_radiation: 'Radiación',
  icon_rain: 'Lluvia',
  icon_soil_moisture: 'La humedad del suelo',
  icon_temperature: 'La temperatura',
  icon_water_meter: 'Medidor de agua',
  icon_water: 'Agua',
  icon_wind_direction: 'Dirección del viento',
  icon_wind_speed: 'Velocidad del viento',
  icon_pump: 'Bomba',
  icon_apartment: 'Edificio de Apartamentos',
  icon_base_station: 'Estación Base',
  icon_clinic: 'Clínica',
  icon_hospital: 'Hospital',
  icon_house: 'Casa',
  icon_mayor_office: 'Edificio de la Oficina del Alcalde',
  icon_police_station: 'Estación de Policía',
  icon_public_restroom: 'Instalación de Baño Público',
  icon_stadium: 'Estadio',
  icon_gas_station: 'Estación de servicio',
  icon_controller: 'Controlador',

  device_icon_new_portal: 'Ícono (nuevo portal)',
  device_icon_old_portal: 'Ícono (antiguo portal)',

  very_low_level_alarm: 'Alarma de nivel muy bajo',
  low_level_alarm: 'Alarma de nivel bajo',
  high_level_alarm: 'Alarma de nivel alto',
  very_high_level_alarm: 'Alarma de nivel muy alto',

  sensor_conversion_formula: 'Fórmula de conversión del sensor',
  delete_old_counters: 'Borrar informes antiguos',
  device_config_select_record_period: 'Seleccionar período de registro',
  device_config_select_transmission_period: 'Seleccionar período de transmisión',

  devices_table_number: 'Número',
  devices_table_name: 'Nombre',
  devices_table_device_number: 'Número de dispositivo',
  devices_table_device_name: 'Nombre del dispositivo',
  devices_table_business_partner: 'Socio comercial',
  devices_table_quarter: 'Trimestre',
  devices_table_address: 'Dirección',
  devices_table_error: 'No se han podido cargar los dispositivos',
  devices_table_no_devices_found: 'No se encontraron dispositivos',
  devices_table_description: 'Descripción',
  devices_column_header_search: 'Buscar...',

  alarm_config_header: 'Configurar alarmas',
  alarm_config_notification_email: 'Correos electrónicos de notificación',
  alarm_config_notification_email_place_holder:
    'Lista de correos electrónicos (separados por comas)',
  alarm_config_missed_transmission_email:
    'Direcciones de correo electrónico para transmisiones perdidas',
  alarm_config_missed_transmission_email_place_holder:
    'Lista de correo electrónico de transmisiones perdidas (separadas por comas)',
  alarm_config_high_consumption_threshold:
    'Umbral de consumo alto para el período de transmisión {{transmission_every}} {{transmission_period}} [{{units}}]',
  alarm_config_low_flow_alarm_threshold: 'Umbral de Alarma de Flujo Bajo [{{units}}]',
  alarm_config_high_flow_alarm_threshold: 'Umbral de Alarma de Flujo Alto [{{units}}]',
  alarm_config_non_zero_consumption_time_range: 'Intervalo de tiempo de consumo distinto de cero',
  alarm_config_high_low_flow_time_range: 'Intervalo de tiempo para caudal alto/bajo',
  alarm_config_start_time: 'Hora de inicio',
  alarm_config_end_time: 'Hora de término',
  alarm_config_flow_calc_time_base: 'Base de tiempo para cálculo de caudal',
  alarm_config_saved_msg: 'Configuración de Alarma Guardada.',
  alarm_config_email_validation_error:
    'Por favor, ingrese direcciones de correo electrónico válidas, separadas por una sola coma y sin espacio, con un máximo de 255 caracteres.',

  alarm_config_non_zero_consumption_time_range_error:
    'Debe tener una hora de inicio y una hora de fin',
  alarm_config_low_flow_severity: 'Gravedad de flujo bajo',
  alarm_config_high_flow_severity: 'Gravedad de flujo alto',
  alarm_config_high_consumption_severity: 'Gravedad de alto consumo',
  alarm_config_all_zero_severity: 'Gravedad de consumo cero',
  alarm_config_non_zero_consumption_severity: 'Gravedad de consumo no cero',
  alarm_config_on_off_severity: 'Gravedad de encendido/apagado',

  alarms_title: 'Alarmas',
  alarms_title_with_dev_num: 'Alarmas, serie del dispositivo: {{device_number}}',
  alarms_type: 'Tipo de Alarma',
  alarms_active_status: 'Actividad',
  alarms_active_dropdown_label: 'Activo',
  alarms_inactive_dropdown_label: 'Inactivo',

  device_battery_title: 'Nivel de batería',
  device_battery_voltage: 'Voltaje, mV',
  device_battery_level: 'Nivel, %',

  device_signal_title: 'Intensidad de señal',

  device_counters_graph_header: 'Gráfico de consumo',

  device_instant_graph_header: 'Gráfico Instantáneo',

  devices_signal_title: 'Señal de dispositivos',
  devices_signal_level: 'Nivel de señal',
  devices_signal_level_percent: 'Nivel, %',

  devices_battery_title: 'Nivel de baterías',
  devices_battery_level: 'Nivel de batería, %',
  devices_battery_voltage: 'Voltaje, mV',

  reports_export_title: 'Exportación de Lecturas',

  device_counter_export_title:
    'Exportar contadores de dispositivos, serie del dispositivo: {{device_number}}',

  device_config_record_and_transmission_period: 'Períodos de registro y transmisión',
  device_config_record_period: 'Período de Registro',
  device_config_transmission_period: 'Periodo de Transmisión',
  device_config_start_setup: 'Iniciar Configuración',
  device_config_setup_message:
    'Configuración Guardada. Ahora pase un imán por el costado del dispositivo.',
  device_config_header: 'Configurar dispositivo',
  device_config_config_date: 'Fecha de configuración: {{date}}',
  device_config_config_status: 'Estado',
  device_config_config_status_configured: 'Sincronizado',
  device_config_config_status_not_configured: 'No sincronizado',
  device_config_time_zone: 'Zona horaria',
  device_config_pulse_per: '{{x}} por pulso',
  device_config_sensor_type: 'Tipo de Sensor',
  device_config_meter_type: 'Tipo de Medidor',
  device_config_select_time_zone: 'Selecciona la zona horaria',
  device_config_missed_transmission_alarm_severity: 'Gravedad de la alarma de transmisión perdida',
  device_config_pulse_coef_not_changed_message:
    'Advertencia: El "Coeficiente de pulso" personalizado permanece sin cambios ante los cambios en "Lectura Inicial"',
  device_config_does_not_support_this_model:
    'Este modelo de dispositivo no es compatible en esta página.',

  device_digits_fraction: 'Decimales',
  device_digits_digits: 'Enteros',
  device_digits_initial_reading: 'Lectura Inicial',
  device_digits_alert_too_many_total_digits: 'El número total de dígitos debe ser <= 9',
  device_digits_alert_too_small_total_digits: 'El número total de dígitos debe ser >= 4',
  device_digits_alert_too_many_total_fraction:
    'El número de dígitos después del punto decimal debe ser <= 3 y el número total de dígitos debe ser <= 9',
  device_digits_alert_too_small_total_fraction:
    'El número de dígitos después del punto decimal debe ser >= 0 y el número total de dígitos debe ser >= 4',

  device_transmissions_at: 'Transmisiones del dispositivo al {{query_date}}',
  device_transmissions_last_transmission_short: 'Última transmisión',
  device_transmissions_next_transmissions_short: 'Próxima transmisión',
  device_transmissions_days: 'Días',
  device_transmissions_transmissions_short: 'Transm',
  device_transmissions_count: 'Conteo',
  device_transmissions_fail: 'Fallar',
  device_transmissions_customer: 'Cliente',
  device_transmissions_address: 'Dirección',
  device_transmissions_ok: 'OK',

  link_device_link_device_to_my_account: 'Vincular dispositivo a mi cuenta',
  link_device_device_number: 'Número del Dispositivo',
  link_device_unlink_device: 'Desvincular Dispositivo',
  link_device_with_device_number: `Vincular dispositivo: {{device_number}}`,
  unlink_device_with_device_number: `Desvincular dispositivo: {{device_number}}`,
  link_device_devices_counts:
    'Dispositivos vinculados: {{linked_devices_count}} / {{allowed_devices_count}}',
  link_device_success_message: 'Dispositivo asociado con éxito',
  unlink_device_success_message: 'Dispositivo desvinculado con éxito',
  link_device_enter_device_number_error: 'Ingrese un número de dispositivo',
  link_device_select_company_error: 'Seleccione una empresa',
  link_device_default_error: 'Hubo un problema al vincular/desvincular el dispositivo',
  link_device_company_not_found_error: 'Empresa no encontrada',
  link_device_device_not_found_error: 'Dispositivo {{device_number}} no encontrado',
  link_device_device_not_linked_error: 'Dispositivo {{device_number}} no está vinculado',
  link_device_device_already_linked_error: 'Dispositivo {{device_number}} ya está vinculado',
  link_device_device_limit_reached_error: 'Límite de dispositivos alcanzado',
  link_device_operation_not_allowed_error: 'Operación no permitida',

  device_search_device_number: 'Número del Dispositivo',

  device_manager_create_or_delete_device: 'Crear o eliminar dispositivo: {{device_number}}',
  device_manager_device_number: 'Número del Dispositivo',
  device_manager_device_model: 'Modelo de Dispositivo',
  device_manager_sw_version_validation:
    'El formato requerido es X.X.X o X.X.X- y X.X.X_ seguido de caracteres, donde X es un número',
  device_manager_hw_version_validation: 'El formato requerido es X.X.X donde X es un número',
  device_manager_software_version: 'Versión del Software',
  device_manager_hardware_version: 'Versión del Hardware',

  dashboard_alarms: 'Alarmas',
  dashboard_batteries: 'Baterías',
  dashboard_signal: 'Señal',
  dashboard_transmission: '{{alarm_type}} ({{alarm_count}})',
  dashboard_chart_battery_level: 'Nivel de bateria',
  dashboard_chart_signal_level: 'Nivel de señal',
  dashboard_chart_number_of_devices: 'Número de dispositivos',
  dashboard_select_company_header: 'Seleccione una cuenta para ver el tablero',
  dashboard_select_company_from: 'Desde aquí: ',
  dashboard_select_company_accounts_page_link: 'Cuentas',

  alarm_type_no_zero_consumption: 'Consumo Constante',
  alarm_type_high_consumption: 'Alto Consumo',
  alarm_type_high_flow: 'Alto Caudal',
  alarm_type_missed_transmission: 'Transmisión Fallida',
  alarm_type_low_flow: 'Bajo Caudal',
  alarm_type_all_zero_consumption: 'Sin Consumo',
  alarm_type_device_removed: 'Dispositivo Eliminado',
  alarm_type_on_off_alarm: 'Alarma de encendido/apagado',
  alarm_type_low_level: 'Alarma de Nivel Bajo',
  alarm_type_high_level: 'Alarma de Nivel Alto',
  alarm_type_tamper: 'Intervención',
  alarm_type_oversized: 'Sobre-dimensionado',
  alarm_type_undersized: 'Sub-dimensionado',
  alarm_type_low_battery: 'Batería baja',
  alarm_type_blockage: 'Detenido',
  alarm_type_burst: 'Fuga Importante',
  alarm_type_reserve_flow: 'Flujo Inverso',

  device_counter_graph_min: 'Mín: {{min}}',
  device_counter_graph_max: 'Máx: {{max}}',
  device_counter_graph_avg: 'Promedio: {{avrg}}',
  device_counter_graph_total: 'Total: {{total}}',
  device_counter_graph_average: 'Promedio:',
  device_counter_graph_no_data: 'Sin datos',
  device_counter_graph_cant_calculate_flow_analog:
    '¡No se puede calcular el flujo para un sensor analógico!',
  device_counter_graph_must_have_digital_sensor: 'Debe tener un sensor digital habilitado.',

  customer_info_header: 'Información del Cliente',
  customer_info_business_partner_number: 'Cuenta',
  customer_info_contact_data: 'Dato de Contacto',
  customer_info_contract_number: 'Rol de Lectura',
  customer_info_name: 'Nombre del Cliente',
  customer_info_description: 'Descripción',
  customer_info_billing_group: 'Grupo de facturación',
  customer_info_municipality: 'Ciudad',
  customer_info_quarter: 'Región',
  customer_info_street: 'Calle',
  customer_info_block: 'Block',
  customer_info_entr: 'Departamento',
  customer_info_customer_type: 'Tipo de Cliente',
  customer_info_customer_type_commercial: 'Comercial',
  customer_info_customer_type_domestic: 'Doméstico',
  customer_info_customer_type_block_of_flats: 'Condominio',
  customer_info_customer_type_budget: 'Remarcador',

  meter_info_meter_info: 'Información del Medidor: {{device_number}}',
  meter_info_factory_number: 'Serie del Medidor',
  meter_info_brand: 'Marca',
  meter_info_enter_diameter: 'Introduzca el Diámetro',
  meter_info_mount_date: 'Fecha de Instalación',
  meter_info_meter_info_saved: 'Información del Medidor Guardada.',

  device_initial_config_header: 'Configuración Inicial del Dispositivo',
  device_initial_device_model: 'Modelo del Dispositivo',
  device_initial_config_address: 'Dirección',
  device_initial_config_port: 'Puerto',
  device_initial_config_resolve_address: 'Resolver dirección (usar DNS)',
  device_initial_config_simcard_provider: 'Proveedor de Tarjeta SIM',
  device_initial_config_simcard_number: 'Número de Tarjeta SIM',
  device_initial_config_bands: 'bandas',
  device_initial_config_OTAA_appkey: 'OTAA - appkey',
  device_initial_config_OTAA_deveui: 'OTAA - deveui',
  device_initial_config_OTAA_appeui: 'OTAA - appeui',
  device_initial_config_ABP_appskey: 'ABP - appskey',
  device_initial_config_ABP_nwkskey: 'ABP - nwkskey',
  device_initial_config_ABP_devaddr: 'ABP - devaddr',
  device_initial_config_ABP_deveui: 'ABP - deveui',
  device_initial_config_lora_app_mode: 'Modo de aplicación',
  device_initial_config_app_id: 'ID de la aplicación',
  device_initial_config_profile_id: 'ID de perfil',
  device_initial_config_platform_name: 'Nombre de la plataforma',
  device_initial_config_thingslog_lora_platform: 'Plataforma ThingsLog LoRa',
  device_receive_data_binary: 'Recibir datos binarios',
  device_send_data_binary: 'Enviar datos binarios',
  device_mqtt_config: 'MQTT configuración',
  device_mqtt_config_qos: 'Calidad de servicio',
  device_mqtt_config_username: 'Nombre de usuario',
  device_mqtt_config_password: 'Contraseña',
  device_mqtt_config_certificate: 'Certificado',
  device_initial_config_apn_username: 'Nombre de Usuario APN',
  device_initial_config_apn_password: 'Contraseña APN',
  device_initial_config_gprs_radio_mode: 'Modo de Radio GPRS',
  device_initial_config_grps_radio_mode_auto_option: 'Automático',
  device_initial_config_net_attached_delay: 'Retraso de transmisión',
  device_initial_config_preferred_network_type: 'Tipo de Red Preferido',
  device_initial_config_preferred_network_default_option: 'Por defecto',
  device_initial_config_mcc_mnc: 'MCC-MNC',
  device_initial_config_slave_devices: 'Dispositivos esclavos',
  device_initial_config_modbus_address: 'Dirección Modbus',
  device_initial_config_modbus_master_device: 'Número del dispositivo maestro',

  sensor_config_header: 'Configuración de Sensor',
  sensor_config_name: 'Nombre',
  sensor_config_sensor_name: 'Nombre del Sensor',
  sensor_config_sensor_icon: 'Nombre icónico',
  sensor_config_description: 'Descripción',
  sensor_config_model: 'Modelo',
  sensor_config_serial_number: 'Número de Serie',
  sensor_config_manufacturer: 'Fabricante',
  sensor_config_diameter: 'Diámetro',
  sensor_config_price: 'Precio',
  sensor_config_currency: 'Moneda',
  sensor_config_supported_visualization_types: 'Tipos de visualización soportados',
  sensor_config_port_type: 'Tipo de Puerto',
  sensor_config_port_type_digital_input_port: 'Puerto de Entrada Digital',
  sensor_config_port_type_analog_input_port: 'Puerto de Entrada Analógica',
  sensor_config_port_type_pulse_s0_input_port: 'Puerto de Entrada de Pulso S0',
  sensor_config_port_type_pulse_input_port: 'Puerto de Entrada de Pulsos',
  sensor_config_port_type_on_off_input_port: 'Puerto de Entrada encendido/apagado',
  sensor_config_port_type_on_off_output_port: 'Puerto de Salida encendido/apagado',
  sensor_config_port_conversion_formula: 'Fórmula de Conversión de Puerto',
  sensor_config_formula: 'Fórmula',
  sensor_config_offset: 'Compensación',
  sensor_config_sensor_index_tooltip: 'índice de sensores: {{sensor_index}}',
  sensor_config_visible: 'Visibilidad',
  sensor_config_order_number: 'Número de pedido',
  sensor_config_visible_true: 'Visible',
  sensor_config_visible_false: 'Invisible',
  sensor_config_use_in_sensor_aggregation: 'Uso en agregación de sensores',
  sensor_config_use_in_sensor_aggregation_true: 'Usar',
  sensor_config_use_in_sensor_aggregation_false: 'No usar',
  sensor_config_value_labels: 'Etiquetas de valores',
  sensor_config_label_for_value_zero: 'Etiqueta para el valor "0"',
  sensor_config_label_for_value_one: 'Etiqueta para el valor "1"',
  sensor_config_inverted_read_state_value: 'Estado de lectura invertido',
  sensor_config_inverted_read_state_value_true: 'Invertido',
  sensor_config_inverted_read_state_value_false: 'No invertido',
  sensor_config_register_address: 'Dirección del registro',
  sensor_config_data_type: 'Tipo de datos',
  sensor_config_count: 'Cantidad',
  sensor_config_number_bytes: 'Número de bytes',
  sensor_config_particle_size: 'Tamaño de partícula',
  sensor_config_power: 'Potencia',
  sensor_config_measurement_type: 'Tipo de medición',
  sensor_config_measurement_group: 'Grupo de medición',
  tasmota_generic_digital_sensor: 'Sensor digital genérico Tasmota',
  tasmota_alarm_input_sensor: 'Sensor de entrada de alarma Tasmota',
  tasmota_generic_analog_sensor: 'Sensor analógico genérico Tasmota',

  device_counter_statistics_statistics_id: 'Estadísticas, id: {{device_number}}, {{description}}',
  device_counter_statistics_quarter: 'Localidad',
  device_counter_statistics_flow: 'Caudal',
  device_counter_statistics_hour: 'Hora',
  device_counter_statistics_quantity: 'Cantidad',
  device_counter_statistics_value: 'Valor',
  device_counter_statistics_digital_ports_must_be_present:
    'Debe tener una entrada de pulso conectada al sensor de un medidor de agua para ver esta tabla.',
  device_counter_statistics_name_must_have_initialized_value:
    'Los parámetros Q3, R y Diámetro son necesarios en la entrada de pulsos conectada al sensor del medidor de agua.',

  company_manager_title: 'Crear o borrar cuenta: {{company_name}}',
  company_manager_account_name: 'ID Cuenta',
  company_manager_solution: 'Тipo de proyecto',
  company_manager_solution_option_none: 'Sin asignar',
  company_manager_solution_option_agri: 'Agrario',
  company_manager_solution_option_air_quality: 'Aire limpio',
  company_manager_solution_option_street_lights: 'Alumbrado público',
  company_manager_solution_option_pump_station: 'Estación de bombeo',
  company_manager_license: 'Licencia',
  company_manager_account: 'Cuenta',
  company_manager_create_company: 'Crear empresa',
  company_manager_create_company_error: 'Hubo un problema al crear su empresa',
  company_manager_license_info_box:
    'El adjunto de licencia requiere una ID de cuenta válida. Verifique dos veces el valor de su ID de cuenta si desea incluirlo en el proceso de creación.',
  company_manager_valid_from: 'Válido desde',
  company_manager_valid_to: 'Válido hasta',
  company_manager_solution_type: 'Tipo de solución',
  company_manager_contact_id: 'ID de contacto',
  company_manager_account_id: 'ID de cuenta',
  company_manager_license_type: 'Tipo de Licencia',
  company_manager_timezone: 'Zona horaria',
  company_manager_country: 'País',
  company_manager_countries_error: 'No se pudieron cargar los países',
  company_manager_timezones_error: 'No se pudieron cargar las zonas horarias',
  company_manager_account_info: 'Información de la cuenta',
  company_manager_address: 'Dirección',
  company_manager_city: 'Ciudad',
  company_manager_municipality: 'Municipio',
  company_manager_area: 'Área',
  company_manager_zip_code: 'Código postal',
  company_manager_vat_number: 'Número de IVA',

  company_manager_license_per_device: 'Por dispositivo',
  company_manager_license_per_reading: 'Por informe',
  company_manager_license_per_sensor: 'Por sensor',
  company_manager_license_per_poc: 'Por Prueba de Concepto (POC)',
  company_manager_license_per_remote_node: 'Por Nodo Remoto',

  company_manager_creating_account: 'Creando cuenta: {{company_name}}',
  company_manager_deleting_account: 'Eliminando cuenta: {{company_name}}',
  company_manager_account_created: 'Cuenta creada correctamente',
  company_manager_account_deleted: 'Cuenta eliminada correctamente',

  company_manager_update_company: 'Actualizar cuenta',
  company_manager_delete_company: 'Eliminar cuenta',
  company_manager_actions: 'Acciones',
  company_manager_delete_modal_description:
    '¿Estás seguro de que quieres eliminar esta cuenta? La acción es irreversible.',
  company_manager_confirm_button: 'Confirmar',
  company_manager_cancel_button: 'Cancelar',
  company_manager_delete_company_error_message: 'Ocurrió un error al eliminar la cuenta',
  company_manager_update_btn: 'Actualizar',
  company_manager_child_company_warning_message: 'Creando una subcuenta de',
  company_manager_current_logged_in_company: 'la cuenta en la que estás actualmente conectado',
  company_manager_current_selected_company: 'la cuenta que has seleccionado actualmente',
  accounts_child_companies: 'Cuentas secundarias',
  accounts_contactRef: 'ID de contacto',
  accounts_accountRef: 'ID de cuenta',
  company_manager_accountRef_is_disabled_because_of_license_type:
    'El ID de cuenta está deshabilitado porque hay una licencia por POC',
  accounts_none: 'Ninguno',
  company_manager_update_company_success: 'Cuenta actualizada con éxito',
  company_manager_update_company_error: 'Ocurrió un error al actualizar la cuenta',
  company_manager_account_name_exists: 'Ya existe una cuenta con este nombre.',

  license_max_readings: 'Lecturas máximas',
  license_max_devices: 'Dispositivos máximos',
  license_max_sensors: 'Sensores máximos',

  license_manager_title: 'Actualizar licencia: {{company_name}}',
  license_manager_license: 'Licencia',
  license_manager_license_updated: 'Licencia actualizada',

  companies_title: 'ID Compañía: {{company_number}}',
  companies_table_name: 'Nombre',
  companies_number_of_accounts: 'Numero de cuentas:',
  companies_search: 'Búsqueda',

  users_title: 'Usuarios',
  units_type: 'Tipo de Unidades',

  reports_export_format_type: 'Formato',

  login_form_forgot_password: '¿Se te olvidó tu contraseña?',

  pump_auto_every: 'Auto cada {{secs}} segundos',
  pump_svg_last_updated: 'Última Actualización: {{timestamp}}',
  pump_svg_power_supply: 'Fuente de Poder',
  pump_svg_water_level_controller: 'Controlador de Nivel de Agua',
  pump_svg_pump: 'Bomba',
  pump_svg_level_sensor: 'Sensor de Nivel',
  pump_svg_pump_on_sensor: 'Sensor Bomba Encendida',
  pump_svg_pump_off_sensor: 'Sensor Bomba Apagada',
  pump_svg_dry_run_sensor: 'Sensor Funcionamiento en Seco',

  device_groups_error_please_select_company: 'Seleccione una empresa.',

  device_groups_btn_add: 'Agregar',
  device_groups_btn_edit: 'Editar',
  device_groups_btn_delete: 'Borrar',
  device_groups_btn_cancel: 'Cancelar',
  device_groups_tooltip_edit: 'Editar',
  device_groups_tooltip_delete: 'Borrar',
  device_groups_color_picker_label: 'Color del grupo de dispositivos',

  device_groups_name: 'Nombre',
  device_groups_devices: 'Dispositivos',
  device_groups_view_devices: 'Ver {{count}} dispositivos',
  device_groups_group_name: 'Nombre del grupo',
  device_groups_parent_device_group: 'Grupo padre de dispositivos',
  device_groups_menuitem_none: 'Ninguno',
  device_groups_add_device_group: 'Agregar grupo de dispositivos',
  device_groups_edit_device_group: 'Editar grupo de dispositivos',
  device_groups_delete_device_group: 'Eliminar grupo de dispositivos',

  device_grous_add_sensor_device: 'Agregar sensor y dispositivo al grupo',

  device_groups_table_sensor_group_name_header: 'Nombre del grupo de sensores',
  device_groups_table_parent_group_name_header: 'Nombre del grupo principal',
  device_groups_table_device_count_header: 'Recuento de dispositivos',

  device_groups_sensor_table_device_number_header: 'Serie de dispositivo',
  device_groups_sensor_table_sensor_name_header: 'Nombre del sensor',
  device_groups_sensor_table_sensor_index_header: 'Lectura del sensor',
  device_groups_sensor_table_select_message: 'Seleccionar un grupo de sensores',

  device_groups_sensors_add_modal_device_placeholder: 'serie del dispositivo',
  device_groups_sensors_add_modal_sensor_placeholder: 'Sensor',
  device_groups_sensors_add_modal_device_group_name_required:
    'El nombre del grupo de dispositivos es obligatorio',

  device_groups_sensors_delete_description:
    'La eliminación de esta configuración la eliminará permanentemente del servidor.',
  device_groups_device_group_delete_description:
    'La eliminación del grupo de dispositivos lo eliminará permanentemente del servidor.',

  device_groups_select_company_header: 'Seleccione una empresa para continuar',
  device_groups_select_company_from: 'Desde aquí: ',
  device_groups_select_company_accounts_page_link: 'Página de Cuentas',

  device_groups_create_device_group_error:
    'Hubo un problema al crear un nuevo grupo de dispositivos',
  device_groups_update_device_group_error:
    'Hubo un problema al actualizar el grupo de dispositivos',
  device_groups_delete_device_group_error: 'Hubo un problema al eliminar el grupo de dispositivos',
  device_groups_add_device_sensor_device_group_error: 'Hubo un problema al agregar el sensor',
  device_groups_remove_device_sensor_device_group_error: 'Hubo un problema al eliminar el sensor',

  /*
   * |==============|
   * | Users Config |
   * |==============|
   */

  //Page
  users_header: 'Usuarios',
  users_table_username: 'Nombre de usuario',
  users_table_first_name: 'Nombre',
  users_table_last_name: 'Apellido',
  users_table_company_name: 'Cuenta',
  users_table_email: 'Correo electrónico',

  //Filters
  users_filter_username: 'Filtrar nombre de usuario',
  users_filter_first_name: 'Filtrar nombre',
  users_filter_last_name: 'Filtrar apellido',
  users_filter_email: 'Filtrar correo electrónico',
  users_filter_company_name: 'Filtrar cuenta',
  users_reset_filter_icon: 'Restablecer filtros',
  users_table_is_contact_person: 'Persona de contacto',

  //Modals
  users_add_modal_header: 'Agregar usuario',
  users_update_modal_header: 'Actualizar usuario',
  user_remove_modal_icon: 'Eliminar usuario',
  users_remove_modal_header: '¿Está seguro de que desea eliminar el usuario {{username}}?',
  users_remove_modal_body:
    'Al hacer clic en eliminar, se eliminará permanentemente del servidor al usuario elegido.',
  users_is_dashboard_enabled: 'Tablero Habilitado',
  users_is_contact_person: 'Persona de Contacto',

  //Errors
  users_password_match: 'Las contraseñas no coinciden',
  users_email_blank: 'El correo electrónico no puede estar en blanco',
  users_load_error: 'No se pudieron cargar los usuarios',
  users_update_error: 'No se pudo actualizar el usuario',
  users_delete_error: 'No se pudo eliminar el usuario',
  users_create_error: 'No se pudo crear el usuario',

  // Success
  users_update_success: 'Usuario actualizado con éxito',
  users_delete_success: 'Usuario eliminado con éxito',
  users_create_success: 'Usuario creado con éxito',
  /*
   * |=====================|
   * | Value Ranges Config |
   * |=====================|
   */

  // Buttons & tooltips
  value_ranges_btn_add: 'Agregar',
  value_ranges_btn_defaults: 'Valores predeterminados',
  value_ranges_line_color: 'Color de linea',
  value_ranges_node_color: 'Color de nodo',
  value_ranges_foreground_color: 'Color de primer plano',
  value_ranges_background_color: 'Color de fondo',
  value_ranges_loading: 'Cargando...',
  value_ranges_nothing_to_show: 'Nada que mostrar',

  //Page
  value_ranges_header: 'Eventos de rango de valor',
  value_ranges_trigger: 'activar',
  value_ranges_notifications: 'notificaciones',
  value_ranges_before: 'Antes',
  value_ranges_after: 'Después',
  value_ranges_disabled: 'Desactivado',
  value_ranges_date_range: 'Rango de fechas',
  value_ranges_daily_time_slot: 'Franja horaria diaria',
  value_ranges_timezone: 'Zona horaria',

  //Modals
  value_ranges_add_event: 'Añadir evento',
  value_ranges_edit_event: 'Editar evento',
  value_ranges_add_default_events: 'Agregar eventos predeterminados',
  value_ranges_remove_modal_header: '¿Está seguro de que desea eliminar el rango de valores',
  value_ranges_remove_modal_body:
    'Al hacer clic en eliminar, se eliminará permanentemente el rango de valores elegido',

  //Form
  value_range_form_name: 'Nombre del evento',
  value_range_form_function: 'Función',
  value_range_form_sensor: 'Sensor',
  value_range_form_description: 'Descripción',
  value_range_form_min: 'Min',
  value_range_form_max: 'Max',
  value_range_form_severity: 'Severidad',
  value_range_form_start_day: 'Día de inicio',
  value_range_form_start_month: 'Mes de inicio',
  value_range_form_start_time: 'Hora de inicio',
  value_range_form_end_day: 'Día final',
  value_range_form_end_month: 'Mes final',
  value_range_form_end_time: 'Hora de finalización',
  value_range_form_enable_events: '¿Habilitar eventos?',
  value_range_form_select_color: 'Color seleccionado',
  value_range_form_notification_email: 'Correo electrónico',
  value_range_form_notification_push_notification: 'Notificación de inserción',
  value_range_form_notification_sms: 'SMS',
  value_range_form_notification_push: 'Notificación de inserción',
  value_range_form_notification_call: 'Llamar',
  value_range_form_line_custom_color: 'Color de línea personalizado',
  value_range_form_marker_custom_color: 'Color de marcador personalizado',
  value_range_form_no_notifications: 'No Notificaciones',

  //Errors

  value_range_tooltip_severity:
    'Las severidades mayores a 7 se consideran severas. Los eventos severos se indican con un signo de exclamación.',
  value_range_error_add: 'No se pudo agregar el evento',
  value_range_error_edit: 'No se pudo editar el evento',
  value_range_name_is_required: 'Se requiere el nombre',
  value_range_name_already_exists: 'El nombre del evento ya existe para este dispositivo y sensor',
  value_range_min_greater_than_max: 'Min no puede ser mayor que max',
  value_range_min_equal_to_max: 'Min no puede ser igual a max',
  value_range_name_incorect_time: 'Formato de hora incorrecto',
  value_range_min_max_is_required: 'Mínima o máxima es requerida',
  value_range_error_occured: 'An error occureOcurrió un errord',
  value_range_start_incorrect_time:
    'La hora de inicio tiene un formato incorrecto. Debería ser HH: mm. Correcto: 01:00. Incorrecto: 1:00.',
  value_range_end_incorrect_time:
    'La hora de finalización tiene un formato incorrecto. Debería ser HH: mm. Correcto: 01:00. Incorrecto: 1:00.',
  value_range_start_day_missing_start_month: 'El día de inicio debe tener el mes de inicio',
  value_range_end_day_missing_end_month: 'El día de finalización debe tener el mes de finalización',
  value_range_start_month_missing_start_day: 'El mes de inicio debe tener el día de inicio',
  value_range_end_month_missing_end_day: 'El mes final debe tener el día final',
  value_range_mixed_alphabets_error: 'Se detectó el uso de alfabetos mixtos, use solo un idioma',

  /*
   * |==========|
   * | Commands |
   * |==========|
   */

  // Command State
  commands_command_state_pending: 'Pendiente',
  commands_command_state_sent: 'Enviado',
  commands_command_state_cancelled: 'Cancelado',
  commands_command_state_status_ok: 'OK',
  commands_command_state_status_nok: 'No OK',
  commands_command_state_unknown: 'Desconocido',
  commands_command_state_timeout: 'Tiempo de espera agotado',

  // Command Type
  commands_command_type_relay_switch: 'Interruptor de relé',
  commands_command_type_single_relay_switch: 'Interruptor de relé único',
  commands_command_type_relay_switch_with_delay: 'Swith del Relay con Delay',
  commands_command_type_relay_statuses: 'Estados de los relés',
  commands_command_type_send_config_over_mqtt: 'Enviar configuración a través de MQTT',
  commands_command_type_send_date_over_mqtt: 'Enviar fecha a través de MQTT',
  commands_command_type_modbus: 'Modbus',
  commands_command_type_tasmota_relay_switch: 'Comando de relé Tasmota',

  // Errors
  commands_error_missing_device_number: 'Serie Dispositivo faltante o errónea.',

  // Buttons
  commands_btn_send_command: 'Enviar Comando',
  commands_btn_refresh: 'Actualizar',
  commands_btn_submit: 'Enviar',

  // Tooltips
  commands_tooltip_edit: 'Editar',

  // Popups
  commands_send_command: 'Enviar Comando',
  commands_edit_command: 'Editar Comando',
  commands_command_type: 'Tipo de Comando',
  commands_parameters: 'Parámetros',
  commands_parameter_name: 'Nombre Parámetro',
  commands_parameter_value: 'Valor Parámetro',

  // Page
  commands_header: 'Comandos',
  commands_type: 'Tipo',
  commands_status: 'Estado',
  commands_relay_1_status: 'Estado del Relay 1',
  commands_relay_2_status: 'Estado del Relay 2',
  commands_created: 'Creado',
  commands_sent: 'Enviado',
  commands_executed: 'Ejecutado',
  commands_relay_status: 'Estado del relé',
  commands_relay_index: 'Índice del relé',
  commands_device_number_slave: 'Número del dispositivo subordinado',
  commands_sensor_index: 'Índice del sensor',

  /*
   * |=============|
   * | Copy Config |
   * |=============|
   */

  // Page
  copy_config_copy_config: 'Copiar Configuración',
  copy_config_to_device_number_with_name_absent: 'Al dispositivo: {{device_number}}',
  copy_config_to_device_number_with_name_present:
    'Al dispositivo: {{device_name}} ({{device_number}})',
  copy_config_from_device_number: 'Del serie del dispositivo',
  copy_config_copy_config_type_label: 'Tipo de copia de configuración',
  copy_config_existing_device_option: 'Dispositivo existente',
  copy_config_new_device_option: 'Nuevo dispositivo',
  copy_config_should_copy_label: 'Debe copiarse:',
  copy_config_should_copy_config_option: 'Configuración',
  copy_config_should_copy_value_ranges_option: 'Rangos de valores',

  // Buttons
  copy_config_btn_copy: 'Dupdo',

  //Toast
  copy_config_toast_copied_success: 'La configuración del dispositivo se copió correctamente.',
  copy_config_toast_copied_error:
    'Hubo un problema al intentar copiar la configuración del dispositivo.',
  copy_config_value_ranges_toast_copied_success: 'Los rangos de valores se copiaron con éxito.',
  copy_config_value_ranges_toast_copied_error:
    'Hubo un problema al intentar copiar los rangos de valores.',

  /*
   * |============|
   * | Statistics |
   * |============|
   */
  statistics_header: 'Estadísticas',
  statistics_header_hours: 'Horas',
  statistics_header_quantity: 'Cantidad',
  statistics_header_value: 'Valor',
  statistics_quantity_minimum: 'Mínimo',
  statistics_quantity_maximum: 'Máximo',
  statistics_quantity_average: 'Promedio',
  statistics_quantity_total: 'Total',
  statistics_quantity_hours: 'Horas',
  statistics_quantity_counters: 'Contadores',

  // Error
  statistics_error_definition_for_q3:
    'El sensor {{name}} contiene una definición para q3. Consulte la página de configuración del sensor.',
  statistics_eror_definition_for_diameter:
    'El sensor {{name}} contiene una definición de diámetro. Consulte la página de configuración del sensor.',
  statistics_error_definition_for_r:
    'El sensor {{name}} contiene una definición para r. Consulte la página de configuración del sensor.',
  statistics_error_no_water_meters_found:
    'No se encontraron medidores de agua. Visite la página de configuración del dispositivo.',

  /*
   * |=====|
   * | Map |
   * |=====|
   */
  map_add_device: 'Añadir {{device}}',
  map_edit_device: 'Editar {{device}}',
  map_lat: 'Latitud',
  map_long: 'Longitud',
  map_coordinates: 'Coordenadas',
  map_polygon: 'Polígono',
  map_linked_in_polygons: 'Contenido en polígonos:',
  map_loading: 'Cargando...',

  // Button
  map_btn_link_device: 'Dispositivo de enlace',
  map_btn_submit: 'Enviar',

  // Error
  map_err_add_device_to_map: 'No se pudo agregar el dispositivo al mapa.',
  map_err_change_location: 'No se pudo cambiar la ubicación.',
  map_err_link_polygon: 'No se pudo vincular al polígono.',

  // Device Modal
  map_btn_get_curr_location: 'Obtener ubicación actual',
  map_geolocation_not_supported: 'La geolocalización no es compatible por este navegador.',
  map_lat_validation_message: 'Latitud no válida',
  map_long_validation_message: 'Longitud no válida',
  map_invalid_location: 'Locación inválida',
  map_invalid_coordinate_input:
    'Entrada de coordenadas no válida. Debería ser "lat, long", p.e. 42.123, 23.123',

  // Set Coordinates Modal
  map_coordinates_modal_btn_label: 'Establecer Coordenadas',
  map_coordinates_modal_title: 'Establecer Coordenadas',
  map_coordinates_modal_target_option_self: 'Para uno mismo',
  map_coordinates_modal_target_option_company: 'Para la Empresa',
  map_coordinates_modal_target_option_user: 'Para el Usuario',
  map_coordinates_modal_select_user_autocomplete_label: 'Nombre de Usuario',
  map_coordinates_modal_save_for_self_warning_message:
    'Guardar establecerá las coordenadas actuales como predeterminadas para su usuario actual. ¿Está seguro de que desea continuar?',
  map_coordinates_modal_save_for_company_warning_message:
    'Guardar establecerá las coordenadas actuales como predeterminadas para cada usuario en su empresa. ¿Está seguro de que desea continuar?',
  map_coordinates_modal_save_for_user_warning_message:
    'Guardar establecerá las coordenadas actuales como predeterminadas para el usuario seleccionado. ¿Está seguro de que desea continuar?',
  map_coordinates_modal_confirm_btn_label: 'Confirmar',
  map_coordinates_modal_cancel_btn_label: 'Cancelar',

  /*
   * |===============|
   * | Sensor Config |
   * |===============|
   */

  flow_time_units: 'Unidad de tiempo',
  flow_measurement_units: 'Unidad de medida',
  flow_configuration_label: 'Configuración de flujo',
  flow_coefficient: 'Coeficiente',
  sensor_config_sensor_group_name: 'Nombre del grupo de sensores',
  flow_configuration_alert_message:
    'Compruebe las siguientes configuraciones de flujo:\n{{message}}',
  flow_configuration_invalid_sensor_row_message:
    'Índice: {{sensor_index}} con nombre: {{sensor_name}}\n',
  flow_configuration_sensor_name_not_set_label: 'No establecido',

  /*
   * |===================|
   * | Device Prediction |
   * |===================|
   */
  device_prediction: 'Predicción de dispositivos',
  device_prediction_header: 'Análisis de series temporales',
  device_prediction_sensor_name: 'Nombre del sensor',
  device_prediction_forward_days: 'Días futuros',
  device_prediction_backward_days: 'Días hacia atrás',
  device_prediction_generate_btn: 'Generar',
  device_prediction_error: 'Error',
  device_prediction_error_msg_json: 'Hubo un problema al generar datos de predicción.',
  device_prediction_error_msg_image: 'Hubo un problema al generar la imagen de predicción.',
  device_prediction_date: 'Fecha',
  device_prediction_trend: 'Tendencia',
  device_prediction_time: 'Hora',
  device_prediction_legend_prediction: 'Predicción',
  device_prediction_legend_range: 'Rango de predicción',
  device_prediction_legend_consumption: 'Consumo actual',
  device_prediction_help_title: 'Ayuda',
  device_prediction_help_title_input_guide: 'Guía de Entrada',
  device_prediction_help_input_guide_text:
    '<strong> 1. Período </strong> - Los usuarios pueden seleccionar un rango de fechas entre opciones predefinidas o establecer un rango personalizado. El sistema analiza los datos dentro de este período elegido para calcular predicciones futuras, ofreciendo información valiosa. <br> <strong> 2. Nombre del Sensor </strong> – selecciona el sensor del cual deseas ver sus predicciones futuras. <br> <strong> 3. Cada </strong> - se utiliza para predicciones más detalladas en términos de la granularidad del tiempo. <br> <strong> 4. Días hacia adelante </strong> - número de días en los que el usuario desea predecir en el futuro. Estas predicciones se extienden más allá del rango de fechas seleccionado, dando ideas sobre el comportamiento esperado de consumo. <br> <strong> 5. Días hacia atrás </strong> - representa el número de días a mostrar en el gráfico y la tabla, comenzando desde el último día del período seleccionado. Esto muestra los datos reales históricos recibidos del dispositivo durante este período especificado. <br> <strong> 6. Botón Generar </strong> – al hacer clic en "Generar", el sistema calcula el consumo en función de la configuración seleccionada y genera predicciones. Los resultados se muestran tanto en la Vista de Gráficos como en la Vista de Tabla. <br> <strong> 7. Gráfico </strong> – El gráfico muestra tanto los días hacia adelante como los días hacia atrás, presentando los datos reales recibidos y mostrando los datos predichos. <br> <strong> 8. Tabla </strong> - La tabla muestra todos los datos desde "desde el período" hasta el final de los "días hacia adelante". Cada fila incluye una fecha específica, hora y valor de tendencia correspondiente, indicando la dirección o patrón general de los datos a lo largo del tiempo. Ayuda a comprender si los valores están aumentando, disminuyendo o siendo estables durante el período predicho.',
  device_prediction_help_title_legend: 'Leyenda',
  device_prediction_help_black:
    '<strong> Color Negro </strong> - representa los datos reales de consumo durante el período seleccionado (ejemplo: desde el 01 de julio hasta el 31 de julio).',
  device_prediction_help_red:
    '<strong> Color Rojo </strong> - Representa el consumo real si hay datos fuera del período seleccionado. En la imagen de arriba, hay datos reales recibidos del dispositivo en los días en que el modelo está configurado para predecir. Así, el usuario puede comparar los valores predichos (azul oscuro) con los valores en rojo (datos reales).',
  device_prediction_help_dark_blue:
    ' <strong> Color Azul Oscuro </strong> - Representa la predicción para el período seleccionado, que incluye datos antiguos y se extiende hacia el futuro con los valores predichos de los días hacia adelante. Estos valores predichos ofrecen información sobre la tendencia esperada de consumo.',
  device_prediction_help_light_blue:
    '<strong> Color Azul Claro </strong> - Representa el rango de los valores predichos. Indica que los datos de consumo reales podrían encontrarse dentro de este rango, lo que señala la incertidumbre asociada con las predicciones.',
  device_prediction_help_example:
    "Si la fecha 'DESDE' se establece en el 1 de julio y la fecha 'HASTA' en el 31 de julio, con 'Días hacia adelante' de 30, 'Días hacia atrás' de 30 y 'CADA' 10 minutos, el gráfico mostrará el consumo real de los 30 días disponibles (del 1 de julio al 1 de agosto) y los valores predichos para 30 días hacia adelante (del 1 de agosto al 1 de septiembre). La tabla mostrará el consumo real y predicho para cada intervalo de 10 minutos desde el 1 de julio hasta el 1 de septiembre de 2023.",

  /*
   * |=================|
   * | Counters Export |
   * |=================|
   */

  // Buttons and dropdowns
  counters_export_header: 'Exportar datos',
  counters_readings_header: 'Informes',
  counters_export_search: 'Buscar',
  counters_export_download: 'Descargar',
  counters_export_format: 'Formato',

  // Table columns
  counters_export_table_device_name: 'Nombre del dispositivo',
  counters_export_table_device_number: 'Número del dispositivo',
  counters_export_table_sensor_index: 'Índice de sensores',
  counters_export_table_sensor_name: 'Nombre del sensor',
  counters_export_table_date: 'Fecha',
  counters_export_table_counters: 'Informes',
  counters_export_table_prev_counters: 'Anterior Informes',
  counters_export_table_dt: 'dt',
  counters_export_table_delta: 'Delta',
  counters_export_error_alert_message: 'Ocurrió un error al generar los datos.',
  counter_export_select_devices_label: 'Seleccionar dispositivos',
  counter_export_select_sensors_label: 'Seleccionar sensores',
  counter_export_sensor_index: 'Índice de sensores: {{sensor_index}}',

  counters_export_maximum_capacity_error_message: '¡Capacidad máxima de exportación alcanzada!',
  counters_export_maximum_capacity_suggestion_message:
    'Disminuya la cantidad de dispositivos seleccionados o el período de fecha.',
  /*
   * |====================|
   * | Consumption Export |
   * |====================|
   */

  // Buttons and dropdowns
  consumption_export_header: 'Reporte',
  consumption_export_search: 'Buscar',
  consumption_export_download: 'Descargar',
  consumption_export_format: 'Formato',
  consumption_export_aggregation_period: 'Período de Agregación',
  consumption_export_aggregation_options_none: 'Ninguno',
  consumption_export_aggregation_options_daily: 'Diario',
  consumption_export_aggregation_options_weekly: 'Semanal',
  consumption_export_aggregation_options_monthly: 'Mensual',
  consumption_export_aggregation_options_yearly: 'Anual',

  // Table columns
  consumption_export_table_device_number: 'Número del dispositivo',
  consumption_export_table_sensor_index: 'Índice de sensores',
  consumption_export_table_address: 'Dirección',
  consumption_export_table_sensor_name: 'Nombre del sensor',
  consumption_export_table_first_reading_date: 'Fecha de la primera lectura',
  consumption_export_table_first_reading_value: 'Valor de primera lectura',
  consumption_export_table_last_reading_date: 'Fecha de última lectura',
  consumption_export_table_last_reading_value: 'Valor de la última lectura',
  consumption_export_table_delta: 'Delta',
  consumption_export_table_average: 'Promedio',
  consumption_export_table_consumption_for_period: 'Consumo para el período',

  /*
   * |=================|
   * | Alarms Export |
   * |=================|
   */

  // Buttons and dropdowns
  alarms_export_header: 'Alarmas',
  alarms_export_search: 'Buscar',
  alarms_export_refresh: 'Actualizar',
  alarms_export_download: 'Descargar',
  alarms_export_format: 'Formato',

  alarms_return_to_alarms: 'Volver a Alarmas',
  alarms_redirect_to_graph: 'Redirigir al gráfico',

  // Table columns
  alarms_export_table_date: 'Fecha',
  alarms_export_table_device_number: 'Número del dispositivo',
  alarms_export_table_device_name: 'Nombre del dispositivo',
  alarms_export_table_severity: 'Gravedad',
  alarms_export_table_sensor_index: 'Índice de sensores',
  alarms_export_table_sensor_name: 'Nombre del sensor',
  alarms_export_table_alarm_type: 'Tipo',
  alarms_export_table_alarm_status: 'Estado',
  alarms_export_table_description: 'Descripción',
  alarms_export_table_link_to_graph: 'Enlace al gráfico',
  alarms_export_chip_status_active: 'Activo',
  alarms_export_chip_status_inactive: 'Inactivo',
  alarms_export_switch_title_label: 'Autorefrescar',
  alarms_export_error_alert_message: 'Ocurrió un error al generar los datos.',
  alarms_export_select_company_alert_message: 'Seleccione una empresa antes de buscar alarmas',
  alarms_export_select_device_or_company_alert_message:
    'Seleccione un dispositivo o una empresa antes de buscar alarmas',
  alarms_export_error_too_big_query:
    'Recuperar el historial de Super-Administrador para todos los dispositivos es una solicitud demasiado grande. Seleccione una empresa.',

  /*
   * |================|
   * | Inventory page |
   * |================|
   */

  //table
  device_inventory_page_header: 'Inventario',
  device_inventory_column_header_search: 'Buscar…',
  device_inventory_description: 'Descripción',
  device_inventory_device_name: 'Nombre del dispositivo',
  device_inventory_device_number: 'Número del dispositivo',
  device_inventory_hardware_version: 'Versión del hardware',
  device_inventory_model: 'Modelo',
  device_inventory_software_version: 'Versión del software',
  device_inventory_actions: 'Acciones',
  nomenclature: 'Nomenclatura',

  //modal
  device_inventory_add_device: 'Agregar dispositivo',
  device_inventory_create_device_button: 'Crear dispositivo',
  device_inventory_delete_unlink_modal_body:
    'Esto eliminará permanentemente el dispositivo elegido o puede desvincularlo de la empresa seleccionada.',
  device_inventory_delete_modal_body: 'Esto eliminará permanentemente el dispositivo elegido.',
  device_inventory_hardware_version_helper: 'El formato requerido es X.X.X donde X es un número',
  device_inventory_update_device_button: 'Actualizar dispositivo',
  device_inventory_update_device_title: 'Actualizando {{number}}',
  device_inventory_modal_delete_device_title: 'Eliminar dispositivo {{number}}',
  device_inventory_software_version_helper:
    'El formato requerido es X.X.X o X.X.X- y X.X.X_ seguido de caracteres, donde X es un número',
  device_inventory_description_helper: 'Límite de caracteres de la descripción alcanzado',
  device_inventory_device_number_helper:
    'Por favor, ingrese 8 caracteres que contengan solo letras (A-Z) y números (0-9).',
  device_inventory_modal_select_action: 'Seleccionar acción para el dispositivo {{deviceNumber}}',

  //success
  device_inventory_create_device_success: 'Dispositivo creado con éxito',
  device_inventory_update_device_success: 'Dispositivo actualizado con éxito',
  device_inventory_delete_device_success: 'Dispositivo eliminado con éxito',
  device_inventory_unlink_device_success: 'Dispositivo desvinculado con éxito',

  //error
  device_inventory_create_device_error: 'Se produjo un error al crear el dispositivo',
  device_inventory_delete_device_error: 'Se produjo un error al eliminar el dispositivo',
  device_inventory_device_exists: 'El dispositivo ya existe',
  device_inventory_empty_device_error: 'El número del dispositivo no puede estar vacío',
  device_inventory_empty_hardware_error: 'La versión del hardware no puede estar vacía',
  device_inventory_empty_software_error: 'La versión del software no puede estar vacía',
  device_inventory_error: 'No se pudieron cargar los dispositivos',
  device_inventory_update_device_error: 'Se produjo un error al actualizar el dispositivo',
  device_inventory_already_exists_error: 'El dispositivo con este número ya existe',
  device_inventory_empty_nomenclature_error: 'La nomenclatura no puede estar vacía',

  /*
   * |====================|
   * | Transmissions page |
   * |====================|
   */

  //page
  transmissions_page_title: 'Transmisiones',
  transmissions_page_refresh: 'Actualizar',
  transmissions_page_export_csv: 'Exportar a CSV',
  transmissions_page_error_message: 'No se pudieron cargar las transmisiones',

  //table
  transmissions_table_device_number: 'Número de dispositivo',
  transmissions_table_device_name: 'Nombre del dispositivo',
  transmissions_table_last_transmission: 'Última transmisión',
  transmissions_table_next_transmission: 'Próxima transmisión',
  transmissions_table_days: 'Días',
  transmissions_table_transmission: 'Transmisión',
  transmissions_table_count: 'Cantidad',
  transmissions_table_customer: 'Cliente',
  transmissions_table_address: 'Dirección',
  transmissions_table_ok: 'OK',
  transmissions_table_devEUI: 'devEUI',
  transmissions_table_simcard_number: 'Número de Tarjeta SIM',
  transmissions_table_simcard_provider: 'Proveedor de Tarjeta SIM',
  transmissions_table_apn: 'APN',

  /*
   * |===============|
   * | Device Config |
   * |===============|
   */

  // page
  device_config_period: 'Período',
  device_config_every: 'Cada',

  // error
  device_config_error_record_period_postiive: 'El período de registro debe ser positivo.',
  device_config_error_transmission_period_positive: 'El período de transmisión debe ser positivo.',
  device_config_error_record_must_be_greater_than_transmission:
    'El período de registro no puede ser mayor que el período de transmisión.',
  device_config_error_not_divisible:
    'El período de registro no es divisible al período de transmisión.',
  device_config_error_max_threshold_reached:
    '{{curr}} registros por transmisión es más que el máximo {{max}}.',

  /*
   * |=======|
   * | Graph |
   * |=======|
   */
  graph_tabs_readings: 'Lecturas',
  graph_tabs_prediction: 'Predicción',
  graph_tabs_average: 'Promedio',
  graph_tabs_previous: 'Anterior',
  graph_column_sensor: 'Sensor',
  graph_column_index: 'Índice',
  graph_column_device: 'Dispositivo',
  graph_column_device_name: 'Nombre del dispositivo',
  graph_column_date: 'Fecha',
  graph_column_readings: 'Lecturas',
  graph_column_previous_readings: 'Lecturas anteriores',
  graph_column_delta: 'Delta',
  /*
   * |===============|
   * | Settings page |
   * |===============|
   */
  settings_page_header: 'Configuraciones',
  settings_submit_success_msg: 'Configuraciones guardadas',
  settings_submit_error_msg: 'Las configuraciones no pudieron ser guardadas',
  settings_input_invalid_format_error_msg: 'Formato de datos inválido',
  settings_certificate_not_found_error: 'No se encontró un certificado en esta ruta de archivo',
  settings_add_button: 'Agregar',
  settings_submit_button: 'Enviar',
  settings_input_key_label: 'Clave',
  settings_input_type_label: 'Tipo',
  settings_input_value_label: 'Valor',
  settings_select_type_string: 'Cadena',
  settings_select_type_number: 'Número',
  settings_select_type_boolean: 'Booleano',
  settings_select_type_object: 'Objeto',
  settings_logo_label: 'Logo',
  settings_dashboard_layout_label: 'Diseño del tablero',
  settings_certificate_label: 'Ruta del certificado',
  settings_device_panel_title: 'Título del panel de dispositivo',
  settings_sensor_panel_title: 'Título del panel de sensor',
  settings_header_home_label: 'Etiqueta de inicio (Portal)',
  settings_header_dashboard_label: 'Etiqueta del tablero (Portal)',
  settings_footer_main_label: 'Etiqueta principal del pie de página (Portal)',
  settings_footer_link_label: 'Etiqueta de enlace del pie de página (Portal)',
  settings_footer_link_url: 'URL de enlace del pie de página (Portal)',
  settings_alarm_notification_display_period:
    'Período de visualización de notificaciones de alarma (Portal)',
  settings_alarm_notification_display_period:
    'Período de visualización de notificaciones de alarma (horas) (Portal)',

  /* |===========|
   * | New Graph |
   * |===========|
   */

  //page
  new_graph_add_device_group_modal_title:
    'Configuración de sensores del grupo de dispositivos "Cada"',
  new_graph_add_device_group_modal_device_group: 'Grupo de dispositivos: ',
  new_graph_add_device_group_modal_sensors_to_add: 'Sensores para añadir: ',
  new_graph_add_device_group_modal_every_selector_mode_per_device: 'Por dispositivo',
  new_graph_add_device_group_modal_every_selector_mode_per_sensor: 'Por sensor',
  new_graph_add_device_group_modal_sensor_limit_message:
    '¡No puedes exceder el límite de gráficos de {{graphSensorsLimit}} sensores!',
  new_graph_add_device_group_modal_confirm_button: 'Confirmar',
  new_graph_add_device_group_modal_cancel_button: 'Cancelar',
  new_graph_select_sensor_heading: 'Seleccionar sensor',
  new_graph_date_range_selection: 'Selección de intervalo de fechas',
  new_graph_selected_sensors_heading: 'Sensores seleccionados',
  new_graph_selected_sensors_remove_all_tooltip: 'Borrar todo',
  new_graph_select_company_message: 'Por favor seleccione una empresa',
  new_graph_show_sensors_statistics_label: 'Mostrar estadísticas',

  //sensor selection
  new_graph_device_group_label: 'Grupo de dispositivos',
  new_graph_device_label: 'Dispositivo',
  new_graph_sensor_label: 'Sensor',

  //date range selection
  new_graph_average_days_label: 'Días promedio',
  new_graph_time_series_prediction_period_label: 'Período',
  new_graph_auto_refresh_label: 'Actualizar',
  new_graph_period_picker_heading: 'Lecturas',
  new_graph_average_days_heading: 'Promedio',
  new_graph_time_series_prediction_period_heading: 'Predicción de series temporales',
  new_graph_none_option: 'Ninguno',
  new_graph_day_option_one: '{{count}} día',
  new_graph_day_option_other: '{{count}} días',
  new_graph_week_option_one: '{{count}} semana',
  new_graph_week_option_other: '{{count}} semanas',
  new_graph_month_option_one: '{{count}} mes',
  new_graph_month_option_other: '{{count}} meses',
  new_graph_year_option_one: '{{count}} año',
  new_graph_year_option_other: '{{count}} años',

  //tooltip
  new_graph_current_value_name: 'Actual',
  new_graph_average_value_name: 'Promedio',
  new_graph_prediction_value_name: 'Predicción',

  //error
  new_graph_fetch_device_groups_error:
    'No se pudieron cargar los grupos de dispositivos para la empresa.',
  new_graph_fetch_devices_error: 'No se pudieron cargar los dispositivos para la empresa.',
  new_graph_fetch_device_config_error:
    'No se pudo cargar la configuración del dispositivo seleccionado.',
  new_graph_fetch_device_ports_config_error:
    'No se pudieron cargar los puertos para el dispositivo seleccionado.',
  new_graph_fetch_device_flow_error:
    'No se pudo cargar el flujo para el dispositivo {{deviceName}}.',
  new_graph_fetch_device_flow_avg_error:
    'No se pudo cargar el flujo promedio para el dispositivo {{deviceName}}.',
  new_graph_fetch_device_readings_error: 'No se pudieron cargar las lecturas para {{deviceName}}.',
  new_graph_fetch_device_readings_avg_error:
    'No se pudieron cargar las lecturas promedio para {{deviceName}}.',
  new_graph_fetch_device_on_off_alarms_error:
    'No se pudieron cargar las alarmas de encendido/apagado para {{deviceName}}.',
  new_graph_fetch_device_prediction_error: 'No se pudo cargar la predicción para {{deviceName}}.',
  new_graph_sensor_already_in_state_error: 'Este sensor específico ya se ha agregado a la lista.',

  /* |================|
   * | Billing Report |
   * |================|
   */

  billing_report_header: 'Informe de facturación',
  billing_report_btn_generate: 'Generar',
  billing_report_device_number: 'Número de dispositivo',
  billing_report_first_reading_date: 'Fecha de primera lectura',
  billing_report_last_reading_date: 'Fecha de última lectura',
  billing_report_readings: 'Lecturas',
  billing_report_battery: 'Batería',
  billing_report_signal: 'Señal',
  billing_report_total: 'Total',

  /* |================|
   * | Profile Page   |
   * |================|
   */
  profile_page_change_logo: 'Cambiar logo',
  profile_page_logo_formats_message: 'Formatos aceptables: PNG, JPEG',
  profile_page_name: 'Nombre',
  profile_page_email: 'Email',
  profile_page_language: 'Idioma',
  profile_page_password: 'Contraseña',
  profile_page_change_language: 'Cambiar idioma',
  profile_page_update_license: 'Actualizar licencia',
  profile_page_change_password: 'Cambiar contraseña',
  profile_page_generate: 'Generar',
  profile_page_header: 'Perfil',
  profile_page_user_info_label: 'Información del usuario',
  profile_page_license_label: 'Licencia ',
  profile_page_license_type: 'Tipo de licencia',
  profile_page_valid_from: 'Válido desde',
  profile_page_valid_to: 'Válido hasta',
  profile_page_max_readings: 'Lecturas máximas',
  profile_page_current_count: 'Cuenta actual',
  profile_page_first_reading: 'Primera lectura',
  profile_page_last_reading: 'Última lectura',
  profile_page_license_status: 'Estado',
  profile_page_renew_license_button: 'Renovar licencia',
  profile_page_deactivate_license_button: 'Desactivar licencia',
  profile_page_valid_license: 'Licencia válida',
  profile_page_invalid_license: 'Licencia no válida',
  profile_page_deactivate_license_success_message: 'Licencia desactivada.',
  profile_page_deactivate_license_error_message: 'No se pudo desactivar la licencia.',
  profile_page_deactivate_modal_title: 'Desactivación de licencia',
  profile_page_confirm_button: 'Confirmar',
  profile_page_cancel_button: 'Cancelar',
  profile_page_deactivate_license_modal_description:
    '¿Está seguro de que desea desactivar esta licencia?',
  profile_page_activate_license_modal_description:
    '¿Está seguro de que quiere activar esta licencia?',
  profile_page_activate_modal_title: 'Activación de Licencia',
  profile_page_license_per_device: 'por dispositivo',
  profile_page_license_per_reading: 'por lectura',
  profile_page_license_per_sensor: 'por sensor',
  profile_page_license_per_poc: 'por prueba de concepto (POC)',
  profile_page_license_per_remote_node: 'por nodo remoto',
  profile_page_license_partial: 'parcial',
  profile_page_logo_file_format_error: 'Tipo de archivo inválido. Por favor, suba una imagen.',
  profile_page_logo_size_error:
    'El tamaño del archivo supera 1MB. Por favor, suba una imagen más pequeña.',
  profile_page_version_info: 'Versiones actuales:',

  logo_error_message: 'No se pudo cargar el logotipo de la empresa',

  profile_page_country: 'País',
  profile_page_timezone: 'Zona horaria',
  profile_page_address: 'Dirección',
  profile_page_city: 'Ciudad',
  profile_page_municipality: 'Municipio',
  profile_page_area: 'Área',
  profile_page_zip_code: 'Código postal',
  profile_page_vat_number: 'Número de IVA',
  profile_page_edit: 'Editar cuenta',
  profile_page_update_account_success: 'Cuenta actualizada exitosamente',
  profile_page_license_info: 'Información de la licencia',

  /* |===============|
   * | Utility Rates |
   * |===============|
   */

  util_rates: 'Tarifas de servicios públicos',

  // table
  util_rates_name: 'Nombre',
  util_rates_price: 'Precio',
  util_rates_currency: 'Divisa',
  util_rates_unit: 'Unidad',
  util_rates_private: 'Privado',
  util_rate_company: 'Compañía',
  util_rate_created: 'Creado',
  util_rates_updated: 'Actualizado',
  util_rates_actions: 'Comportamiento',
  util_rates_tooltip_inspect: 'Inspeccionar',

  // modals
  util_rates_add_modal_header: 'Agregar tarifa de servicios públicos',
  util_rates_edit_modal_header: 'Editar {{name}}',
  util_rates_delete_modal_header: 'Borrar {{name}}',
  util_rates_delete_modal_body:
    'Esto eliminará permanentemente la tarifa de servicios públicos elegida.',
  util_rates_confirm: 'Confirmar',
  util_rates_cancel: 'Cancelar',
  util_rates_loading: 'Cargando...',
  util_rates_error: 'Error...',
  util_rates_error_name_exists: 'El nombre ya existe',

  // errors
  util_rate_fetch_company_error: 'No se pudieron cargar empresas',
  util_rate_fetch_util_rates_error: 'No se pudieron cargar las tarifas de servicios públicos',

  // footer
  footer_powered_by: 'Soportado por',
  util_rates_name_required: 'Se requiere nombre',
  util_rates_price_required: 'Se requiere precio',

  /*
   * |=======|
   * | Icons |
   * |=======|
   */
  icon_type: 'Tipo',
  icon_type_device: 'Dispositivo',
  icon_type_sensor: 'Sensor',

  icon_scope: 'Alcance',
  icon_scope_global: 'Global',
  icon_scope_company: 'Compañía',
  icon_scope_device: 'Dispositivo',
  icon_scope_sensor: 'Sensor',

  icon_input_device: 'Dispositivo',
  icon_input_sensor: 'Sensor',
  icon_input_name: 'Nombre',
  icon_input_company: 'Compañía',

  icon_btn_add_icon: 'Agregar icono',
  icon_btn_upload_file: 'Subir archivo',
  icon_btn_cancel: 'Cancelar',
  icon_btn_save: 'Ahorrar',

  icon_table_name: 'Nombre',
  icon_table_scope: 'Alcance',
  icon_table_icon: 'Icono',
  icon_table_actions: 'Comportamiento',

  // errors
  icon_error_update_icon: 'No se pudo actualizar el icono',
  icon_error_unsupported_file_type: 'Tipo de archivo invalido. Solo se permiten JPEG, PNG y SVG.',
  icon_error_error_occured: 'Ocurrió un error',
  icon_error_enter_name: 'Por favor ingrese un nombre',
  icon_error_enter_icon: 'Sube un icono',
  icon_error_select_company: 'Seleccione una empresa',
  icon_error_select_device: 'Seleccione un dispositivo',
  icon_error_select_sensor: 'Seleccione un sensor',

  /*
   * |=========================|
   * | Customer Billing Report |
   * |=========================|
   */
  customer_billing_report_account_id_table_header: 'ID de Cuenta',
  customer_billing_report_description_table_header: 'Descripción',
  customer_billing_report_customer_name_table_header: 'Nombre del Cliente',
  customer_billing_report_street_table_header: 'Calle',
  customer_billing_report_quarter_table_header: 'Trimestre',
  customer_billing_report_last_reading_table_header: 'Última Lectura',
  customer_billing_report_last_reading_date_table_header: 'Fecha de Última Lectura',
  customer_billing_report_device_number_table_header: 'Número de Dispositivo',
  customer_billing_report_last_config_date_table_header: 'Fecha de última configuración',
  customer_billing_report_billing_group_table_header: 'Grupo de Facturación',
  customer_billing_report_meter_serial_table_header: 'Número de serie',
  customer_billing_report_diameter_table_header: 'Diámetro',
  customer_billing_report_meter_brand_table_header: 'Marca',
  customer_billing_report_meter_model_table_header: 'Modelo',
  customer_billing_report_default_error: 'Se ha producido un error al generar el informe',
  customer_billing_report_contract_number_table_header: 'Número de contrato',
  customer_billing_report_device_name_table_header: 'Nombre del dispositivo',
  customer_billing_report_sensor_name_table_header: 'Nombre del sensor',

  //license
  license_license_warning: 'Advertencia de Licencia',
  license_license_warning_per_reading_over_limit_singular: 'del tipo por lectura supera el límite',
  license_license_warning_expire_singular: 'próxima a expirar',
  license_license_warning_per_reading_over_limit_plural: 'del tipo por lecturas superan el límite',
  license_license_warning_expire_plural: 'próximas a expirar',
  license_max_count_error: 'El conteo máximo no puede ser 0',

  license_header: 'Licencia',
  license_table_company_name: 'Nombre de la empresa',
  license_table_license_type: 'Tipo de licencia',
  license_table_max_count: 'Cuenta máxima',
  license_table_valid_from: 'Válido desde',
  license_table_valid_to: 'Válido hasta',
  license_table_first_reading_date: 'Fecha de la primera lectura',
  license_table_last_reading_date: 'Fecha de la última lectura',
  license_table_active: 'Activo',
  license_table_valid: 'Válido',
  license_table_yes: 'Sí',
  license_table_no: 'No',
  license_table_actions: 'Acciones',

  license_warning_access_hold: 'Acceso a la cuenta en espera',
  license_warning_access_hold_description:
    'Su acceso a la plataforma ha sido suspendido temporalmente debido a un pago no resuelto. Le animamos a que se ponga en contacto con nuestro soporte o se conecte con su representante designado para ayudarle a resolver este asunto.',
  license_warning_no_valid_license: 'No hay licencia válida',
  license_warning_no_valid_license_description:
    'No pudimos encontrar una licencia válida asociada con esta cuenta. Comuníquese con su representante designado o contacte nuestro soporte para obtener ayuda.',
  license_warning_devices_count_limit_reached: 'Límite de Dispositivos Alcanzado',
  license_warning_devices_count_limit_reached_description:
    'Ha alcanzado el número máximo de dispositivos que puede vincular a la cuenta según la licencia. Si desea agregar más, por favor, póngase en contacto con su representante designado o con nuestro soporte para obtener asistencia.',
  license_warning_generic_title: 'Sin licencia',
  license_warning_generic_description: 'No se puede continuar debido a un problema de licencia.',
  license_info_renew_button: 'Renovar Licencia',
  license_info_deactivate_button: 'Desactivar Licencia',
  license_info_renew_modal_button: 'Renovar',
  license_info_used_readings: 'Lecturas utilizadas',
  license_info_readings_left: 'Lecturas restantes',
  license_renew_license_button: 'Renovar',
  license_deactivate_license_button: 'Desactivar',
  license_select_company_warning_message: 'Seleccione una empresa para continuar',
  license_add_button: 'Agregar Licencia',
  license_add_success_message: 'Licencia creada con éxito',
  license_add_error_message: 'Ocurrió un error durante el proceso',
  license_available_licenses: 'Licencias disponibles',
  license_max_devices: 'Máx Dispositivos',
  license_max_readings: 'Máx Informes',
  license_max_sensors: 'Máx Sensores',
  license_company: 'Empresa',
  license_deactivate_warning: 'Esto suspenderá el acceso de los usuarios a la plataforma',
  license_activate_warning: 'Esto restablecerá el acceso de los usuarios a la plataforma',
  license_activate_license_button: 'Activar',
  license_available_licenses: 'Licencias disponibles',
  license_add_license: 'Agregar licencia',
  license_status_active: 'Activo',
  license_status_inactive: 'Inactivo',
  license_info_activate_button: 'Activar licencia',
  license_info_used_devices: 'Dispositivos usados',
  license_info_devices_left: 'Dispositivos restantes',
  license_error_page_access_restricted: 'Acceso restringido',
  license_valid_license_existing: '¡Ya tiene una licencia válida de este tipo!',
  license_conflicting_license_date: 'Hay una licencia válida en el mismo período',

  //Password
  change_password: 'Cambiar contraseña',
  current_password: 'Contraseña actual',
  new_password: 'Nueva contraseña',
  confirm_new_password: 'Confirmar nueva contraseña',
  customer_info_saved: 'Información del cliente guardada',

  /*
   * |=========================|
   * |     Reset Password      |
   * |=========================|
   */
  reset_password_check_email_for_reset_link:
    'Revise su correo electrónico para el enlace de restablecimiento de contraseña',
  reset_password_default_error_message: 'Hubo un error al intentar restablecer su contraseña',
  reset_password_change_password_label: 'Cambiar contraseña',
  reset_password_send_btn: 'Enviar',

  /*
   * |==============================|
   * |  Sensors and Network Config  |
   * |==============================|
   */

  //headers
  sensors_and_network_config_network_config: 'Netzwerkkonfiguration des Geräts',
  sensors_and_network_config_device_details: 'Detalles del dispositivo',
  sensors_and_network_config_add_sensor: 'Sensor hinzufügen',

  //messages
  sensors_and_network_save_success: 'La configuración se guardó correctamente.',
  sensors_and_network_save_error: 'Hubo un problema al guardar la configuración.',
  sensors_and_network_formula_validation_default_error: 'La validación de la fórmula ha fallado.',
  sensors_and_network_config_required_field_error: 'Este campo es obligatorio',

  //labels
  sensors_and_network_config_sensor_index: 'Índice de sensores',

  //buttons
  sensors_and_network_config_reset_btn: 'Reiniciar',
};
export default i18n_es;
