import { PortType } from '@thingslog/repositories';

const portTypeOptions: Record<PortType, string> = {
  digital_input_port: 'digital_input_port',
  pulse_input_port: 'pulse_input_port',
  pulse_s0_input_port: 'pulse_s0_input_port',
  analog_input_port: 'analog_input_port',
  '4_20_mA_input_port': '4_20_mA_input_port',
  '0_5_V_input_port': '0_5_V_input_port',
  on_off_input_port: 'on_off_input_port',
  on_off_output_port: 'on_off_output_port',
  generic_modbus_on_off_input_port: 'generic_modbus_on_off_input_port',
  generic_modbus_on_off_output_port: 'generic_modbus_on_off_output_port',
};

export default portTypeOptions;
