import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { DeviceCreateUpdateBody, DeviceIconDto } from '@thingslog/repositories';
import { DeviceModel } from '@thingslog/repositories';
import { IconDropdown } from '@thingslog/ui-components';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import JwtValidator from '../../common/JwtValidator';
import IconSelector from '../../components/IconSelector/IconSelector';
import { CreateOrUpdateDeviceProps } from './models/CreateOrUpdateDeviceProps';

const InventoryAddDeviceModal: FC<InventoryModalProps> = ({
  iconsDropdownOptions,
  createOrUpdateDeviceData,
  onCreateDevice,
  onUpdateDevice,
  deviceModelsDropdownOptions,
}: InventoryModalProps) => {
  //initial state
  const [description, setDescription] = useState<string>('');
  const [deviceName, setDeviceName] = useState<string>('');
  const [deviceIcon, setDeviceIcon] = useState<string | null>(null); //old implementation
  const [iconId, setIconId] = useState<number | null>(
    iconsDropdownOptions[0].id !== undefined ? iconsDropdownOptions[0].id : null
  ); // new implementation
  const [deviceNumber, setDeviceNumber] = useState<string>('');
  const [hardwareVersion, setHardwareVersion] = useState<string>('');
  const [isUpdate] = useState<boolean>(createOrUpdateDeviceData.type === 'UPDATE');
  const [model, setModel] = useState<DeviceModel>(DeviceModel.LPMDL_1101);
  const [softwareVersion, setSoftwareVersion] = useState<string>('');
  const [nomenclature, setNomenclature] = useState<string | null>(null);

  //error state
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const [isHardwareValid, setIsHardwareValid] = useState<boolean>(true);
  const [isSoftwareValid, setIsSoftwareValid] = useState<boolean>(true);
  const [isDeviceNumberValid, setIsDeviceNumberValid] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  //hooks
  const { t } = useTranslation();

  const { hasRole } = useMemo(() => new JwtValidator(), []);

  useEffect(() => {
    if (createOrUpdateDeviceData.type === 'UPDATE') {
      setDeviceName(createOrUpdateDeviceData.name);
      setDeviceNumber(createOrUpdateDeviceData.deviceNumber);
      setHardwareVersion(createOrUpdateDeviceData.hwVersion);
      setSoftwareVersion(createOrUpdateDeviceData.swVersion);
      setModel(createOrUpdateDeviceData.model);
      setDeviceIcon(createOrUpdateDeviceData.deviceIcon);
      setIconId(createOrUpdateDeviceData.iconId);
      setDescription(createOrUpdateDeviceData.description);
      setNomenclature(createOrUpdateDeviceData.nomenclature);
    }
  }, [createOrUpdateDeviceData]);

  useEffect(() => {
    if (!isSoftwareValid || !isHardwareValid || !isDeviceNumberValid || description?.length > 250) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [isSoftwareValid, isHardwareValid, isDeviceNumberValid, description]);

  const createDeviceHandler = (): void => {
    let error: string | null = null;
    if (!hardwareVersion) error = t('device_inventory_empty_hardware_error');
    if (!softwareVersion) error = t('device_inventory_empty_software_error');
    if (!deviceNumber) error = t('device_inventory_empty_device_error');
    if (!nomenclature && doesDeviceRequireNomenclature(model))
      error = t('device_inventory_empty_nomenclature_error');

    if (error !== null) {
      setErrorMessage(error);
      return;
    } else {
      setErrorMessage(null);
    }

    const device: DeviceCreateUpdateBody = {
      number: deviceNumber,
      name: deviceName,
      swVersion: softwareVersion,
      hwVersion: hardwareVersion,
      model: model,
      deviceIcon: deviceIcon,
      iconId: iconId || 0,
      description: description,
      nomenclature: nomenclature,
    };

    if (onUpdateDevice && isUpdate) {
      onUpdateDevice(deviceNumber, device);
    } else if (onCreateDevice) {
      onCreateDevice(device);
    }
  };

  const doesDeviceRequireNomenclature = (model: DeviceModel): boolean => {
    switch (model) {
      case DeviceModel.LPMDL_1101:
      case DeviceModel.LPMDL_1102:
      case DeviceModel.LPMDL_1103:
      case DeviceModel.LPMDL_1103_OPENLORA:
      case DeviceModel.LPMDL_1104:
      case DeviceModel.LPMDL_1105:
      case DeviceModel.LPMDL_1106:
      case DeviceModel.TLCTL_1104:
      case DeviceModel.TLCTL_1105:
      case DeviceModel.TLCTL_1106:
      case DeviceModel.TLCTL_21A4S:
        return true;
      default:
        return false;
    }
  };

  const handleSoftwareVersionChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const regex = new RegExp(/^(\d{1,2}\.\d{1,2}\.\d{1,2})(-.+|_.+|)$/);
    setSoftwareVersion(e.target.value);
    if (regex.test(e.target.value) === false) {
      setIsSoftwareValid(false);
    } else if (regex.test(e.target.value) === true) {
      setIsSoftwareValid(true);
    }
  };

  const handleHardwareVersionChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const regex = new RegExp(/^([1-9][0-9]*)(?:\.([0-9]{1,2})){2}$/);
    setHardwareVersion(e.target.value);
    if (regex.test(e.target.value) === false) {
      setIsHardwareValid(false);
    } else if (regex.test(e.target.value) === true) {
      setIsHardwareValid(true);
    }
  };

  const handleDeviceNumberChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const regex = new RegExp(/^[a-zA-Z0-9]{8}$/);
    setDeviceNumber(e.target.value);
    if (regex.test(e.target.value) === false) {
      setIsDeviceNumberValid(false);
    } else if (regex.test(e.target.value) === true) {
      setIsDeviceNumberValid(true);
    }
  };

  return (
    <div className="flex flex-col mt-5 gap-5 ">
      {!isUpdate && (
        <FormControl>
          <TextField
            size="small"
            label={t('device_inventory_device_number')}
            value={deviceNumber}
            onInput={(e: ChangeEvent<HTMLInputElement>): void => handleDeviceNumberChange(e)}
            required
          />
        </FormControl>
      )}
      {!isDeviceNumberValid && (
        <Alert severity="error">{t('device_inventory_device_number_helper')}</Alert>
      )}

      <TextField
        size="small"
        label={t('device_inventory_device_name')}
        value={deviceName}
        onInput={(e: ChangeEvent<HTMLInputElement>): void => setDeviceName(e.target.value)}
      />
      <FormControl fullWidth size="small">
        <InputLabel>{t('device_inventory_model')}</InputLabel>
        <Select
          size="small"
          label={t('device_inventory_model')}
          value={model}
          onChange={(e: SelectChangeEvent<DeviceModel>): void =>
            setModel(e.target.value as DeviceModel)
          }
        >
          {Object.values(deviceModelsDropdownOptions).map((deviceModel: DeviceModel) => (
            <MenuItem key={deviceModel} value={deviceModel}>
              {deviceModel}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {(!isUpdate || (isUpdate && deviceNumber)) && (
        <IconSelector
          iconName={deviceIcon}
          setIconName={(deviceIcon: string | null): void => setDeviceIcon(deviceIcon)}
          selectorLabel={t('device_icon_old_portal')}
        />
      )}
      {iconsDropdownOptions?.length >= 0 && iconId !== null && (
        <IconDropdown
          icons={iconsDropdownOptions}
          selectedIconId={iconId}
          onIconChange={setIconId}
          label={t('device_icon_new_portal')}
        />
      )}
      <TextField
        size="small"
        multiline
        minRows={2}
        error={description.length > 250}
        helperText={description.length > 250 ? t('device_inventory_description_helper') : ''}
        label={t('device_inventory_description')}
        value={description}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => setDescription(e.target.value)}
      />
      <TextField
        size="small"
        label={t('device_inventory_software_version')}
        value={softwareVersion}
        onInput={(e: ChangeEvent<HTMLInputElement>): void => handleSoftwareVersionChange(e)}
        error={!isSoftwareValid}
        required
      />
      {!isSoftwareValid && (
        <Alert severity="error">{t('device_inventory_soft ware_version_helper')}</Alert>
      )}
      <TextField
        size="small"
        label={t('device_inventory_hardware_version')}
        value={hardwareVersion}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => handleHardwareVersionChange(e)}
        error={!isHardwareValid}
        required
      />
      {!isHardwareValid && (
        <Alert severity="error">{t('device_inventory_hardware_version_helper')}</Alert>
      )}
      <TextField
        size="small"
        label={t('nomenclature')}
        value={nomenclature}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => setNomenclature(e.target.value)}
      />
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Button disabled={isButtonDisabled} onClick={createDeviceHandler} variant="contained">
        {isUpdate
          ? t('device_inventory_update_device_button')
          : t('device_inventory_create_device_button')}
      </Button>
    </div>
  );
};

export interface InventoryModalProps {
  createOrUpdateDeviceData: CreateOrUpdateDeviceProps;
  onUpdateDevice?: (device: string, body: DeviceCreateUpdateBody) => void;
  onCreateDevice?: (device: DeviceCreateUpdateBody) => void;
  iconsDropdownOptions: DeviceIconDto[];
  deviceModelsDropdownOptions: DeviceModel[];
}

export default InventoryAddDeviceModal;
