import GenericDigitalSensor, { defaultGenericDigitalSensor } from './GenericDigitalSensor';

export default interface RainSensor extends GenericDigitalSensor {
  '@type': 'rain_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericDigitalSensor;

export const defaultRainSensor: RainSensor = {
  '@type': 'rain_sensor',
  ...defaultSensorWithoutType,
};
