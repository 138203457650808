import OnOffSensor, { defaultOnOffSensor } from './OnOffSensor';

export default interface OnOffAutoOutputSensor extends OnOffSensor {
  groupName: string | null;
  '@type': 'on_off_auto_output_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultOnOffSensor;

export const defaultOnOffAutoOutputSensor: OnOffAutoOutputSensor = {
  '@type': 'on_off_auto_output_sensor',
  ...defaultSensorWithoutType,
  groupName: null,
};
