import React, { FC, ReactElement, ReactNode, SyntheticEvent, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn } from 'react-hook-form';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Chip,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { ReduxState } from '../../../../reducers';
import JwtValidator from '../../../../common/JwtValidator';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import { Device, LocalControllerConfigModel } from '@thingslog/repositories';
import { filterSlaveDevices } from '../../../../common/DeviceModelUtil';

const LocalControllerNetworkConfiguration: FC<LocalControllerNetworkConfigurationProps> = () => {
  const { t } = useTranslation();
  const devices = useSelector((state: ReduxState) => state.dev.devicesArray);

  const { hasRole } = useMemo(() => new JwtValidator(), []);
  const slaveDevicesOptions: string[] = useMemo(() => filterSlaveDevices(devices), [devices]);

  return (
    <ConnectForm<LocalControllerConfigModel>>
      {({ control, getValues }: UseFormReturn<LocalControllerConfigModel>): ReactElement => (
        <div className="grid grid-cols-4 gap-5">
          <Controller
            control={control}
            name="slaveDevices"
            render={({
              field,
            }: UseControllerReturn<LocalControllerConfigModel, 'slaveDevices'>): ReactElement => (
              <Autocomplete
                multiple
                freeSolo={hasRole('ROLE_SUPER_ADMIN')} // Allows Superadmin to add more options in the dropdown. Usable when SA wants to select slave outside of selected company
                size="small"
                options={slaveDevicesOptions}
                value={field.value}
                onChange={(
                  event: SyntheticEvent,
                  value: string[],
                  reason: AutocompleteChangeReason,
                  details?: AutocompleteChangeDetails
                ): void => {
                  let slaveDevices = structuredClone(field.value);

                  if (reason === 'selectOption' && details) {
                    slaveDevices.push(details.option);
                  } else if (reason === 'createOption' && details) {
                    const newSlaves = details.option.replace(/\s+/g, '').split(',');
                    slaveDevices.push(...newSlaves);
                  } else if (reason === 'removeOption' && details) {
                    const indexToRemove = slaveDevices.indexOf(details.option);
                    slaveDevices.splice(indexToRemove, 1);
                  } else if (reason === 'clear') {
                    slaveDevices = [];
                  }

                  field.onChange(slaveDevices);
                }}
                renderInput={(params: AutocompleteRenderInputParams): JSX.Element => (
                  <TextField {...params} label={t('device_initial_config_slave_devices')} />
                )}
                renderTags={(
                  deviceNumber: string[],
                  getTagProps: AutocompleteRenderGetTagProps
                ): ReactNode => {
                  const slaveModbusAddressesMap = getValues('slaveModbusAddresses');
                  return deviceNumber.map((deviceNumber: string, index: number) => {
                    const modbusAddress = slaveModbusAddressesMap?.[deviceNumber];
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        key={key}
                        label={
                          <Link key={key} to={`/app/ParametersConfiguration/${deviceNumber}`}>
                            <span className="text-blue-500 underline cursor-pointer">
                              {`${deviceNumber}${
                                modbusAddress !== undefined ? ` (${modbusAddress})` : ''
                              }`}
                            </span>
                          </Link>
                        }
                        size="small"
                        {...tagProps}
                      />
                    );
                  });
                }}
              />
            )}
          />
        </div>
      )}
    </ConnectForm>
  );
};

interface LocalControllerNetworkConfigurationProps {}

export default LocalControllerNetworkConfiguration;
