import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import Header from '../../components/header';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import { WaterMeter, SensorType } from '@thingslog/repositories';
import useFetchDevice from '../../hooks/useFetchDevice';
import useFetchDevicePortsConfig from '../../hooks/useFetchDevicePortsConfig';
import useFetchQFlowStatistics from '../../hooks/useFetchQFlowStatistics';
import useFetchFlowStatistics from '../../hooks/useFetchFlowStatistics';
import useFetchFlowTimeHistogram from '../../hooks/useFetchFlowTimeHistogram';
import useFetchGeneralStatistics from '../../hooks/useFetchGeneralStatistics';
import IndexedWaterMeter from './model/IndexedWaterMeter';
import { Box } from '@mui/system';
import WaterMeterSelector from './components/WaterMeterSelector';
import { Grid, Paper, Typography, useTheme } from '@mui/material';
import QFlowTable from './components/QFlowTable';
import FlowTable from './components/FlowTable';
import TimeTable from './components/TimeTable';
import GeneralStatisticsTable from './components/GeneralStatisticsTable';
import { useStyles } from './Statistics.styles';
import { useTranslation } from 'react-i18next';
import PeriodPickerFlex from '../../components/DatePicker/PeriodPickerFlex';
import { RecordPeriod } from '../../model/RecordPeriod/RecordPeriod';
import { EverySelector } from '@thingslog/ui-components';
import { ReduxState } from '../../reducers';
import { useSelector } from 'react-redux';
import { deviceConfigQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';
import { ConfPeriod } from '@thingslog/repositories';

const Statistics: React.FC = () => {
  // #region State
  // Hooks
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles(theme);

  // Page state
  const [every, setEvery] = useState<number | null>(1);
  const [waterMeters, setWaterMeters] = useState<IndexedWaterMeter[]>([]);
  const [selectedSensorIndex, setSelectedSensorIndex] = useState<number | null>(null);
  const [confPeriod, setConfPeriod] = useState<ConfPeriod | null>(null);

  // Errors
  const [errorFound, setErrorFound] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>();

  // Custom Hooks
  const { deviceNumber } = useParams();

  const selectedDevice = useSelector((state: ReduxState) => state.dev.selectedDevice);

  const { useDeviceConfig } = useMemo(() => deviceConfigQueryClient, []);

  const { deviceError, deviceLoading } = useFetchDevice(deviceNumber);
  const {
    data: deviceConfig,
    isLoading: deviceConfigLoading,
    isError: deviceConfigError,
  } = useDeviceConfig(deviceNumber!, { enabled: typeof deviceNumber === 'string' });
  const { portsConfig, portsConfigLoading, portsConfigError } =
    useFetchDevicePortsConfig(deviceNumber);
  const { flow, flowLoading, flowError } = useFetchFlowStatistics(
    deviceNumber,
    selectedSensorIndex,
    every
  );
  const { time, timeLoading, timeError } = useFetchFlowTimeHistogram(
    deviceNumber,
    selectedSensorIndex,
    every
  );
  const { qflow, qflowLoading, qflowError } = useFetchQFlowStatistics(
    deviceNumber,
    selectedSensorIndex,
    every
  );
  const { general, generalLoading, generalError } = useFetchGeneralStatistics(
    deviceNumber,
    selectedSensorIndex,
    every
  );
  // #endregion

  // #region UseEffect
  useEffect(() => {
    // whenever portsConfig changes, update waterMeters
    if (portsConfig === null) {
      setWaterMeters([]);
    } else {
      const waterMeters: IndexedWaterMeter[] = [];
      for (const entry of Object.entries(portsConfig)) {
        const index = +entry[0];
        const port = entry[1];
        if (port.sensor['@type'] === 'water_meter' && port.enabled) {
          waterMeters.push({ index, waterMeter: port.sensor as WaterMeter });
        }
      }
      setWaterMeters(waterMeters);
    }
  }, [portsConfig]);

  useEffect(() => {
    // Auto select the first available sensor if there is one
    if (waterMeters.length !== 0) {
      setSelectedSensorIndex(waterMeters[0].index);
    }
  }, [waterMeters]);

  useEffect(() => {
    let confPeriod: ConfPeriod | null = null;
    if (deviceConfig?.recordPeriod === RecordPeriod.DAYS) confPeriod = 'DAYS';
    if (deviceConfig?.recordPeriod === RecordPeriod.HOURS) confPeriod = 'HOURS';
    if (deviceConfig?.recordPeriod === RecordPeriod.MINUTES) confPeriod = 'MINUTES';
    if (deviceConfig?.recordPeriod === RecordPeriod.SECONDS) confPeriod = 'SECONDS';

    setConfPeriod(confPeriod);
  }, [deviceConfig]);

  useEffect(() => {
    wipeError();
    // device and device config errors displayed on main page instead of tables
    // statistics errors are displayed on tables
    checkForWaterMeters();
    if (!deviceLoading && deviceError) throwError(deviceError);
    if (!portsConfigLoading && portsConfigError) throwError(portsConfigError);
    if (!deviceConfigLoading && deviceConfigError)
      throwError(t('error_cannot_fetch_device_config'));
  }, [
    deviceError,
    deviceLoading,
    portsConfigLoading,
    portsConfigError,
    deviceConfigLoading,
    deviceConfigError,
    waterMeters,
    selectedSensorIndex, // important
  ]);
  // #endregion

  // #region Functions
  const wipeError = (): void => {
    setErrorFound(false);
    setErrorText('');
  };

  const throwError = (message: string): void => {
    setErrorFound(true);
    setErrorText(message);
  };

  const checkForWaterMeters = (): void => {
    waterMeters.forEach((indexedWaterMeter: IndexedWaterMeter) => {
      const waterMeter = indexedWaterMeter.waterMeter;
      if (waterMeter.q3 === undefined || waterMeter.q3 === null) {
        throwError(
          t('statistics_error_definition_for_q3', {
            name: indexedWaterMeter.waterMeter.name,
          })
        );
      }
      if (waterMeter.diameter === undefined || waterMeter.diameter === null) {
        throwError(
          t('statistics_eror_definition_for_diameter', {
            name: indexedWaterMeter.waterMeter.name,
          })
        );
      }
      if (waterMeter.r === undefined || waterMeter.r === null) {
        throwError(
          t('statistics_error_definition_for_r', {
            name: indexedWaterMeter.waterMeter.name,
          })
        );
      }
    });

    if (waterMeters.length === 0) {
      throwError(t('statistics_error_no_water_meters_found'));
    }
  };
  // #endregion

  // #region Render
  const renderContent = (): JSX.Element => {
    if (errorFound) {
      return (
        <>
          <Box padding={15} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h6">{errorText}</Typography>
          </Box>
        </>
      );
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <QFlowTable qFlow={qflow} loading={qflowLoading} error={qflowError} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <FlowTable flow={flow} loading={flowLoading} error={flowError} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <TimeTable time={time} loading={timeLoading} error={timeError} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <GeneralStatisticsTable general={general} loading={generalLoading} error={generalError} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Header>
      <Grid container direction="column" alignItems="center" justifyContent="center" rowSpacing={2}>
        {selectedDevice && (
          <Grid item>
            <TitleHeader
              title={t('statistics_header')}
              deviceNumber={selectedDevice.number}
              deviceName={selectedDevice.name}
              customerInfo={selectedDevice.customerInfo}
            />
          </Grid>
        )}
        <Grid item sm={12} md={11} lg={9} xl={8}>
          <Paper elevation={2}>
            <Paper elevation={0} className={classes.paperHeader}>
              <Box
                paddingTop={1}
                paddingBottom={1}
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                sx={{
                  justifyContent: {
                    xs: 'center',
                    sm: 'center',
                    md: 'start',
                    lg: 'start',
                    xl: 'start',
                  },
                }}
              >
                <PeriodPickerFlex />
                <Box sx={{ flexGrow: { xs: 0, sm: 0, md: 1, xl: 1 } }} />
                {selectedSensorIndex !== null && (
                  <Box padding={1}>
                    <WaterMeterSelector
                      waterMeters={waterMeters}
                      selectedIndex={selectedSensorIndex}
                      setSelectedIndex={setSelectedSensorIndex}
                    />
                  </Box>
                )}
                {every !== null && confPeriod && deviceConfig && (
                  <Box padding={1}>
                    <EverySelector
                      confEvery={deviceConfig.every}
                      confPeriod={confPeriod}
                      every={every}
                      onEveryChange={setEvery}
                      translation={{
                        day: t('day'),
                        days: t('days'),
                        hour: t('hour'),
                        hours: t('hours'),
                        minute: t('minute'),
                        minutes: t('minutes'),
                        second: t('second'),
                        seconds: t('seconds'),
                        label: t('every'),
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Paper>
            <Box padding={2}>{renderContent()}</Box>
          </Paper>
        </Grid>
      </Grid>
    </Header>
  );
  // #endregion
};

export default Statistics;
