import OnOffOutputPort, { defaultOnOffOutputPort } from './OnOffOutputPort';

export default interface GenericModbusOnOffOutputPort extends Omit<OnOffOutputPort, '@type'> {
  '@type': 'generic_modbus_on_off_output_port';
}

const { '@type': _, ...defaultPortWithoutType } = defaultOnOffOutputPort;

export const defaultGenericModbusOnOffOutputPort: GenericModbusOnOffOutputPort = {
  '@type': 'generic_modbus_on_off_output_port',
  ...defaultPortWithoutType,
};
