import React, { FC, useCallback, useMemo } from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

const ValueRangePeriodSummaryItem: FC<ValueRangePeriodSummaryItemProps> = (
  props: ValueRangePeriodSummaryItemProps
) => {
  const { icon: Icon, label, type } = props;

  const { t } = useTranslation();

  const formatDate = useCallback(
    (day: number | null, month: number | null): string | null =>
      day !== null && month !== null ? format(new Date(2022, month - 1, day), 'dd MMM') : null,
    []
  );

  const formatTime = useCallback(
    (hour: number | null, minute: number | null): string | null =>
      hour !== null && minute !== null ? format(new Date(0, 0, 0, hour, minute), 'HH:mm') : null,
    []
  );

  const formatRange = useCallback((start: string | null, end: string | null): string | null => {
    if (start === null && end === null) return null;
    if (start !== null && end === null) return `${t('value_ranges_after')} ${start}`;
    if (start === null && end !== null) return `${t('value_ranges_before')} ${end}`;
    return `${start} - ${end}`;
  }, []);

  const value = useMemo(() => {
    switch (type) {
      case 'date':
        const fromDate = formatDate(props.startDay, props.startMonth);
        const endDate = formatDate(props.endDay, props.endMonth);
        return formatRange(fromDate, endDate);
      case 'time':
        const fromTime = formatTime(props.startHour, props.startMinute);
        const endTime = formatTime(props.endHour, props.endMinute);
        return formatRange(fromTime, endTime);
      case 'timezone':
        return props.timezone;
    }
  }, [props]);

  return value ? (
    <Tooltip className="max-sm:pointer-events-none h-fit" arrow title={label}>
      <div className="flex items-top gap-1">
        <Icon />
        <span className="sm:hidden ">{`${label}: `}</span>
        <span className="font-semibold ml-auto">{value}</span>
      </div>
    </Tooltip>
  ) : (
    <></>
  );
};

interface ValueRangePeriodSummaryBaseProps {
  type: 'date' | 'time' | 'timezone';
  icon: SvgIconComponent;
  label: string;
}

interface ValueRangeDateSummaryItemProps extends ValueRangePeriodSummaryBaseProps {
  type: 'date';
  startDay: number | null;
  endDay: number | null;
  startMonth: number | null;
  endMonth: number | null;
}

interface ValueRangeTimeSummaryItemProps extends ValueRangePeriodSummaryBaseProps {
  type: 'time';
  startHour: number | null;
  endHour: number | null;
  startMinute: number | null;
  endMinute: number | null;
}

interface ValueRangeTimezoneSummaryItemProps extends ValueRangePeriodSummaryBaseProps {
  type: 'timezone';
  timezone: string;
}

type ValueRangePeriodSummaryItemProps =
  | ValueRangeDateSummaryItemProps
  | ValueRangeTimeSummaryItemProps
  | ValueRangeTimezoneSummaryItemProps;

export default ValueRangePeriodSummaryItem;
