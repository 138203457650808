import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface COSensor extends GenericAnalogSensor {
  '@type': 'co_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultCOSensor: COSensor = {
  '@type': 'co_sensor',
  ...defaultSensorWithoutType,
};
