import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import devicesClient from './DevicesClient';
import deviceConfigClient from './DeviceConfigClient';
import deviceCountersClient from './DeviceCountersClient';
import meterInfoClient from './MeterInfoClient';
import Button from '@mui/material/Button';
import PeriodPicker from './components/DatePicker/PeriodPicker';
import AveragePeriodPicker from './components/DatePicker/AveragePeriodPicker';
import {
  styler,
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  ScatterChart,
  Baseline,
  EventMarker,
  LineChart,
  Resizable,
} from 'react-timeseries-charts';
import { Index, TimeSeries } from 'pondjs';
import CircularProgress from '@mui/material/CircularProgress';
import * as actions from './actions';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography/Typography';
import SensorSelector, { findFirstEnabledSensor } from './components/SensorSelector';
import Header from './components/header';
import Grid from '@mui/material/Grid';
import sensorsConfigClient from './SensorsConfigClient';
import { DisplayUnit } from '@thingslog/repositories';
import { withTranslation } from 'react-i18next';
import { ControlLabel } from 'react-bootstrap';
import Switch from '@mui/material/Switch';
import SensorStatistics from './components/SensorStatistics';
import Description from './components/TitleHeader/CustomerInfoDescription';
import GoogleAnalyticsService from './common/GoogleAnalyticsService';
import { GaEventCategory } from './common/GaEventCategory';
import { GaEventAction } from './common/GaEventAction';
import { withRouter } from './common/withRouter';
import ErrorUtil from './common/ErrorUtil';

class DeviceCountersCumulativeGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'loading',
      showAverage: false,
      series: null,
      timerange: null,
      tracker: null,
      trackerValue: null,
      trackerEvent: null,
      sensorIndex: 0,
      customerInfo: null,
    };
    this.loadDeviceCountersFromServer = this.loadDeviceCountersFromServer.bind(this);
    this.handleTrackerChanged = this.handleTrackerChanged.bind(this);
    this.handleTimeRangeChange = this.handleTimeRangeChange.bind(this);
    this.handleChartResize = this.handleChartResize.bind(this);
    this.createSeries = this.createSeries.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.handleApplyButtonClick = this.handleApplyButtonClick.bind(this);
    this.orientationDidChange = this.orientationDidChange.bind(this);
    this.toggleShowAverage = this.toggleShowAverage.bind(this);
    this.mql = window.matchMedia('(orientation: portrait)');
  }

  errorCallback(error) {
    ErrorUtil.handleErrorWithAlert(error);
    this.setState({ data: [] });
  }

  handleApplyButtonClick() {
    GoogleAnalyticsService.triggerEvent(GaEventCategory.GRAPH_PAGE, GaEventAction.GRAPH_PAGE_APPLY);
    this.setState({ status: 'loading' }, this.loadDeviceCountersFromServer);
  }

  handleSensorChange = (event) => this.setState({ sensorIndex: event.target.value });

  handleTrackerChanged(t) {
    if (t) {
      const e = this.state.series.atTime(t);
      const eventTime = new Date(
        e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2
      );
      const eventValue = e.get('val');
      const v = parseFloat(eventValue).toFixed(5);
      this.setState({ tracker: eventTime, trackerValue: v, trackerEvent: e });
    } else {
      this.setState({ tracker: null, trackerValue: null, trackerEvent: null });
    }
  }

  // Handles when the brush changes the timerange
  handleTimeRangeChange(timerange) {
    if (timerange) {
      this.setState({ timerange });
    } else {
      this.setState({ timerange: this.series.range() });
    }
  }

  toggleShowAverage() {
    this.setState({ showAverage: !this.state.showAverage });
  }

  handleChartResize(width) {
    this.state({ width });
  }

  orientationDidChange(orientationQuery) {
    this.setState({ isLendscapeOrientation: !orientationQuery.matches });
  }

  getGraphMin() {
    if (this.state.seriesAvg && this.state.showAverage) {
      return this.state.series.min('val') < this.state.seriesAvg.min('avg')
        ? this.state.series.min('val')
        : this.state.seriesAvg.min('avg');
    }
    return this.state.series.min('val');
  }

  getGraphMax() {
    if (this.state.seriesAvg && this.state.showAverage) {
      return this.state.series.max('val') > this.state.seriesAvg.max('avg')
        ? this.state.series.max('val')
        : this.state.seriesAvg.max('avg');
    }
    return this.state.series.max('val');
  }

  render() {
    const style = styler([{ key: 'val', color: '#00BCD4' }]);
    const style1 = styler([{ key: 'avg', color: '#0000FF' }]);

    return (
      <Header>
        <div>
          <Grid container direction={'column'}>
            <Grid item>
              <Typography variant="h6" component="h5" paragraph>
                {this.props.t('device_counters_cumulative_graph_graph', {
                  device_number: this.props.match.params.deviceNumber,
                })}
              </Typography>
            </Grid>
            <Grid item>
              {this.state.customerInfo && <Description customerInfo={this.state.customerInfo} />}
            </Grid>
            {this.state.every && (
              <SensorStatistics
                deviceNumber={this.props.match.params.deviceNumber}
                sensorIndex={this.props.sensorIndex}
                every={this.state.every}
              />
            )}
            <Grid container spacing={4} justifyContent="center">
              {this.state.showAverage ? <AveragePeriodPicker /> : <PeriodPicker />}
              <SensorSelector portsConfig={this.state.portsConfig} />
              <Grid item>
                <ControlLabel>{this.props.t('device_counter_graph_average')}</ControlLabel>
                <Switch
                  value={this.state.showAverage}
                  onChange={this.toggleShowAverage.bind(this)}
                />
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={this.handleApplyButtonClick.bind(this)}>
                  {this.props.t('button_apply')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {this.state.status === 'loading' && <CircularProgress size={80} thickness={5} />}
          {this.state.status === 'no_data' && (
            <Typography component="p" paragraph>
              {this.props.t('no_data')}.
            </Typography>
          )}
          {this.state.status === 'ready' && (
            <Resizable>
              <ChartContainer
                timeRange={this.state.timerange}
                trackerPosition={this.state.tracker}
                onTrackerChanged={this.handleTrackerChanged}
                enablePanZoom
                enableDragZoom
                maxTime={this.state.series.range().end()}
                minTime={this.state.series.range().begin()}
                minDuration={10 * 60 * 1000}
                onTimeRangeChanged={this.handleTimeRangeChange}
                onChartResize={this.handleChartResize}
                showGrid={false}
              >
                <ChartRow height="350">
                  <YAxis
                    id="yaxis"
                    min={this.getGraphMin()}
                    max={this.getGraphMax()}
                    format=".3f"
                    width="70"
                    type="linear"
                    label={DisplayUnit[this.state.port.sensor.units]}
                    labelOffset={-20}
                  />
                  <Charts>
                    <LineChart
                      axis="yaxis"
                      style={style}
                      spacing={1}
                      columns={['val']}
                      series={this.state.series}
                    />
                    <ScatterChart
                      axis="yaxis"
                      style={style}
                      spacing={1}
                      columns={['val']}
                      series={this.state.series}
                    />
                    {this.state.seriesAvg != null && this.state.showAverage ? (
                      <LineChart
                        axis="yaxis"
                        style={style1}
                        spacing={1}
                        columns={['avg']}
                        series={this.state.seriesAvg}
                      />
                    ) : (
                      []
                    )}
                    {this.state.seriesAvg != null && this.state.showAverage ? (
                      <ScatterChart
                        axis="yaxis"
                        style={style1}
                        spacing={1}
                        columns={['avg']}
                        series={this.state.seriesAvg}
                      />
                    ) : (
                      []
                    )}
                    <Baseline axis="yaxis" value={this.state.q1} label="Q1" position="left" />
                    <Baseline axis="yaxis" value={this.state.q2} label="Q2" position="left" />
                    <Baseline axis="yaxis" value={this.state.q3} label="Q3" position="left" />
                    <Baseline axis="yaxis" value={this.state.q4} label="Q4" position="left" />
                    <EventMarker
                      type="flag"
                      axis="yaxis"
                      event={this.state.trackerEvent}
                      column={this.state.series.name()}
                      info={[{ label: 'Reading', value: this.state.trackerValue }]}
                      infoTimeFormat="%Y-%m-%d %H-%M"
                      infoWidth={120}
                      markerRadius={2}
                      markerStyle={{ fill: 'black' }}
                    />
                  </Charts>
                </ChartRow>
              </ChartContainer>
            </Resizable>
          )}
        </div>
      </Header>
    );
  }

  componentWillMount() {
    var isPortraitOrientation = this.mql.matches;
    this.setState({ isLendscapeOrientation: !isPortraitOrientation });
  }

  componentDidMount() {
    this.mql.addListener(this.orientationDidChange);
    this.loadDeviceCountersFromServer();
  }

  componentWillUnmount() {
    this.mql.removeListener(this.orientationDidChange);
  }

  loadDeviceCountersFromServer() {
    let self = this;
    let fromDate = this.props.fromDate;
    let toDate = this.props.toDate;
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    let avgFromDate = this.props.avgFromDate;
    let avgToDate = this.props.avgToDate;
    avgFromDate.setHours(0, 0, 0, 0);
    avgToDate.setHours(23, 59, 59, 999);
    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    let fromDateISO = new Date(fromDate - tzoffset).toISOString();
    let toDateISO = new Date(toDate - tzoffset).toISOString();
    let avgFromDateISO = new Date(avgFromDate - tzoffset).toISOString();
    let avgToDateISO = new Date(avgToDate - tzoffset).toISOString();
    this.setState({ status: 'loading', series: null });
    devicesClient.getDevice(
      self.props.match.params.deviceNumber,
      (device) => {
        sensorsConfigClient.getPortsConfig(
          self.props.match.params.deviceNumber,
          (portsConfig) => {
            let idx = findFirstEnabledSensor(portsConfig, self.props.sensorIndex);
            self.props.sensorIndexChanged(idx);
            deviceConfigClient.getDeviceConfig(
              self.props.match.params.deviceNumber,
              (conf) => {
                let every = conf.every > 1 ? 1 : self.state.every;
                meterInfoClient.getMeterInfo(
                  self.props.match.params.deviceNumber,
                  (meterInfo) => {
                    deviceCountersClient.getDeviceCounters(
                      self.props.match.params.deviceNumber,
                      self.props.sensorIndex,
                      fromDateISO,
                      toDateISO,
                      (data) => {
                        const series = self.createSeries(data, conf);
                        if (series == null) {
                          self.setState({
                            status: 'no_data',
                            customerInfo: device.customerInfo,
                            portsConfig: portsConfig,
                          });
                          return;
                        }

                        if (!self.state.showAverage) {
                          self.setState({
                            status: 'ready',
                            series: series,
                            timerange: series.range(),
                            tracker: self.tracker,
                            customerInfo: device.customerInfo,
                            every: every,
                            portsConfig: portsConfig,
                            port: portsConfig[this.props.sensorIndex],
                          });
                        }

                        if (self.state.showAverage) {
                          deviceCountersClient.getDeviceCountersAvg(
                            self.props.match.params.deviceNumber,
                            self.props.sensorIndex,
                            avgFromDateISO,
                            avgToDateISO,
                            fromDateISO,
                            toDateISO,
                            (data) => {
                              let seriesAvg = self.createSeriesFlowAvg(data.value, conf);
                              self.setState({
                                status: 'ready',
                                series: series,
                                timerange: series.range(),
                                tracker: self.tracker,
                                customerInfo: device.customerInfo,
                                every: every,
                                portsConfig: portsConfig,
                                port: portsConfig[this.props.sensorIndex],
                                seriesAvg: seriesAvg,
                              });
                            },
                            self.errorCallback
                          );
                        }
                      },
                      self.errorCallback
                    );
                  },
                  self.errorCallback
                );
              },
              self.errorCallback
            );
          },
          self.errorCallback
        );
      },
      self.errorCallback
    );
  }

  createSeries(data, conf) {
    let points = [];
    for (let j = 0; j < data.length; j++) {
      points.push([Index.getIndexString('1min', new Date(data[j].date)), data[j].reading]);
    }
    if (points.length > 0) {
      return new TimeSeries({
        name: 'avg',
        columns: ['index', 'val'],
        points: points,
      });
    } else {
      return null;
    }
  }

  createSeriesFlowAvg(data, conf) {
    let points = [];
    for (let j = 0; j < data.length; j++) {
      points.push([Index.getIndexString('1min', new Date(data[j].date)), data[j].reading]);
    }
    if (points.length > 0) {
      return new TimeSeries({
        name: 'avg',
        columns: ['index', 'avg'],
        points: points,
      });
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    fromDate: state.period.fromDate,
    toDate: state.period.toDate,
    avgFromDate: state.period.avgFromDate,
    avgToDate: state.period.avgToDate,
    sensorIndex: state.dev.sensorIndex,
  };
}

export default connect(
  mapStateToProps,
  actions
)(withTranslation()(withRouter(DeviceCountersCumulativeGraph)));
