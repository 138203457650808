import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface NO2Sensor extends GenericAnalogSensor {
  '@type': 'no2_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultNO2Sensor: NO2Sensor = {
  '@type': 'no2_sensor',
  ...defaultSensorWithoutType,
};
