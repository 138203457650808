import GenericDigitalSensor, { defaultGenericDigitalSensor } from './GenericDigitalSensor';

export default interface PowerMeter extends GenericDigitalSensor {
  '@type': 'power_meter';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericDigitalSensor;

export const defaultPowerMeter: PowerMeter = {
  '@type': 'power_meter',
  ...defaultSensorWithoutType,
};
