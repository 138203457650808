import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface PressureSensor extends GenericAnalogSensor {
  '@type': 'pressure_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultPressureSensor: PressureSensor = {
  '@type': 'pressure_sensor',
  ...defaultSensorWithoutType,
};
