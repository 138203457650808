import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';
import ModbusSensorBase, { defaultModbusSensorBase } from './ModbusSensorBase';

export default interface ModbusGenericAnalogSensor extends GenericAnalogSensor, ModbusSensorBase {
  numberBytes: number;
}
const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultModbusGenericAnalogSensor: ModbusGenericAnalogSensor = {
  '@type': 'modbus_generic_analog_sensor',
  ...defaultSensorWithoutType,
  ...defaultModbusSensorBase,
  numberBytes: 2,
};
