/**
 * **InitialConfigConverter**
 *
 * This class provides static methods to convert between `DeviceInitialConfig` and `InitialConfigFormFields`.
 *
 * **What It Converts:**
 * - Transforms the `ports` object in `DeviceInitialConfig` into an array of port objects, each with a `portKey`. All other fields and structures are preserved.
 *
 * **Why Conversion is Necessary:**
 * - Initial config contains many nested objects and dynamic fields, which `react-hook-form` and typescript does not handle well. It cannot resolve paths like `ports${portIndex}.sensor.name`.
 * - Avoids the risks associated with casting paths.
 * - Enables the use of `useFieldArray`, the integrated library's hook for dynamic array fields, which provides optimized methods to add and remove fields.
 */

import { DeviceInitialConfig, Port, PortsConfig } from '@thingslog/repositories';
import InitialConfigFormFields from '../models/InitialConfigFormFields';

export default class InitialConfigConverter {
  public static portsConfigToFormFields(
    initialConfig: DeviceInitialConfig
  ): InitialConfigFormFields {
    const { ports, ...rest } = initialConfig;

    let formFieldsPorts: Array<{ portKey: number } & Port> = [];

    if (ports) {
      formFieldsPorts = Object.entries(ports).map(([portKey, portData]: [string, Port]) => ({
        portKey: Number(portKey),
        ...portData,
      }));
    }
    const result: InitialConfigFormFields = {
      ...rest,
      ports: formFieldsPorts,
    };

    return result;
  }

  public static formFieldsToPortsConfig(formFields: InitialConfigFormFields): DeviceInitialConfig {
    const { ports, ...rest } = formFields;

    let configPorts: PortsConfig = {};

    if (ports && ports.length > 0) {
      configPorts = {};
      for (const port of ports) {
        const { portKey, ...portData } = port;
        configPorts[portKey] = portData;
      }
    }

    const result: DeviceInitialConfig = {
      ...rest,
      ports: configPorts,
    };

    return result;
  }
}
