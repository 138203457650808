import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface WeightMeter extends GenericAnalogSensor {
  '@type': 'weight_meter';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultWeightMeter: WeightMeter = {
  '@type': 'weight_meter',
  ...defaultSensorWithoutType,
};
