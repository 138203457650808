import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getSensorTypeOptionsBasedOnPortTypeAndDeviceModel } from '../common/SensorTypeOptions';
import { DeviceType, PortType } from '@thingslog/repositories';
import { ControllerRenderProps } from 'react-hook-form';
import InitialConfigFormFields from '../models/InitialConfigFormFields';

const SensorTypeSelector: FC<SensorTypeSelectorProps> = ({
  portType,
  deviceModel,
  field,
}: SensorTypeSelectorProps) => {
  const { t } = useTranslation();
  const sensorTypeOptions = getSensorTypeOptionsBasedOnPortTypeAndDeviceModel(
    portType,
    deviceModel
  );

  return (
    <FormControl className="order-[-1]" size="small">
      <InputLabel>{t('device_config_sensor_type')}</InputLabel>
      <Select {...field} label={t('device_config_sensor_type')}>
        {sensorTypeOptions &&
          Object.entries(sensorTypeOptions).map(([value, label]: [string, string]) => (
            <MenuItem key={value} value={value}>
              {t(label)}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText> </FormHelperText>
    </FormControl>
  );
};

export default SensorTypeSelector;

interface SensorTypeSelectorProps {
  portType: PortType;
  deviceModel: DeviceType;
  field?: ControllerRenderProps<InitialConfigFormFields, `ports.${number}.sensor.@type`>;
}
