import OnOffSensor, { defaultOnOffSensor } from './OnOffSensor';

export default interface OnOffInputSensor extends OnOffSensor {
  '@type': 'on_off_input_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultOnOffSensor;

export const defaultOnOffInputSensor: OnOffInputSensor = {
  '@type': 'on_off_input_sensor',
  ...defaultSensorWithoutType,
};
