import React, { FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';

import InitialConfigFormFields from '../../models/InitialConfigFormFields';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import { HelperTextInputField } from '@thingslog/ui-components';

const TasmotaOnOffConfig: FC<TasmotaOnOffConfigProps> = ({ index }: TasmotaOnOffConfigProps) => {
  const { t } = useTranslation();

  return (
    <ConnectForm<InitialConfigFormFields>>
      {({ register }: UseFormReturn<InitialConfigFormFields>): ReactElement => (
        <>
          <HelperTextInputField
            {...register(`ports.${index}.sensor.power`)}
            size="small"
            label={t('power')}
          />
        </>
      )}
    </ConnectForm>
  );
};

interface TasmotaOnOffConfigProps {
  index: number;
}

export default TasmotaOnOffConfig;
