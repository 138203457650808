import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface SoilPotentialSensor extends GenericAnalogSensor {
  '@type': 'soil_potential_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultSoilPotentialSensor: SoilPotentialSensor = {
  '@type': 'soil_potential_sensor',
  ...defaultSensorWithoutType,
};
