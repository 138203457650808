import PortBase, { defaultPortBase } from './PortBase';

export default interface DigitalInputPort extends PortBase {
  '@type': 'digital_input_port';
}

const { '@type': _, ...defaultPortWithoutType } = defaultPortBase;

export const defaultDigitalInputPort: DigitalInputPort = {
  '@type': 'digital_input_port',
  ...defaultPortWithoutType,
};

// should we overwrite direction here ?
// const { '@type': _, direction, ...defaultPortWithoutType } = defaultPortBase;
