import OnOffOutputSensorBase, { defaultOnOffOutputSensorBase } from './OnOffOutputSensorBase';

export default interface OnOffOutputSensor extends OnOffOutputSensorBase {
  '@type': 'on_off_output_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultOnOffOutputSensorBase;

export const defaultOnOffOutputSensor: OnOffOutputSensor = {
  '@type': 'on_off_output_sensor',
  ...defaultSensorWithoutType,
};
