import SensorBase, { defaultSensorBase } from './SensorBase';

export default interface OnOffSensor extends SensorBase {
  valueLabels: ValueLabels | null;
  invertedReadStateValue: boolean;
}

interface ValueLabels {
  [0]: string | undefined;
  [1]: string | undefined;
}

const { '@type': _, ...defaultSensorWithoutType } = defaultSensorBase;

export const defaultOnOffSensor: OnOffSensor = {
  '@type': 'on_off_sensor',
  ...defaultSensorWithoutType,
  valueLabels: null,
  invertedReadStateValue: false,
};
