import GenericDigitalSensor, { defaultGenericDigitalSensor } from './GenericDigitalSensor';

export default interface TasmotaGenericDigitalSensor extends GenericDigitalSensor {
  '@type': 'tasmota_generic_digital_sensor';
  measurementGroup: string | null;
  measurementType: string | null;
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericDigitalSensor;

export const defaultTasmotaGenericDigitalSensor: TasmotaGenericDigitalSensor = {
  '@type': 'tasmota_generic_digital_sensor',
  ...defaultSensorWithoutType,
  measurementGroup: null,
  measurementType: null,
};
