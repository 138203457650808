import GenericAnalogSensor, { defaultGenericAnalogSensor } from './GenericAnalogSensor';

export default interface WindSpeedSensor extends GenericAnalogSensor {
  '@type': 'wind_speed_sensor';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericAnalogSensor;

export const defaultWindSpeedSensor: WindSpeedSensor = {
  '@type': 'wind_speed_sensor',
  ...defaultSensorWithoutType,
};
