import DigitalInputPort, { defaultDigitalInputPort } from './DigitalInputPort';

export default interface OnOffInputPort extends Omit<DigitalInputPort, '@type'> {
  '@type': 'on_off_input_port';
}

const { '@type': _, ...defaultPortWithoutType } = defaultDigitalInputPort;

export const defaultOnOffInputPort: OnOffInputPort = {
  '@type': 'on_off_input_port',
  ...defaultPortWithoutType,
};
