import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { SensorType, MeasurementUnit } from '@thingslog/repositories';
import { QueryKeys } from '../enums/QueryKeys';
import { QueryOptions } from '@thingslog/repositories';

export interface SensorAllowedUnitsQueryClient {
  getSensorAllowedUnits: (sensorType: SensorType) => Promise<MeasurementUnit[]>;
  useSensorAllowedUnits: (
    sensorType: SensorType,
    options?: QueryOptions<MeasurementUnit[]>
  ) => UseQueryResult<MeasurementUnit[], AxiosError>;
}

export function createSensorAllowedUnitsQueryClient(
  axios: AxiosInstance
): SensorAllowedUnitsQueryClient {
  return new SensorAllowedUnitsQueryClientImp(axios);
}

class SensorAllowedUnitsQueryClientImp {
  public constructor(private axios: AxiosInstance) {}

  public getSensorAllowedUnits = async (sensorType: SensorType): Promise<MeasurementUnit[]> => {
    return await this.axios
      .get(`/api/allowed-units`, { params: { sensorType: sensorType } })
      .then((response: AxiosResponse) => response.data);
  };

  public useSensorAllowedUnits = (
    sensorType: SensorType,
    options?: QueryOptions<MeasurementUnit[]>
  ): UseQueryResult<MeasurementUnit[], AxiosError> => {
    return useQuery<MeasurementUnit[], AxiosError>(
      [QueryKeys.GetSensorAllowedUnits, sensorType],
      () => this.getSensorAllowedUnits(sensorType),
      options
    );
  };
}
