import GenericDigitalSensor, { defaultGenericDigitalSensor } from './GenericDigitalSensor';

export default interface GeigerMuellerCounter extends GenericDigitalSensor {
  '@type': 'geiger_mueller_counter';
}

const { '@type': _, cumulative, ...defaultSensorWithoutType } = defaultGenericDigitalSensor;

export const defaultGeigerMuellerCounter: GeigerMuellerCounter = {
  '@type': 'geiger_mueller_counter',
  cumulative: false,
  ...defaultSensorWithoutType,
};
