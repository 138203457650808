import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import deviceFlowsClient from './DeviceFlowsClient';
import {
  styler,
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  // Resizable,
  Legend,
  LineChart,
  ScatterChart,
  EventMarker,
} from 'react-timeseries-charts';
import { Index, TimeSeries } from 'pondjs';
import TextField from '@mui/material/TextField';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import * as actions from './actions';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { FlowUnits } from './model/Units/FlowUnits';
import ErrorUtil from './common/ErrorUtil';

/////////
var rgbColors = function (t) {
  t = parseInt(t, 10);
  if (t === 1) return [[0, 188, 212]];
  if (t < 2) throw new Error("'t' must be greater than 1.");

  // distribute the colors evenly on
  // the hue range (the 'H' in HSV)
  var i = 360 / (t - 1);

  // hold the generated colors
  var r = [];
  var sv = 70;
  for (var x = 0; x < t; x++) {
    // alternate the s, v for more
    // contrast between the colors.
    sv = sv > 90 ? 70 : sv + 10;
    r.push(hsvToRgb(i * x, sv, sv));
  }
  return r;
};
/**
 * HSV to RGB color conversion
 *
 * H runs from 0 to 360 degrees
 * S and V run from 0 to 100
 *
 * Ported from the excellent java algorithm by Eugene Vishnevsky at:
 * http://www.cs.rit.edu/~ncs/color/t_convert.html
 */
function hsvToRgb(h, s, v) {
  var r, g, b;
  var i;
  var f, p, q, t;

  // Make sure our arguments stay in-range
  h = Math.max(0, Math.min(360, h));
  s = Math.max(0, Math.min(100, s));
  v = Math.max(0, Math.min(100, v));

  // We accept saturation and value arguments from 0 to 100 because that's
  // how Photoshop represents those values. Internally, however, the
  // saturation and value are calculated from a range of 0 to 1. We make
  // That conversion here.
  s /= 100;
  v /= 100;

  if (s === 0) {
    // Achromatic (grey)
    r = g = b = v;
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  h /= 60; // sector 0 to 5
  i = Math.floor(h);
  f = h - i; // factorial part of h
  p = v * (1 - s);
  q = v * (1 - s * f);
  t = v * (1 - s * (1 - f));

  switch (i) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;

    case 1:
      r = q;
      g = v;
      b = p;
      break;

    case 2:
      r = p;
      g = v;
      b = t;
      break;

    case 3:
      r = p;
      g = q;
      b = v;
      break;

    case 4:
      r = t;
      g = p;
      b = v;
      break;

    default:
      // case 5:
      r = v;
      g = p;
      b = q;
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}
////////

class MultiDeviceFlowsGraph extends Component {
  constructor(props) {
    super(props);
    let active = [];
    for (let i = 0; i < this.props.devices.length; i++) {
      active[this.props.devices[i]] = true;
    }
    this.state = {
      status: 'loading',
      flowUnits: FlowUnits.CUBIC_METERS_PER_HOUR,
      every: 5,
      series: null,
      data: null,
      timerange: null,
      tracker: null,
      trackerValue: null,
      trackerEvent: null,
      active: active,
      sensorIndex: 0,
    };
    this.loadDeviceCountersFromServer = this.loadDeviceCountersFromServer.bind(this);
    this.handleTrackerChanged = this.handleTrackerChanged.bind(this);
    this.handleTimeRangeChange = this.handleTimeRangeChange.bind(this);
    this.handleChartResize = this.handleChartResize.bind(this);
    this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
    this.handleToDateChanged = this.handleToDateChanged.bind(this);
    this.handleEveryChange = this.handleEveryChange.bind(this);
    this.handleUnitsChange = this.handleUnitsChange.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
    this.createSeries = this.createSeries.bind(this);
    this.handleApplyButtonClick = this.handleApplyButtonClick.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.orientationDidChange = this.orientationDidChange.bind(this);
    this.mql = window.matchMedia('(orientation: portrait)');
  }

  handleSensorChange = (event) => this.setState({ sensorIndex: event.target.value });

  errorCallback(error) {
    ErrorUtil.handleErrorWithAlert(error);
    this.setState({ status: 'no_data' });
  }

  handleFromDateChanged(event, date) {
    this.props.periodFromDateChanged(date);
  }

  handleToDateChanged(event, date) {
    this.props.periodToDateChanged(date);
  }

  handleApplyButtonClick() {
    this.loadDeviceCountersFromServer();
  }

  handleTrackerChanged(t) {
    if (t) {
      const e = this.state.series[0].atTime(t);
      const eventTime = new Date(
        e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2
      );
      const eventValue = e.get(this.state.series[0].name());
      const v = parseFloat(eventValue).toFixed(5);
      this.setState({ tracker: eventTime, trackerValue: v, trackerEvent: e });
    } else {
      this.setState({ tracker: null, trackerValue: null, trackerEvent: null });
    }
  }

  // Handles when the brush changes the timerange
  handleTimeRangeChange(timerange) {
    if (timerange) {
      this.setState({ timerange });
    } else {
      this.setState({ timerange: this.series[0].range() });
    }
  }

  handleEveryChange = (event) => {
    let every = event.target.value;
    this.setState(
      {
        every: every,
      },
      this.loadDeviceCountersFromServer
    );
  };
  handleUnitsChange = (event) => {
    let units = event.target.value;
    this.setState(
      {
        flowUnits: units,
      },
      this.loadDeviceCountersFromServer
    );
  };

  handleChartResize(width) {
    this.state({ width });
  }

  orientationDidChange(orientationQuery) {
    this.setState({ isLendscapeOrientation: !orientationQuery.matches });
  }

  handleActiveChange(key) {
    const active = this.state.active;
    active[key] = !active[key];
    this.setState({ active });
  }

  render() {
    let dateFilter = (
      <Row>
        <Col sm={2} md={2} lg={2}>
          <FormGroup controlId="Digits">
            <ControlLabel>Units</ControlLabel>
            <FormControl
              value={this.state.flowUnits}
              componentClass="select"
              placeholder="every"
              onChange={this.handleUnitsChange.bind(this)}
            >
              <option value={FlowUnits.CUBIC_METERS_PER_HOUR}>
                {FlowUnits.CUBIC_METERS_PER_HOUR}
              </option>
              <option value={FlowUnits.LITERS_PER_SECOND}>{FlowUnits.LITERS_PER_SECOND}</option>
            </FormControl>
          </FormGroup>
        </Col>
        <Col sm={2} md={2} lg={2}>
          <FormGroup controlId="Every">
            <ControlLabel>Every, min</ControlLabel>
            <FormControl
              value={this.state.every}
              componentClass="select"
              placeholder="every"
              onChange={this.handleEveryChange.bind(this)}
            >
              <option value={1}>1</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={30}>30</option>
              <option value={60}>60</option>
            </FormControl>
          </FormGroup>
        </Col>
        <Col sm={3} md={2} lg={2}>
          <ControlLabel>From Date</ControlLabel>
          <TextField
            type="date"
            onChange={this.handleFromDateChanged}
            value={this.props.fromDate}
          />
        </Col>
        <Col sm={3} md={2} lg={2}>
          <ControlLabel>To Date</ControlLabel>
          <TextField type="date" onChange={this.handleToDateChanged} value={this.props.toDate} />
        </Col>
        <Col sm={2} md={2} lg={2}>
          <FormGroup controlId="Every">
            <ControlLabel>Every, min</ControlLabel>
            <FormControl
              value={this.state.every}
              componentClass="select"
              placeholder="every"
              onChange={this.handleEveryChange.bind(this)}
            >
              <option value={1}>1</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={30}>30</option>
              <option value={60}>60</option>
            </FormControl>
          </FormGroup>
        </Col>
        <Col sm={3} md={2} lg={2}>
          <Button variant="raised" onClick={this.handleApplyButtonClick.bind(this)}>
            Apply
          </Button>
        </Col>
      </Row>
    );
    if (this.state.status === 'ready') {
      let min = 0;
      let max = 0;
      for (let i = 0; i < this.state.series.length; i++) {
        if (this.state.active[this.state.series[i].name()]) {
          max = Math.max(max, this.state.series[i].max(this.state.series[i].name()));
        }
      }

      let lineCharts = [];
      let scatterCharts = [];
      let legend = [];
      let styles = [];
      let total = this.state.series.length;
      let cc = rgbColors(total);
      for (let i = 0; i < this.state.series.length; i++) {
        styles.push({
          key: this.state.series[i].name(),
          color: `rgb(${cc[i][0]},${cc[i][1]},${cc[i][2]})`,
        });
      }
      const style = styler(styles);

      for (let i = 0; i < this.state.series.length; i++) {
        if (this.state.active[this.state.series[i].name()]) {
          lineCharts.push(
            <LineChart
              key={this.state.series[i].name()}
              axis="yaxis"
              style={style}
              spacing={1}
              columns={[this.state.series[i].name()]}
              series={this.state.series[i]}
            />
          );
          scatterCharts.push(
            <ScatterChart
              key={this.state.series[i].name()}
              axis="yaxis"
              style={style}
              spacing={1}
              columns={[this.state.series[i].name()]}
              series={this.state.series[i]}
            />
          );
        }
        legend.push({
          key: this.state.series[i].name(),
          label: this.state.series[i].name(),
          disabled: !this.state.active[this.state.series[i].name()],
        });
      }

      return (
        <div>
          <p>Water Consumption Flow Graph of devices: {this.props.devices.join()}</p>
          <Grid>
            {dateFilter}
            <Row>
              <Col sm={3} md={3} lg={3}>
                <Legend
                  type="line"
                  style={style}
                  categories={legend}
                  onSelectionChange={this.handleActiveChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ChartContainer
                  timeRange={this.state.timerange}
                  format="absolute"
                  trackerPosition={this.state.tracker}
                  onTrackerChanged={this.handleTrackerChanged}
                  enablePanZoom
                  maxTime={this.state.series[0].range().end()}
                  minTime={this.state.series[0].range().begin()}
                  minDuration={10 * 60 * 1000}
                  onTimeRangeChanged={this.handleTimeRangeChange}
                  onChartResize={this.handleChartResize}
                  showGrid={false}
                >
                  <ChartRow height="350">
                    <YAxis id="yaxis" format=".3f" width="70" type="linear" min={min} max={max} />
                    <Charts>
                      {lineCharts}
                      {scatterCharts}
                      <EventMarker
                        type="flag"
                        axis="yaxis"
                        event={this.state.trackerEvent}
                        column={this.state.series[0].name()}
                        info={[{ label: 'Flow', value: this.state.trackerValue }]}
                        infoTimeFormat="%Y-%m-%d %H-%M"
                        infoWidth={120}
                        markerRadius={2}
                        markerStyle={{ fill: 'black' }}
                      />
                    </Charts>
                  </ChartRow>
                </ChartContainer>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    } else if (this.state.status === 'loading') {
      return (
        <div>
          <CircularProgress size={80} thickness={5} />
        </div>
      );
    } else if (this.state.status === 'no_data') {
      return (
        <div>
          <p>Water Consumption Flow Graph of device: {this.props.devices.join()}</p>
          <p>{this.state.description}</p>
          {dateFilter}
          <Row>
            <Col sm={2} md={2} lg={2}></Col>
            <Col sm={2} md={2} lg={2}>
              <p>No data.</p>
            </Col>
            <Col sm={2} md={2} lg={2}></Col>
          </Row>
        </div>
      );
    }
  }

  componentWillMount() {
    var isPortraitOrientation = this.mql.matches;
    this.setState({ isLendscapeOrientation: !isPortraitOrientation });
  }

  componentDidMount() {
    this.mql.addListener(this.orientationDidChange);
    this.loadDeviceCountersFromServer();
  }

  componentWillUnmount() {
    this.mql.removeListener(this.orientationDidChange);
  }

  loadDeviceCountersFromServer() {
    let fromDate = this.props.fromDate;
    let toDate = this.props.toDate;
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    let fromDateISO = new Date(fromDate - tzoffset).toISOString();
    let toDateISO = new Date(toDate - tzoffset).toISOString();
    let multiply = 1;
    if (this.state.flowUnits === FlowUnits.CUBIC_METERS_PER_HOUR) {
      multiply = 3600;
    } else if (this.state.flowUnits === FlowUnits.LITERS_PER_SECOND) {
      multiply = 1000;
    }
    deviceFlowsClient.getMultiDeviceFlows(
      this.props.devices,
      this.state.sensorIndex,
      fromDateISO,
      toDateISO,
      multiply,
      this.state.every,
      (data) => {
        const series = this.createSeries(data);
        if (series !== null) {
          this.setState({
            status: 'ready',
            series: series,
            timerange: series[0].range(),
            tracker: this.tracker,
            data: data,
          });
        } else {
          this.setState({
            status: 'no_data',
          });
        }
      },
      this.errorCallback
    );
  }

  createSeries(devicesFlow) {
    let series = [];
    let hasData = false;
    for (let k = 0; k < devicesFlow.length; k++) {
      let points = [];
      let deviceFlow = devicesFlow[k];
      let device = deviceFlow.device;
      let value = deviceFlow.value;
      for (let j = 0; j < value.length; j++) {
        points.push([Index.getIndexString('1min', value[j].date), value[j].flow]);
        hasData = true;
      }
      series.push(
        new TimeSeries({
          name: device.number,
          columns: ['index', device.number],
          points: points,
        })
      );
    }
    if (hasData === false) {
      return null;
    } else {
      return series;
    }
  }
}

function mapStateToProps(state) {
  return {
    devices: state.dev.devices,
    fromDate: state.period.fromDate,
    toDate: state.period.toDate,
  };
}

export default connect(mapStateToProps, actions)(MultiDeviceFlowsGraph);
