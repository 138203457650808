import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../reducers';

import { Chip, FormControlLabel, FormGroup, Checkbox, TextField, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { JwtRole } from '@thingslog/repositories/src/jwt/JWT';
import { UserDto } from '@thingslog/repositories';
import { UserDtoRole } from '@thingslog/repositories/src/user/UserDto';
import { useForm } from 'react-hook-form';

const AddUserModal: FC<AddModalProps> = (props: AddModalProps) => {
  const { onCloseModal, onCreateUser } = props;
  const { t } = useTranslation();

  const companyName = useSelector((state: ReduxState) =>
    state.company.name ? state.company.name : state.auth.companyName
  );

  const currentUserRoles = useSelector((state: ReduxState) => state.auth.roles);

  const language = useSelector((state: ReduxState) => state.auth.language);

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<AddUserProps>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      roles: [],
      dashboardEnabled: false,
      isContactPerson: false,
      language: language!,
      companyName: companyName!,
    },
  });

  const assignRole = (role: UserDtoRole): void => {
    let currentRoles = watch('roles');
    if (currentRoles.includes(role)) {
      currentRoles = currentRoles.filter((x: string) => x != role);
    } else {
      currentRoles.push(role);
    }
    setValue('roles', currentRoles);
  };

  const checkAdminRights = (adminRole: string): boolean => {
    let result = currentUserRoles!.some((element: JwtRole) => {
      if (element.authority === adminRole) return true;
      return false;
    });

    return result;
  };

  const submitForm = (props: AddUserProps): void => {
    const { confirmPassword: _, ...userProps } = props;

    const user: UserDto = { username: userProps.email, ...userProps };

    onCreateUser(user);
    onCloseModal();
  };

  return (
    <form className="flex flex-col gap-7 mt-6" onSubmit={handleSubmit(submitForm)}>
      <TextField
        {...register('email', { required: true })}
        label={t('users_table_email')}
        type="email"
        size="small"
        fullWidth
      />
      <div className="flex gap-7">
        <TextField
          {...register('firstName')}
          label={t('users_table_first_name')}
          size="small"
          fullWidth
        />
        <TextField
          {...register('lastName')}
          label={t('users_table_last_name')}
          size="small"
          fullWidth
        />
      </div>
      <TextField
        {...register('password', { required: true })}
        label={t('password')}
        type="password"
        size="small"
        fullWidth
      />
      <TextField
        {...register('confirmPassword', {
          required: true,
          validate: (value: string) => value === watch('password'),
        })}
        label={t('confirm_password')}
        type="password"
        size="small"
        fullWidth
      />
      <div className="flex justify-center gap-2 mx-2">
        <Chip
          color="primary"
          size="small"
          variant="filled"
          icon={watch('roles').includes('USER') ? <CheckCircleOutlineIcon /> : undefined}
          onClick={(): void => assignRole('USER')}
          label={t('role_user')}
        />
        <Chip
          color="primary"
          size="small"
          variant="filled"
          icon={watch('roles').includes('PUBLIC') ? <CheckCircleOutlineIcon /> : undefined}
          onClick={(): void => assignRole('PUBLIC')}
          label={t('role_public')}
        />
        <Chip
          color="primary"
          size="small"
          variant="filled"
          icon={watch('roles').includes('ADMIN') ? <CheckCircleOutlineIcon /> : undefined}
          onClick={(): void => assignRole('ADMIN')}
          label={t('role_admin')}
        />
        <Chip
          color="primary"
          size="small"
          variant="filled"
          icon={watch('roles').includes('RESTRICTED') ? <CheckCircleOutlineIcon /> : undefined}
          onClick={(): void => assignRole('RESTRICTED')}
          label={t('role_restricted')}
        />
        {checkAdminRights('ROLE_SUPER_ADMIN') && (
          <Chip
            size="small"
            color="primary"
            variant="filled"
            icon={
              watch('roles').includes('SUPER_ADMIN') ? <CheckCircleOutlineIcon /> : <span></span>
            }
            onClick={(): void => assignRole('SUPER_ADMIN')}
            label={t('role_super_admin')}
          />
        )}
      </div>
      <div className="flex justify-evenly">
        <FormGroup>
          <FormControlLabel
            control={<Checkbox {...register('dashboardEnabled')} />}
            label={t('users_is_dashboard_enabled')}
          />
        </FormGroup>
        {checkAdminRights('ROLE_SUPER_ADMIN') && (
          <FormGroup>
            <FormControlLabel
              control={<Checkbox {...register('isContactPerson')} />}
              label={t('users_is_contact_person')}
            />
          </FormGroup>
        )}
      </div>

      <Button
        fullWidth
        type="submit"
        variant="contained"
        disabled={isSubmitting || !isValid}
        disableElevation
      >
        {t('capital_create_btn')}
      </Button>

      <div className="m-auto flex flex-col gap-2">
        <span>{t('for_company')}:</span>
        <div className="text-center font-bold">{companyName}</div>
      </div>
    </form>
  );
};

interface AddModalProps {
  onCloseModal: () => void;
  onCreateUser: (user: UserDto) => void;
}

interface AddUserProps {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  companyName: string;
  roles: UserDtoRole[];
  language: string;
  dashboardEnabled: boolean;
  isContactPerson: boolean;
}

export default AddUserModal;
