import { HelperTextInputField } from '@thingslog/ui-components';
import React from 'react';
import { FC, ReactElement } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import InitialConfigFormFields from '../../models/InitialConfigFormFields';

const TasmotaGenericConfig: FC<TasmotaGenericConfigProps> = ({
  index,
}: TasmotaGenericConfigProps) => {
  const { t } = useTranslation();

  return (
    <ConnectForm<InitialConfigFormFields>>
      {({ register }: UseFormReturn<InitialConfigFormFields>): ReactElement => (
        <>
          <HelperTextInputField
            {...register(`ports.${index}.sensor.measurementType`)}
            size="small"
            label={t('sensor_config_measurement_type')}
          />
          <HelperTextInputField
            {...register(`ports.${index}.sensor.measurementGroup`)}
            size="small"
            label={t('sensor_config_measurement_group')}
          />
        </>
      )}
    </ConnectForm>
  );
};

interface TasmotaGenericConfigProps {
  index: number;
}

export default TasmotaGenericConfig;
