import GenericDigitalSensor, { defaultGenericDigitalSensor } from './GenericDigitalSensor';

export default interface WaterMeter extends GenericDigitalSensor {
  diameter: number | null;
  q3: number | null;
  r: number | null;
  '@type': 'water_meter';
}

const { '@type': _, ...defaultSensorWithoutType } = defaultGenericDigitalSensor;

export const defaultWaterMeter: WaterMeter = {
  '@type': 'water_meter',
  diameter: null,
  q3: null,
  r: null,
  ...defaultSensorWithoutType,
};
