import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import deviceCountersStatisticsClient from './DeviceCountersStatisticsClient';
import sensorsConfigClient from './SensorsConfigClient';
import deviceConfigClient from './DeviceConfigClient';
import deviceFlowsClient from './DeviceFlowsClient';
import meterInfoClient from './MeterInfoClient';
import devicesClient from './DevicesClient';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from './actions';
import { Index, TimeSeries } from 'pondjs';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

import {
  ChartContainer,
  ScatterChart,
  EventMarker,
  LineChart,
  Resizable,
  ChartRow,
  Baseline,
  styler,
  Charts,
  YAxis,
} from 'react-timeseries-charts';
import { CircularProgress, Typography, Button, Grid, Switch } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SensorSelector, { findFirstEnabledDigitalSensor } from './components/SensorSelector';
import AveragePeriodPicker from './components/DatePicker/AveragePeriodPicker';
import SensorStatistics from './components/SensorStatistics';
import PeriodPicker from './components/DatePicker/PeriodPicker';
import TitleHeader from './components/TitleHeader/TitleHeader';
import Header from './components/header';
import GoogleAnalyticsService from './common/GoogleAnalyticsService';
import { GaEventCategory } from './common/GaEventCategory';
import { GaEventAction } from './common/GaEventAction';
import { withRouter } from './common/withRouter';
import { FlowUnits } from './model/Units/FlowUnits';
import { DisplayUnit } from '@thingslog/repositories';
import ErrorUtil from './common/ErrorUtil';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1),
  },
});

const loadingStatus = {
  LOADING: 'loading',
  NO_DATA: 'no_data',
  READY: 'ready',
  NO_DIGITAL_SENSOR: 'no_digital_sensor',
};

const inputPortType = {
  ANALOG: 'analog_input_port',
  CURRENT_420_INPUT_PORT: 'current_420_input_port',
  VOLTAGE_05_INPUT_PORT: 'voltage_05_input_port',
  MA_4_20_INPUT_PORT: '4_20_mA_input_port',
  V_0_5_INPUT_PORT: '0_5_V_input_port',
  DIGITAL: 'digital_input_port',
  PULSE_INPUT_PORT: 'pulse_input_port',
  PULSE_S0_INPUT_PORT: 'pulse_s0_input_port',
};

class DeviceCountersGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: loadingStatus.LOADING,
      showAverage: false,
      units: FlowUnits.CUBIC_METERS_PER_HOUR,
      unitsFromConfig: null,
      every: 5,
      recordPeriod: null,
      confEvery: 1,
      series: null,
      seriesAvg: null,
      timerange: null,
      tracker: null,
      trackerValue: null,
      trackerEvent: null,
      sensorIndex: 0,
      width: 0,
      height: 0,
    };
    this.loadDeviceCountersFromServer = this.loadDeviceCountersFromServer.bind(this);
    this.handleTrackerChanged = this.handleTrackerChanged.bind(this);
    this.handleTimeRangeChange = this.handleTimeRangeChange.bind(this);
    this.handleChartResize = this.handleChartResize.bind(this);
    this.handleEveryChange = this.handleEveryChange.bind(this);
    this.handleUnitsChange = this.handleUnitsChange.bind(this);
    this.createSeriesFlow = this.createSeriesFlow.bind(this);
    this.createSeriesFlowAvg = this.createSeriesFlowAvg.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.handleApplyButtonClick = this.handleApplyButtonClick.bind(this);
    this.orientationDidChange = this.orientationDidChange.bind(this);
    this.toggleShowAverage = this.toggleShowAverage.bind(this);
    this.mql = window.matchMedia('(orientation: portrait)');
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  errorCallback(error) {
    ErrorUtil.handleErrorWithAlert(error);
    this.setState({ data: [] });
  }

  handleApplyButtonClick() {
    this.setState({ status: loadingStatus.LOADING }, this.loadDeviceCountersFromServer);
    GoogleAnalyticsService.triggerEvent(
      GaEventCategory.FLOW_GRAPH_PAGE,
      GaEventAction.FLOW_PAGE_APPLY,
      `${this.state.every} ${this.state.recordPeriod || 'MINUTES'}`
    );
  }

  handleTrackerChanged(t) {
    if (t) {
      const e = this.state.series.atTime(t);
      const eventTime = new Date(
        e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2
      );
      const eventValue = e.get('val');
      const v = parseFloat(eventValue).toFixed(5);
      this.setState({ tracker: eventTime, trackerValue: v, trackerEvent: e });
    } else {
      this.setState({ tracker: null, trackerValue: null, trackerEvent: null });
    }
  }

  // Handles when the brush changes the timerange
  handleTimeRangeChange(timerange) {
    if (timerange) {
      this.setState({ timerange });
    } else {
      this.setState({ timerange: this.series.range() });
    }
  }

  handleEveryChange = (event) => {
    let every = event.target.value;
    this.setState(
      { every: every, status: loadingStatus.LOADING },
      this.loadDeviceCountersFromServer
    );
  };
  handleUnitsChange = (event) => {
    let units = event.target.value;
    this.setState(
      { units: units, status: loadingStatus.LOADING },
      this.loadDeviceCountersFromServer
    );
  };

  handleChartResize(width) {
    this.state({ width });
  }

  orientationDidChange(orientationQuery) {
    this.setState({ isLendscapeOrientation: !orientationQuery.matches });
  }

  toggleShowAverage() {
    this.setState({ showAverage: !this.state.showAverage });
  }

  render() {
    const style = styler([{ key: 'val', color: '#00BCD4' }]);
    const style1 = styler([{ key: 'avg', color: '#0000FF' }]);
    var portType = null;
    if (this.state.port !== undefined) {
      portType = this.state.port['@type'];
    }
    return (
      <Header>
        <div>
          <Grid container direction="column" justifyContent={'center'} spacing={2}>
            {this.props.selectedDevice && (
              <Grid item>
                <TitleHeader
                  title={this.props.t('device_counters_graph_header')}
                  deviceNumber={this.props.selectedDevice.number}
                  deviceName={this.props.selectedDevice.name}
                  customerInfo={this.props.selectedDevice.customerInfo}
                />
              </Grid>
            )}
            {this.state.status === loadingStatus.READY && this.isDigitalPort(portType) && (
              <SensorStatistics
                deviceNumber={this.props.match.params.deviceNumber}
                sensorIndex={this.props.sensorIndex}
                every={this.state.every}
                unit={DisplayUnit[this.state.port.sensor.units]}
                flowUnit={this.state.units}
              />
            )}
            <Grid gap={4} container justifyContent={'center'} item>
              <Grid item>
                <FormGroup controlId="Digits">
                  <FormControl
                    value={this.state.units}
                    componentClass="select"
                    placeholder="every"
                    onChange={this.handleUnitsChange.bind(this)}
                  >
                    {this.state.port != null &&
                      (this.state.units === DisplayUnit.LITERS_PER_SECOND ||
                        this.state.units === DisplayUnit.CUBIC_METERS_PER_HOUR) && (
                        <>
                          <option value={FlowUnits.CUBIC_METERS_PER_HOUR}>
                            {FlowUnits.CUBIC_METERS_PER_HOUR}
                          </option>
                          <option value={FlowUnits.LITERS_PER_SECOND}>
                            {FlowUnits.LITERS_PER_SECOND}
                          </option>
                        </>
                      )}
                    {this.state.port != null && this.state.units === DisplayUnit.KWH && (
                      <option value={FlowUnits.KW}>{FlowUnits.KW}</option>
                    )}
                    {this.state.port != null && this.state.units === DisplayUnit.KVARH && (
                      <option value={FlowUnits.KVAR}>{FlowUnits.KVAR}</option>
                    )}
                    {this.state.port != null &&
                      this.state.units !== DisplayUnit.LITERS_PER_SECOND &&
                      this.state.units !== DisplayUnit.CUBIC_METERS_PER_HOUR &&
                      this.state.units !== DisplayUnit.KWH &&
                      this.state.units !== DisplayUnit.KVARH && (
                        <option value={this.state.units}>{this.state.units}</option>
                      )}
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item>
                <FormGroup controlId="Every">
                  <FormControl
                    value={this.state.every}
                    componentClass="select"
                    placeholder="every"
                    onChange={this.handleEveryChange.bind(this)}
                  >
                    <option value={1}>{this.state.confEvery * 1} min</option>
                    <option value={2}>{this.state.confEvery * 2} min</option>
                    <option value={3}>{this.state.confEvery * 3} min</option>
                    <option value={5}>{this.state.confEvery * 5} min</option>
                    <option value={10}>{this.state.confEvery * 10} min</option>
                    <option value={15}>{this.state.confEvery * 15} min</option>
                    <option value={30}>{this.state.confEvery * 30} min</option>
                    <option value={60}>{this.state.confEvery * 60} min</option>
                  </FormControl>
                </FormGroup>
              </Grid>
              <SensorSelector portsConfig={this.state.portsConfig} />
              <Grid item>
                <ControlLabel>{this.props.t('device_counter_graph_average')}</ControlLabel>
                <Switch
                  value={this.state.showAverage}
                  onChange={this.toggleShowAverage.bind(this)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} justifyContent={'center'} item>
              {this.state.showAverage ? <AveragePeriodPicker /> : <PeriodPicker />}
              <Grid item>
                <Button variant="contained" onClick={this.handleApplyButtonClick.bind(this)}>
                  {this.props.t('button_apply')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {this.state.status === loadingStatus.NO_DIGITAL_SENSOR && (
            <Typography component="p" paragraph>
              {this.props.t('device_counter_graph_must_have_digital_sensor')}
            </Typography>
          )}
          {this.isAnalogPort(portType) && (
            <Typography component="p" paragraph>
              {this.props.t('device_counter_graph_cant_calculate_flow_analog')}
            </Typography>
          )}
          {this.state.status === loadingStatus.NO_DATA && (
            <Typography component="p" paragraph>
              {this.props.t('device_counter_graph_no_data')}
            </Typography>
          )}
          {this.state.status === loadingStatus.LOADING && (
            <CircularProgress size={80} thickness={5} />
          )}
          {this.state.status === loadingStatus.READY && this.isDigitalPort(portType) && (
            <Resizable>
              <ChartContainer
                timeRange={this.state.timerange}
                trackerPosition={this.state.tracker}
                onTrackerChanged={this.handleTrackerChanged}
                enablePanZoom
                enableDragZoom
                maxTime={this.state.series.range().end()}
                minTime={this.state.series.range().begin()}
                minDuration={10 * 60 * 1000}
                onTimeRangeChanged={this.handleTimeRangeChange}
                onChartResize={this.handleChartResize}
                showGrid={false}
                timeAxisTickCount={this.state.width <= 520 ? 5 : 0}
              >
                <ChartRow height="350">
                  <YAxis
                    id="yaxis"
                    min={this.getGraphMin()}
                    max={this.getGraphMax()}
                    format=".3f"
                    width="70"
                    type="linear"
                  />
                  <Charts>
                    <LineChart
                      axis="yaxis"
                      style={style}
                      spacing={1}
                      columns={['val']}
                      series={this.state.series}
                    />
                    <ScatterChart
                      axis="yaxis"
                      style={style}
                      spacing={1}
                      columns={['val']}
                      series={this.state.series}
                    />
                    {this.state.seriesAvg != null && this.state.showAverage ? (
                      <LineChart
                        axis="yaxis"
                        style={style1}
                        spacing={1}
                        columns={['avg']}
                        series={this.state.seriesAvg}
                      />
                    ) : (
                      []
                    )}
                    {this.state.seriesAvg != null && this.state.showAverage ? (
                      <ScatterChart
                        axis="yaxis"
                        style={style1}
                        spacing={1}
                        columns={['avg']}
                        series={this.state.seriesAvg}
                      />
                    ) : (
                      []
                    )}
                    <Baseline axis="yaxis" value={this.state.q1} label="Q1" position="left" />
                    <Baseline axis="yaxis" value={this.state.q2} label="Q2" position="left" />
                    <Baseline axis="yaxis" value={this.state.q3} label="Q3" position="left" />
                    <Baseline axis="yaxis" value={this.state.q4} label="Q4" position="left" />
                    <EventMarker
                      type="flag"
                      axis="yaxis"
                      event={this.state.trackerEvent}
                      column={this.state.series.name()}
                      info={[
                        {
                          label: 'Flow',
                          value: `${this.state.trackerValue} ${this.state.units}`,
                        },
                      ]}
                      infoTimeFormat="%Y-%m-%d %H-%M"
                      infoWidth={120}
                      markerRadius={2}
                      markerStyle={{ fill: 'black' }}
                    />
                  </Charts>
                </ChartRow>
              </ChartContainer>
            </Resizable>
          )}
        </div>
      </Header>
    );
  }

  isAnalogPort(portType) {
    return (
      portType === inputPortType.ANALOG ||
      portType === inputPortType.CURRENT_420_INPUT_PORT ||
      portType === inputPortType.VOLTAGE_05_INPUT_PORT ||
      portType === inputPortType.MA_4_20_INPUT_PORT ||
      portType === inputPortType.V_0_5_INPUT_PORT
    );
  }

  isDigitalPort(portType) {
    return (
      portType === inputPortType.DIGITAL ||
      portType === inputPortType.PULSE_INPUT_PORT ||
      portType === inputPortType.PULSE_S0_INPUT_PORT
    );
  }

  componentWillMount() {
    var isPortraitOrientation = this.mql.matches;
    this.setState({ isLendscapeOrientation: !isPortraitOrientation });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.mql.addListener(this.orientationDidChange);
    this.loadDeviceCountersFromServer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.deviceNumber !== prevProps.match.params.deviceNumber ||
      this.state.units !== prevState.units
    ) {
      this.loadDeviceCountersFromServer();
    }

    if (
      this.state.unitsFromConfig !== prevState.unitsFromConfig &&
      this.state.unitsFromConfig !== null &&
      this.state.unitsFromConfig !== undefined
    ) {
      let derivedUnits;

      switch (this.state.unitsFromConfig) {
        case DisplayUnit.CUBIC_METER:
          derivedUnits = DisplayUnit.CUBIC_METERS_PER_HOUR;
          break;
        case DisplayUnit.LITER:
          derivedUnits = DisplayUnit.LITERS_PER_SECOND;
          break;
        case DisplayUnit.KW:
          derivedUnits = DisplayUnit.KWH;
          break;
        default:
          derivedUnits = this.state.unitsFromConfig;
          break;
      }

      this.setState({ units: derivedUnits });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    this.mql.removeListener(this.orientationDidChange);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  loadDeviceCountersFromServer() {
    let self = this;
    let fromDate = this.props.fromDate;
    let toDate = this.props.toDate;
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    let avgFromDate = this.props.avgFromDate;
    let avgToDate = this.props.avgToDate;
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    avgFromDate.setHours(0, 0, 0, 0);
    avgToDate.setHours(23, 59, 59, 999);
    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    let fromDateISO = new Date(fromDate - tzoffset).toISOString();
    let toDateISO = new Date(toDate - tzoffset).toISOString();
    let avgFromDateISO = new Date(avgFromDate - tzoffset).toISOString();
    let avgToDateISO = new Date(avgToDate - tzoffset).toISOString();
    let dv = 1;
    let dt = 1;
    let multiply;

    switch (this.state.units) {
      case DisplayUnit.LITERS_PER_SECOND:
      case DisplayUnit.CUBIC_METERS_PER_HOUR:
      case DisplayUnit.KWH:
      case DisplayUnit.KVARH:
        if (this.state.units === DisplayUnit.LITERS_PER_SECOND) {
          dv *= 1000; // [l]
        } else {
          dt /= 3600; // [h]
        }
        multiply = dv / dt;
        break;
      default:
        multiply = undefined;
    }

    this.setState({
      seriesAvg: null,
      status: loadingStatus.LOADING,
      series: null,
    });
    devicesClient.getDevice(
      self.props.match.params.deviceNumber,
      (device) => {
        sensorsConfigClient.getPortsConfig(
          self.props.match.params.deviceNumber,
          (portsConfig) => {
            let idx = findFirstEnabledDigitalSensor(portsConfig, self.props.sensorIndex);
            if (idx === null) {
              self.setState({
                status: loadingStatus.NO_DIGITAL_SENSOR,
                portsConfig: portsConfig,
              });
              return;
            }
            const sensor = portsConfig[this.props.sensorIndex].sensor;
            if (sensor && sensor.units) {
              const units = DisplayUnit[sensor.units];
              this.setState({
                unitsFromConfig: units,
              });
            }

            self.props.sensorIndexChanged(idx);
            deviceConfigClient.getDeviceConfig(
              self.props.match.params.deviceNumber,
              (conf) => {
                let every = this.state.every;
                meterInfoClient.getMeterInfo(
                  self.props.match.params.deviceNumber,
                  (meterInfo) => {
                    deviceFlowsClient.getMultiDeviceFlows(
                      self.props.match.params.deviceNumber,
                      self.props.sensorIndex,
                      fromDateISO,
                      toDateISO,
                      multiply,
                      every,
                      (data) => {
                        const series = self.createSeriesFlow(data, conf);
                        if (series == null) {
                          self.setState({
                            status: loadingStatus.NO_DATA,
                            portsConfig: portsConfig,
                          });
                          return;
                        }

                        if (!self.state.showAverage) {
                          self.setState({
                            status: loadingStatus.READY,
                            series: series,
                            seriesAvg: null,
                            timerange: series.range(),
                            tracker: self.tracker,
                            fraction: conf.fraction,
                            confEvery: conf.every,
                            recordPeriod: conf.recordPeriod,
                            every: every,
                            q1: meterInfo.q1,
                            q2: meterInfo.q2,
                            q3: meterInfo.q3,
                            q4: meterInfo.q4,
                            portsConfig: portsConfig,
                            port: portsConfig[this.props.sensorIndex],
                          });
                        }

                        if (self.state.showAverage) {
                          deviceFlowsClient.getMultiDeviceFlowsAvg(
                            self.props.match.params.deviceNumber,
                            self.props.sensorIndex,
                            avgFromDateISO,
                            avgToDateISO,
                            fromDateISO,
                            toDateISO,
                            multiply,
                            self.state.every,
                            (data) => {
                              let seriesAvg = self.createSeriesFlowAvg(data, conf);
                              self.setState({
                                status: loadingStatus.READY,
                                series: series,
                                seriesAvg: null,
                                timerange: series.range(),
                                tracker: self.tracker,
                                fraction: conf.fraction,
                                confEvery: conf.every,
                                recordPeriod: conf.recordPeriod,
                                every: every,
                                q1: meterInfo.q1,
                                q2: meterInfo.q2,
                                q3: meterInfo.q3,
                                q4: meterInfo.q4,
                                portsConfig: portsConfig,
                                port: portsConfig[this.props.sensorIndex],
                                seriesAvg: seriesAvg,
                              });
                            },
                            self.errorCallback
                          );
                        }

                        deviceCountersStatisticsClient.getDeviceCountersStatisticsGeneral(
                          self.props.match.params.deviceNumber,
                          self.props.sensorIndex,
                          fromDateISO,
                          toDateISO,
                          self.state.every,
                          (data) => {
                            self.setState({
                              statistics: {
                                min: data.min,
                                max: data.max,
                                avg: data.avg,
                                total: data.total,
                              },
                            });
                          },
                          self.errorCallback
                        );
                      },
                      self.errorCallback
                    );
                  },
                  self.errorCallback
                );
              },
              self.errorCallback
            );
          },
          self.errorCallback
        );
      },
      self.errorCallback
    );
  }

  createSeriesFlow(devicesFlow, conf) {
    let series = [];
    let hasData = false;
    for (let k = 0; k < devicesFlow.length; k++) {
      let points = [];
      let deviceFlow = devicesFlow[k];
      let value = deviceFlow.value;
      for (let j = 0; j < value.length; j++) {
        points.push([Index.getIndexString('1min', new Date(value[j].date)), value[j].flow]);
        hasData = true;
      }
      series.push(
        new TimeSeries({
          name: 'avg',
          columns: ['index', 'val'],
          points: points,
        })
      );
    }
    if (hasData === false) {
      return null;
    } else {
      return series[0];
    }
  }

  createSeriesFlowAvg(devicesFlow, conf) {
    let series = [];
    let hasData = false;
    for (let k = 0; k < devicesFlow.length; k++) {
      let points = [];
      let deviceFlow = devicesFlow[k];
      let value = deviceFlow.value;
      for (let j = 0; j < value.length; j++) {
        points.push([Index.getIndexString('1min', new Date(value[j].date)), value[j].flow]);
        hasData = true;
      }
      series.push(
        new TimeSeries({
          name: 'avg',
          columns: ['index', 'avg'],
          points: points,
        })
      );
    }
    if (hasData === false) {
      return null;
    } else {
      return series[0];
    }
  }

  getGraphMin() {
    if (this.state.seriesAvg) {
      return this.state.series.min('val') < this.state.seriesAvg.min('avg')
        ? this.state.series.min('val')
        : this.state.seriesAvg.min('avg');
    }
    return this.state.series.min('val');
  }

  getGraphMax() {
    if (this.state.seriesAvg) {
      return this.state.series.max('val') > this.state.seriesAvg.max('avg')
        ? this.state.series.max('val')
        : this.state.seriesAvg.max('avg');
    }
    return this.state.series.max('val');
  }
}

function mapStateToProps(state) {
  return {
    fromDate: state.period.fromDate,
    toDate: state.period.toDate,
    avgFromDate: state.period.avgFromDate,
    avgToDate: state.period.avgToDate,
    sensorIndex: state.dev.sensorIndex,
    selectedDevice: state.dev.selectedDevice,
  };
}

export default withRouter(
  connect(mapStateToProps, actions)(withStyles(styles)(withTranslation()(DeviceCountersGraph)))
);
